import React, {useEffect, useState} from 'react';
import { Row, Col, } from 'react-bootstrap';
import FilterObj from "../../../../assets/filters.json";
// import './Audit.scss';
import { useDispatch, useSelector} from 'react-redux';
import Filter from "./Filter";
import AuditData from "./AuditData";
import { AuditPeriod } from 'audit-component-library';
import {AuditData as Data, Events } from "../types/auditTypes";
import { getAuditList,getTenantId } from '../../../../services/api.service';
import { RootState } from '../../../../store/store';
import allActions from '../../../../store/actions';
import { auditRoles } from '../../../../services/constant';
import { AuditFilter } from 'audit-component-library';



const Audit = () => {

    const [filterCodeList, setFilterCodeList] = useState<Array<string>>([]);
    const [auditData,setAuditData] = useState<Array<Data>>([]);
    const dispatch = useDispatch();
    const [ profileName, role,siteName,tenantIds] = useSelector((state: RootState) => {
      return [ state.audit.currentProfile,state.audit.currentRole,state.user.siteName,
      state.audit.tenantIds];
  });
    const curtenantId = useSelector((state:RootState)=>state.audit.tenantId);


  useEffect(() => {

    if (profileName !== undefined && profileName.length > 0) {
      if(role !== undefined && auditRoles.includes(role.substring(role.indexOf("_") +1))) {
      if (tenantIds.size === 0) {
        dispatch(allActions.loaderAction.showLoader());
        if (tenantIds.get(profileName) === undefined) {
          const tenantMap = new Map();
          getTenantId().then((response) => {
            for (const profile in response) {
              tenantMap.set(response[profile]["tenant"], response[profile]["tenantId"]);
            }
            dispatch(allActions.auditAction.addTenantIds(tenantMap));
            dispatch(allActions.auditAction.addTenantId(tenantMap.get(siteName)));
            dispatch(allActions.loaderAction.hideLoader());
            fetchAuditList(tenantMap.get(siteName));
          }

          ).catch(error => {
            dispatch(allActions.alertAction.showAlert({ type: "danger", message: error }))
            dispatch(allActions.loaderAction.hideLoader());
          }
          )

        }
      } else {
        fetchAuditList();
      }
    }
    }
    },[profileName])


  const fetchAuditList = (tenantId?: string) => {
    dispatch(allActions.loaderAction.showLoader());
    const payload = {
      eventType: 'AUDIT',
      profile: profileName.trim(),
      role: role,
      product: 'Argus Performance Insights'
    }
    const date = new Date();
    payload["eventFromTime"] = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 6, 0, 0, 1).toISOString();
    payload["eventEndTime"] = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59).toISOString();
    if (tenantId !== undefined && tenantId.length > 0) {
      payload["tenantId"] = tenantId;
    } else {
      payload["tenantId"] = tenantIds.get(siteName);
    }

    dispatch(allActions.auditAction.addEventsFrom(payload["eventFromTime"]));
    dispatch(allActions.auditAction.addEventsTo(payload["eventEndTime"]));
   
      getAuditList(payload).then((response:Events)=> {
        setAuditData(response !== null && response.events ? response.events : [])
    }).catch(error => {
      dispatch(allActions.alertAction.showAlert({ type: "danger", message: error }))
      setAuditData([])
    }
    ).finally(() => {
        dispatch(allActions.loaderAction.hideLoader())
      })
  }
  const validateCustomDate = (startDate:Date |undefined,endDate:Date | null | undefined) => {
    const date = new Date();     
    if(startDate === undefined && endDate === undefined) {
        dispatch(allActions.alertAction.showAlert({type:"danger",message:"Please select start and end date"}));
        return false;
    } else if(startDate == undefined) {
        dispatch(allActions.alertAction.showAlert({type:"danger",message:"Start date is not selected"}));
        return false;
    }else if(endDate == undefined ){
        dispatch(allActions.alertAction.showAlert({type:"danger",message:"End date is not selected"}));
        return false;
    }else if(startDate.getTime() > endDate.getTime()) {
        dispatch(allActions.alertAction.showAlert({type:"danger",message:"Start date cannot be after the end date"}));
        return false;
    }
     else if(date.getFullYear() === startDate.getFullYear()) {
        const diff = startDate.getTime() - endDate.getTime(); 
        const days:number = diff/(1000 * 3600 * 24);
        if(days > 90) {
            dispatch(allActions.alertAction.showAlert({type:"danger",message:"Logs can be fetched for upto three months in the current year"}));
            return false;
        }
      
    }else if(date.getFullYear() !== startDate.getFullYear()) {
        const diff = startDate.getTime() - endDate.getTime();
        const days = diff/(1000 * 3600 * 24);
        if(days > 30) {
            dispatch(allActions.alertAction.showAlert({type:"danger",message:"Logs can be fetched for upto one month"}));
        }
        return false;
    }
  
    return true;
}
  const fetchHandler = (startDate:Date |undefined,endDate:Date | null | undefined,activeFilter:string) => {
        
    const isValid =   activeFilter === "Custom Date" ? validateCustomDate(startDate,endDate) : true;
      if(!isValid)
      return;

  const payload = {
      eventType:'AUDIT',
      tenantId:curtenantId,
      profile:profileName,
      role:role,
      product:'Argus Performance Insights'
  }

  
 
      payload["eventFromTime"] = startDate && startDate.toISOString();

  payload["eventEndTime"] = endDate && new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59).toISOString();
  


  dispatch(allActions.auditAction.addEventsFrom(payload["eventFromTime"]));
  dispatch(allActions.auditAction.addEventsTo(payload["eventEndTime"]));
  dispatch(allActions.loaderAction.showLoader());
  getAuditList(payload).then((response ) => {
    setAuditData(response !== null && response.events ? response.events : [])
  }).catch(error =>
      dispatch(allActions.alertAction.showAlert({type:"danger",message: error})))
      .finally(() =>  dispatch(allActions.loaderAction.hideLoader()))

}



    return (
        <>
        <Row className='mt-2 audit' style={{height:'80vh'}}>
              <Col className="h-100 audit-content"  sm={1} md={2} lg={2}><AuditFilter FilterObj={FilterObj} setFilterCodeList={setFilterCodeList} /></Col>
              <Col className="ms-4 audit-content">
                <Row>
                <Col className="audit-period" xs={12} >
                  <AuditPeriod setAuditData={setAuditData} type={''} profileName={profileName} role={role} tenantId={curtenantId} product={'Argus Performance Insights'} selectedUsers={[]} fetchHandler={fetchHandler} /></Col></Row>
                  <Row className=" w-100 h-90"><Col xs={12}>
                    <AuditData  auditData={auditData} filterList={filterCodeList} />
                    </Col></Row>
                  
                  </Col>
          </Row>
          </>

    )
}

export default Audit
