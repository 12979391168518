const checkSignalEnabled = (state, { signal_banks, signal_roles, signal_profiles }) => {
    const macthedBank = signal_banks.includes(state.siteName);
    const macthedProfile = signal_profiles.includes(`${state.siteName}_${state.profileName}`);
    const macthedRole = signal_roles.includes(`${state.siteName}_${state.role}`);
    return macthedBank && macthedProfile && macthedRole;
}
const user = (
    state = {
        siteName: '',
        profileName: '',
        profileIndex: '',
        userId: '',
        userEmail: '',
        favorite: null,
        role: '',
        workBooks: [],
        selectedWorkbookId: null,
        currentWorkbookName: null,
        allProfile: null,
        reloadDashboard: false,
        isSignalsEnabled: false,
        isSystemNotificationOn: false
    },
    { type, payload }
) => {
    switch (type) {
        case 'ADD_USER':
            return {
                ...state,
                workBooks: payload.workBooks,
                favorite: payload.favouriteDashboard
            };
        case 'ADD_FAVORITE':
            return {
                ...state,
                favorite: payload
            };
        case 'ADD_USER_ID':
            return {
                ...state,
                userId: payload
            };
        case 'ADD_USER_EMAIL':
            return {
                ...state,
                userEmail: payload
            };
        case 'ADD_SITE_NAME':
            return {
                ...state,
                siteName: payload
            };
        case 'ADD_SIGNALS_ENABLED':
            return {
                ...state,
                isSignalsEnabled: checkSignalEnabled(state, payload)
            };
        case 'SHOW_SYSTEM_NOTIFICATION':
            return {
                ...state,
                isSystemNotificationOn: payload
            };
        case 'ADD_PROFILE_NAME':
            return {
                ...state,
                profileName: payload
            };
        case 'ADD_PROFILE_INDEX':
            return {
                ...state,
                profileIndex: payload
            };    
        case 'ADD_ROLE':
            return {
                ...state,
                role: payload
            };
        case 'ADD_WORKBOOK_NAME':
            return {
                ...state,
                currentWorkbookName: payload
            };
        case 'ADD_WORKBOOK_ID':
            return {
                ...state,
                selectedWorkbookId: payload
            };
        case 'ADD_ALL_PROFILE':
            return {
                ...state,
                allProfile: payload
            };
        case 'RELOAD_DASHBOARD':
            return {
                ...state,
                reloadDashboard: payload
            };
        default:
            return state;
    }
};
export default user;
