const showAlert = (payload) => {
    return {
        type: "SHOW_ALERT",
        payload
    }
}

const hideAlert = () => {
    return {
        type: "HIDE_ALERT"
    }
}

export default {
    showAlert,
    hideAlert
}