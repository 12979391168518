import React from 'react';
import './signinError.scss';
import Argus_Logo from '../../../assets/images/logo.svg';
import i18nLabels from '../../../services/i18n.service';
import { app_config } from '../../../services/constant';
function SigninError() {
    
    return (
        <div className="signin-error-holder">
            <div className="signin-error-content">
                <div className="signin-error-header">
                    <img src={Argus_Logo} alt="Argus" className="logocss" />
                </div>
                <div className="signin-error-text">
                    {i18nLabels.signin_error}
                    <a href={`mailto:${app_config.SUPPORT_EMAIL}`}>{app_config.SUPPORT_EMAIL}</a>
                </div>
            </div>
        </div>
    );
}
export default SigninError;
