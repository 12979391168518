import React, { useState } from 'react';
import { Accordion, Card, Container, Row, Col } from 'react-bootstrap';
import './SupportPage.scss';
import { TypeFAQ } from '../../../types/types';
import UseSupportPage from './useSupportPage';
import i18nLabels from '../../../services/i18n.service';


function Faq() {
   const [view, setView] = useState<Array<TypeFAQ> | null>(null);

   const getView=(response)=>{
       setView(response.faqs)
   }
   UseSupportPage('faqs', getView);

     return (
        
        <Container fluid-xxl id="page-container" className="dashboard-container container-xxl">
            <Row>
                <Col md={12} className="">
                    <h2 className="text-left support-title">{i18nLabels.faq_title}</h2>
                    <Card className="border-0 rounded-2">
                        <Card.Body className="p-4">
                        {view?.length ? (
                            <Accordion defaultActiveKey="0" flush>
                                { view?.map((views: TypeFAQ, index: number) => {
                                    return (
                                        <Accordion.Item
                                            eventKey={'' + index}
                                            key={views.question}
                                            className="border-0 border-bottom ">
                                            <Accordion.Header className="fs-16 l-h-24">
                                              <h6> {views.question}{' '}</h6> 
                                        
                                                       
                                                <ul className='mb-0 mt-1'>
                                                     { views?.tags?.map((tag) => {
                                                       
                                                        return ( <li key={tag} className="me-2"> {tag}</li> );
                                                       
                                                    })} 
                                                    </ul>
                                               

                                            </Accordion.Header>
                                            <Accordion.Body className="fs-14 ps-4">
                                                {' '}
                                               <p className="ps-2"> {views.answer}</p>
                                                <ul className='mb-0 mt-1'>
                                                     { views?.hyperlink?.map((hyperlink) => {
                                                       
                                                        return ( <li key={hyperlink.content} className="me-2"> 
                                                        <a href="https://www.synchrony.com">{hyperlink.content}</a>
                                                        </li> );
                                                       
                                                    })} 
                                                    </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })}
                            </Accordion>
                        ):  (
                            <p className="text-center mb-5 mt-5"></p>
                        )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
export default Faq;
