import React from 'react';
import './Loader.scss';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
import type { RootState } from '../../../../store/store';
import i18nLabels from '../../../../services/i18n.service';
function ArgusLoader() {
    const loader = useSelector((state: RootState) => {
        return state.loader;
    });
    if (!loader.showLoader) {
        return null;
    }
    return (
        <div className="loader-container">
            <div className="spinner-holder">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">{i18nLabels.loading_msg}</span>
                </Spinner>
            </div>
            <div className="loader-overlay"></div>
        </div>
    );
}

export default ArgusLoader;
