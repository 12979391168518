import React, { useState } from 'react';
import { Container, Row, Col, Card, Modal } from 'react-bootstrap';
import './SupportPage.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { TypeTutorialTraining } from '../../../types/types';
import UseSupportPage from './useSupportPage';
import i18nLabels from '../../../services/i18n.service';

export default function TutorialTraining() {
    const [open, setOpen] = useState(0);
    const [view, setView] = useState<Array<TypeTutorialTraining> | null>(null);

    const getView = (response) => {
        setView(response.contents);
    };
    UseSupportPage('tutorials', getView);

    const handleModal = (id, filetype, presignedURL) => {
        if (filetype !== 'pdf' && filetype !== 'doc' && filetype !== 'docx' && filetype !== 'xls' && filetype !== 'odt' && filetype !== 'csv' && filetype !== 'xlsx') {
            setOpen(id);
            if (open) {
                setOpen(0);
            }
        } else {
            window.open(presignedURL, '_blank', 'noreferrer');
        }
    };

    if (!view) {
        return (
            <></>
        );
    }

    return (
        <>
            <Container fluid-xxl id="page-container " className="dashboard-container tutorial-training container-xxl">
                <Row>
                    <Col>
                        <h2 className="text-left support-title">{i18nLabels.tutorial_training_title}</h2>
                    </Col>
                </Row>

                <Card className="border-0">
                {view?.length ? (
                    <Card.Body className="p-4">
                        <Row className="pt-2 mb-4 tutorial-heading">
                            <Col md={4} className="training-tab-title ps-5">
                                {i18nLabels.tutorial_training_col_heading_name}
                            </Col>
                            <Col md={7} className="training-tab-title ps-3">
                            {i18nLabels.tutorial_training_col_heading_description}
                            </Col>
                            <Col md={1} className="training-tab-title">
                            {i18nLabels.tutorial_training_date_created}
                            </Col>
                        </Row>

                        {view?.map != null &&
                            view.map((views: TypeTutorialTraining, index: number) => {
                                return (
                                    <div key={views.filename}>
                                        <Card
                                            className={`card-panel ${
                                                open == index + 1 ? 'active' : ''
                                            }`}
                                            onClick={() =>
                                                handleModal(
                                                    index + 1,
                                                    views.filetype,
                                                    views.presignedURL
                                                )
                                            }>
                                            <Card.Body>
                                                <Row>
                                                    <Col md={4}>
                                                        {' '}
                                                        {views.filetype == 'mp4'  ?
                                                        <span className="material-symbols-outlined">
                                                            smart_display
                                                        </span>
                                                        : <span className="material-symbols-outlined">
                                                        description
                                                    </span>}
                                                        <span className="tutorial-heading">
                                                            {views.filename}
                                                        </span>
                                                    </Col>
                                                    <Col md={7}><p> {views.description}</p></Col>
                                                    <Col md={1}> {views.uploadedDate}</Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>

                                        <Modal
                                            className="tutorial-training-modal"
                                            size="lg"
                                            show={open == index + 1}
                                            onHide={() =>
                                                handleModal(
                                                    index,
                                                    views.filetype,
                                                    views.presignedURL
                                                )
                                            }
                                            aria-labelledby="example-modal-sizes-title-lg"
                                            centered>
                                            <Modal.Header closeButton>
                                                <Modal.Title id="example-modal-sizes-title-lg">
                                                    {views.filename}
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <iframe
                                                    width="100%"
                                                    height="415px"
                                                    src={views.presignedURL}
                                                    title="YouTube video player"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                                                <p className="mtext pt-2">{views.description}</p>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                );
                            })}
                    </Card.Body>
                ):(
                    <p className="text-center mb-5 mt-5"> {i18nLabels.tutorial_training_no_found}</p>
                )}
                </Card>
            </Container>
        </>
    );
}
