export const tableau_new_workbook_code = 'newWorkbook';

export const allRoles = {
    ADMIN: 'ADMIN'
}

export const userTypes = {
    ARGUSUSER: 'Argus User',
    GROUPUSER: 'Group Users',
    OTHERUSERS: 'OTHERUSERS'
}

export const app_config = {
    API_URL: process.env.REACT_APP_API_URL,
    TRUSTED_TOKEN_API: process.env.REACT_APP_TICKET_API_URL,
    COGNITO_DOMAIN: process.env.REACT_APP_DOMAIN_URL,
    AWS_PROJECT_REGION: process.env.REACT_APP_AWS_PROJECT_REGION,
    AWS_USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    AWS_USER_WEB_CLIENT_ID: process.env.REACT_APP_WEB_CLIENT_ID,
    REDIRECT_SIGN_IN: process.env.REACT_APP_REDIRECT_SIGNIN,
    REDIRECT_SIGN_OUT: process.env.REACT_APP_REDIRECT_SIGNOUT,
    TABLEAU_URL: process.env.REACT_APP_TABLEAU_URL,
    IDENTITY_PROVIDER: process.env.REACT_APP_IDENTITY_PROVIDER,
    PRIVACY_POLICY_URL: 'https://www.argusinformation.com/privacy-notice/',
    SUPPORT_EMAIL: 'PDLPerformanceInsightsSupport@transunion.com'
}

export const roles = {
    EDIT: ['ADM', 'RM', 'BA', 'OPS', 'CC', 'EDITOR'],
    SHARE: ['ADM', 'RM', 'BA', 'OPS', 'CC', 'EDITOR'],
    FILTER: ['ADM', 'RM', 'BA', 'OPS'],
    FAVOURITE: ['ADM', 'RM', 'BA', 'OPS',  'CC', 'EDITOR', 'VIEWER'],
    MULTITENANT: ['ADM', 'RM', 'BA', 'OPS',  'CC', 'EDITOR', 'VIEWER'],
    RM: ['RM'],
    GROUP_MULTITENANT: ['RM', 'BA'],
    NEW_SIGNALS: ['RM', 'BA'],
    SIGNAL_SHARE: ['RM', 'BA'],
    EXTERNAL_USERS: ['CC', 'EDITOR', 'VIEWER'],
    INTERNAL_USERS: ['ADM', 'RM', 'OPS', 'BA']
};

export const auditRoles = [ 'OPS','RM','ADM'];

export const errorCode = {
    apiNotFound: {
        code: 200404,
        message: 'API not available'
    },
    apiServerDown: {
        code: 200503,
        message: 'API Server Maintenance or Error'
    },
    pageNotFound: {
        code: '',
        message: 'Requested page not available'
    },
    apiTimeout: {
        code: '',
        message: 'Request timed out. If you are seeing this error for the first time, please try again shortly, or otherwise contact technical support for assistance.'
    }

}

export const globalConstant = {
    dateFormat: 'M/D/YYYY',
    dateDisplayFormat: 'MMMM YYYY',
    hideProfile:'Argus2-App',
    reportType: {
        signals: 'signals',
        dashboard: 'dashboard'
    },
    inactiveTimerDuration: 300,
    expiryTime:{
        internal: 3600,
        external: 900
    },
    tableauHeight:{
        FAVORITE: {
            min: 540,
            deduction: 242
        },
        VIEW: {
            min: 580,
            deduction: 180
        },
        EDIT: {
            min: 680,
            deduction: 220
        }
    },
    filterPointerMap: {
        'mob': 'Months on Books Segment',
        'status': 'Active Segment',
        'month-on-books': 'Months on Books Segment',
        'month on books': 'Months on Books Segment'
    },
    exportType:{
        'export-pdf': 'PDF',
        'image': 'Image',
        'export-powerpoint': 'PPT',
        'export-cross-tab': 'Data'
    }
}

export const dimensionDefaultOptions = {
    POPULATION: {
        name: 'Population',
        options: ['Benchmark']
    },
    PORTFOLIO: {
        name: 'Portfolio',
        options: ['Bank - Overall', 'Benchmark - Industry']
    }
}

export const nonOverallFilters = ['portfolio_type', 'portfolio_desc', 'reporting_date', 'Portfolio', 'Population'];
