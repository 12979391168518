
const initialState = {
    isOpen: false,
    template:null
}
const createDashboard = (
    state = initialState,
    { type, payload }
) => {
    switch (type) {
        case 'SHOW_CREATE_DASHBOARD_POPUP':
            return {
                ...state,
                isOpen: true
            };
        case 'HIDE_CREATE_DASHBOARD_POPUP':
            return {
                ...state,
                isOpen: false
            };
        case 'SET_CREATE_DASHBOARD_TEMPLATE':
            return {
                ...state,
                template: payload
            };
        default:
            return state;
    }
};
export default createDashboard;
