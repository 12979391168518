const initialState = {
    tenantIds:new Map(),
    tenantId:0,
     currentProfile:'',
     currentRole:'',
     downloadType:'',
     eventsFrom:'',
     eventsTo:'',
     presignedUrl:'',
     downloadAsyncId:'',
     disableDownload:false,
     totalPages:0
 };
 
 const audit = (state = initialState, { type, payload }) => {
     switch (type) {
         case 'ADD_TENANT_IDS':
             return {
                 ...state,
                 tenantIds:payload
             };
 
         case 'ADD_TENANT_ID':
             return {
                 ...state,
                 tenantId: payload
             };
             case 'ADD_CURRENT_PROFILE':
             return {
                 ...state,
               currentProfile:payload
             };
             case 'ADD_CURRENT_ROLE':
             return {
                 ...state,
                 currentRole:  payload
             };
            
            
             case 'ADD_DOWNLOAD_TYPE':
                 return {
                     ...state,
                     downloadType:payload
                 };
             case 'ADD_EVENTS_FROM':
                 return {
                     ...state,
                     eventsFrom:payload
                 };
             case 'ADD_EVENTS_TO':
                 return {
                     ...state,
                     eventsTo:payload
                 };
            
            
             case 'ADD_PRESIGNED_URL':
                 return {
                     ...state,
                     presignedUrl:payload
                 }
           
             case 'ADD_DOWNLOAD_ASYNC_ID':
                 return {
                     ...state,
                     downloadAsyncId:payload
                 }
             case 'DISABLE_DOWNLOAD':
                 return {
                     ...state,
                    disableDownload: payload
                 }
             case 'TOTAL_PAGES':
                 return {
                     ...state,
                    totalPages: payload
                 }
         default:
             return state;
     }
 };
 export default audit;
 