import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import i18nLabels from '../../../../services/i18n.service';
interface Props {
    onFieldFocus:() => void;
    onSearchChange: (string) => void;
    onCloseSearch: () => void;
    searchText: string;
}
function SearchInput({ onSearchChange, onCloseSearch, onFieldFocus, searchText }: Props) {
    const [showClose, setShowClose] = useState(false)
    const onChangeHandler = (event) => {
        onSearchChange(event.target.value);
        setShowClose(true)
    };
    const onFocusHandler = () => {
        onFieldFocus()
        setShowClose(true)
    };
    const onClose = () => {
        onSearchChange('');
        onCloseSearch();
        setShowClose(false);
    };
    return (
        <>
            <InputGroup className={showClose ? 'mb-3 hide-right-border' : 'mb-3'}>
                <InputGroup.Text id="basic-addon1">
                    <span className="material-symbols-outlined">search</span>
                </InputGroup.Text>
                <Form.Control
                    placeholder={i18nLabels.dashboard_share_search_placeholder}
                    aria-label="Username"
                    value={searchText}
                    aria-describedby="basic-addon1"
                    onChange={onChangeHandler}
                    onFocus={onFocusHandler}
                />
                {showClose ? <InputGroup.Text id="basic-addon1">
                    <span onClick={() => {
                        onClose()
                    }} className="material-symbols-outlined search-close-btn">close</span>
                </InputGroup.Text> : null}
                
            </InputGroup>
        </>
    );
}

export default SearchInput;
