import React, { useEffect, useCallback, useState } from 'react';
import './tableau.scss';
import type { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../store/actions';
import useContainerSize from './useContainerSize';
import useTableauFilter from './useTableauFilter';
import { TypeSelectedFilter } from '../../../types/types';
import { getTranformedFilters } from './tableauUtils';
import HeaderEditTableau from './HeaderEditTableau';
import useSyncFilter from './useSyncFilter';
import { addLogs } from '../../../services/utils';
import { globalConstant } from '../../../services/constant';
type Props = {
    url: string;
    createMode?: boolean;
    extraHeight?: number;
    showFilters?: boolean;
    reportType: string;
    setCurrentTabSheet?: (worksheetId: string) => void;
    selectedFilters?: Array<TypeSelectedFilter>;
    setDashboardIsVizRendered: (flag: boolean) => void;
    onPublishDashboard: (workbookName?: string, sheetName?: string) => void;
};
let authVizRef;

function EditTableau({
    url,
    onPublishDashboard,
    selectedFilters,
    setCurrentTabSheet,
    setDashboardIsVizRendered,
    showFilters,
    reportType
}: Props) {
    const dispatch = useDispatch();
    const [isVizRendered, setIsVizRendered] = useState(false);
    const [reload, setReload] = useState(false);
    const [lastAccessedSheet, setLastAccessedSheet] = useState(null);
    const [disableFilterBtn, setDisableFilterBtn] = useState(true);
    const { vizHeight } = useContainerSize('EDIT');
    const { syncLocalFilters } = useSyncFilter();
    const { applySelectedFilters, isFilterChanged, setIsFilterChanged } = useTableauFilter();

    const { applySelectedFilter } = useSelector((state: RootState) => {
        return state.dimensionFilters;
    });

    const handlePublished = useCallback(() => {
        onPublishDashboard();
        setIsFilterChanged(false);
        if (!authVizRef.vizImpl.workbookImpl.activeSheet) {
            setReload(true);

            setTimeout(() => {
                setReload(false);
            }, 200);
        } else {
            initSyncLocalFilters();
        }
    }, [url, reload]);

    const handlePublishedAs = useCallback(
        (event) => {
            if (event.detail.newUrl) {
                const workbookUrlName = event.detail.newUrl.split('authoring/')[1].split('/')[0];
                const sheetId = event.detail.newUrl.split('authoring/')[1].split('/')[1];
                onPublishDashboard(workbookUrlName, sheetId);
            }
        },
        [url, reload]
    );

    const tabChangeHandler = useCallback(
        async (event) => {
            setLastAccessedSheet(event.detail.newSheetName);
            authVizRef = document.getElementById('tableauViz');
            initSyncLocalFilters();
            if(authVizRef.vizImpl.workbookImpl.activeSheet){
                const currentTabSheetId = authVizRef.vizImpl.workbookImpl.activeSheet.url.split('/').pop();
                setCurrentTabSheet && setCurrentTabSheet(currentTabSheetId);
            }
            
        },
        [url, reload]
    );

    const onApplyFilterClick = useCallback(async () => {
        initSyncLocalFilters();
    }, []);

    useEffect(() => {
        return () => {
            addLogs('Workbook-log', 'Edit Dashboard loading start');
            dispatch(allActions.dimensionFiltersAction.deleteAllFilters());
        };
    }, []);

    useEffect(() => {
        if (selectedFilters?.length && isVizRendered && applySelectedFilter) {
            const dimFilters = getTranformedFilters(selectedFilters);
            applySelectedFilters(
                authVizRef.vizImpl.workbookImpl.activeSheet,
                dimFilters,
                isVizRendered,
                authVizRef.vizImpl
            );
            dispatch(allActions.dimensionFiltersAction.triggerApplyFilter(false));
        }
    }, [selectedFilters, isVizRendered, applySelectedFilter]);

    useEffect(() => {
        if (showFilters) {
            initSyncLocalFilters(false);
        }
    }, [showFilters]);

    useEffect(() => {
        if (url && !reload) {
            authVizRef = document.getElementById('tableauViz');
            authVizRef.addEventListener('workbookpublished', handlePublished);
            authVizRef.addEventListener('workbookpublishedas', handlePublishedAs);
            authVizRef.addEventListener('firstinteractive', onFirstInteractiveHandler);
            authVizRef.addEventListener('tabswitched', tabChangeHandler);
            return () => {
                authVizRef.removeEventListener('workbookpublished', handlePublished);
                authVizRef.removeEventListener('workbookpublishedas', handlePublishedAs);
                authVizRef.removeEventListener('tabswitched', tabChangeHandler);
            };
        }
    }, [url, reload]);

    const initSyncLocalFilters = async (isApplyFilterRequired = true) => {
        const sheet = authVizRef.vizImpl.workbookImpl.activeSheet;
        setDisableFilterBtn(!sheet);
        if (!sheet || reportType === globalConstant.reportType.signals) {
            return;
        }
        syncLocalFilters(sheet, isApplyFilterRequired);
    };

    const onFirstInteractiveHandler = useCallback(async () => {
        setDashboardIsVizRendered(true);
        setIsVizRendered(true);
        authVizRef = document.getElementById('tableauViz');
        const workbook = authVizRef.vizImpl.workbookImpl;
        dispatch(allActions.userAction.addWorkkbookName(workbook.name));
        if (lastAccessedSheet) {
            await workbook.activateSheetAsync(lastAccessedSheet);
        } else {
            initSyncLocalFilters();
        }
        addLogs('Workbook-log', 'Edit Dashboard loading completed');
    }, [lastAccessedSheet]);

    if (reload) {
        return <></>;
    }

    return (
        <>
            <div>
                {(isVizRendered && reportType === globalConstant.reportType.dashboard) ? (
                    <HeaderEditTableau
                        isFilterChanged={isFilterChanged}
                        disableFilterBtn={disableFilterBtn}
                        onApplyFilterClick={onApplyFilterClick}></HeaderEditTableau>
                ) : <div className='pt-2'></div>}
                <div className="viz-container" style={{ height: `${vizHeight}px` }}>
                    <tableau-authoring-viz
                        hide-close-button="true"
                        width="100%"
                        src={url}
                        height={vizHeight - 8}
                        id="tableauViz"></tableau-authoring-viz>
                </div>
            </div>
        </>
    );
}

export default EditTableau;
