import React, { useEffect, useRef, useState } from 'react';
import './recentDashboard.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../../common/ui/button/Button';
import { TypeWorkbook } from '../../../types/types';
import { roles } from '../../../services/constant';
import { RootState } from '../../../store/store';
import i18nLabels from '../../../services/i18n.service';
import RecentLeftThumbnails from './RecentLeftThumbnails';
import RecentSignalThumbnails from './RecentSignalThumbnails';

interface Props {
    onCreateNewDashboard: () => void;
    view: Array<TypeWorkbook>;
    signals: Array<TypeWorkbook>;
    gotoView: (
        workBookName: string,
        workSheetId: string,
        workBookId: string,
        workbookUrl: string,
        reportType:string
    ) => void;
}
/**
 * React component for showing recent dashboard
 * @param {function} onCreateNewDashboard - event handler for new dashboard creation
 * @param {Array} view - List of dashboards
 * @param {function} gotoView - function for navigating on view page
 * @return {void} Nothing
 */
function RecentLeftPanel({ onCreateNewDashboard, view, gotoView, signals }: Props) {
    const [trimmedSignals, setTrimmedSignals] = useState<Array<TypeWorkbook>>([])
    const [profileRole, isSignalsEnabled] = useSelector((state: RootState) => {
        return [state.user.role, state.user.isSignalsEnabled];
    });

    const refSignalCont = useRef<HTMLInputElement>(null);

    const updateSignalsCount = () =>{
        if(signals && refSignalCont?.current?.clientHeight && signals.length > 3) {
            const oneSignalHeight = 72;
            const containerHeight = refSignalCont.current.clientHeight - 8;
            let maxSignals = Math.ceil(containerHeight/oneSignalHeight);
            maxSignals = maxSignals > 3 ? maxSignals : 3;
            setTrimmedSignals(signals.slice(0, maxSignals));
        }else{
            setTrimmedSignals(signals);
        }
    }

    useEffect(() => {
        function onWindowResize() {
            updateSignalsCount();
        }
        updateSignalsCount();
        window.addEventListener('resize', onWindowResize);
        return () =>{
            window.removeEventListener('resize', onWindowResize);
        }
    }, [signals])

    return (
        <div className='recent-section'>
            {isSignalsEnabled && signals.length > 0 &&
            <div className="recent-container signals">
                <Row className="landing-header favorite-header">
                    <Col sm={6} className="landing-title">
                    <h4>{i18nLabels.dashboard_recent_signal_title}</h4>
                    </Col>
                    <Col sm={6} className="view-all-hyperlink">
                        <Link to="signals/list">
                            {i18nLabels.dashboard_recent_dashboard_view_all_dashboard_link}
                        </Link>
                    </Col>
                </Row>
                <Row className="recent-dashboard" ref={refSignalCont}>
                        <div id="list-dashboard"  className=" rounded-3">
                            <RecentSignalThumbnails view={trimmedSignals} gotoView={gotoView}></RecentSignalThumbnails>
                        </div>
                </Row>
                
            </div>}
            

            <div className={`recent-container ${(!isSignalsEnabled || !signals?.length) ? 'full-height' : ''}`}>
                <Row className="landing-header favorite-header">
                    <Col sm={9} className="landing-title">
                    <h4 className='flex-v-center'>
                        {i18nLabels.dashboard_recent_dashboard_title}
                    
                        {roles.EDIT.indexOf(profileRole) > -1 ? (
                                <Button onClick={onCreateNewDashboard} type="icon-btn ps-3">
                                    <span className="material-icons-outlined">dashboard_customize</span>
                                </Button>
                        ) : (
                            ''
                        )}
                        
                    </h4>
                    </Col>
                    
                    <Col sm={3} className="view-all-hyperlink">
                        <Link to="dashboard/list">
                            {i18nLabels.dashboard_recent_dashboard_view_all_dashboard_link}
                        </Link>
                    </Col>
                </Row>
                <Row className="recent-dashboard">
                    {view?.length ? (
                        
                        <div id="list-dashboard" className=" rounded-3">
                            <RecentLeftThumbnails  view={view} gotoView={gotoView}></RecentLeftThumbnails>
                        </div>
                    ) : (
                        <span className="no-dashbaord">
                            {i18nLabels.dashboard_recent_dashboard_no_dashboard_info}
                        </span>
                    )}
                </Row>
            </div>
        </div>
    );
}

export default RecentLeftPanel;
