import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch } from 'react-redux';
import allActions from '../../../store/actions';
import { errorCode } from '../../../services/constant';
import i18nLabels from '../../../services/i18n.service';

function PageNotFound() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(allActions.alertAction.showAlert(errorCode.pageNotFound));
    }, []);

    return (
        <Container fluid-xxl className="vertical-space-16  container-xxl">
            <Row>
                <Col md={3} className="d-none d-md-block ">
                    {i18nLabels.page_not_found}
                </Col>
            </Row>
        </Container>
    );
}

export default PageNotFound;
