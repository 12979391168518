import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux'
import { store } from './store/store'
import Signout from './components/features/signout/Signout';
import { app_config } from './services/constant';
import SigninError from './components/features/signinError/SigninError';
import  UnsubscribePage from './components/features/unsubscribe/Unsubscribe';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
//redirect
if(window.location.pathname.indexOf('signout') > -1) {
    if(localStorage.getItem("redirected-to-login")){
        localStorage.removeItem("redirected-to-login");
        window.location.href = app_config.REDIRECT_SIGN_IN ?? '';
    } else{
        root.render(
            <Signout/>
        );
    }
}else {
    if(window.location.href.includes('Invalid+user+attributes') || window.location.href.includes('Error+in+SAML') && !window.location.href.includes('AuthnFailed')) {
        root.render(
            <SigninError/>
        );
    } else if (window.location.pathname.indexOf('unsubscribe') > -1) {
        root.render(
            <Provider store={store}>
                <UnsubscribePage />
            </Provider>
        );
    } else {
        root.render(
            // <React.StrictMode>
                <Provider store={store}>
                    <App />
                </Provider>
          //  </React.StrictMode>
        );
    }
    
}
localStorage.removeItem("redirected-to-login");
