import React from 'react';
import { useDispatch } from 'react-redux';
import allActions from '../../../store/actions';
import ArgusOffcanvas from '../../common/ui/offcanvas/Offcanvas';
import DashboardDatasetSelection from './DashboardDatasetSelection';
import i18nLabels from '../../../services/i18n.service';

function CreateDashboardContainer() {
    const dispatch = useDispatch();

    const onCanvasClose = () => {
        dispatch(allActions.createDashboardAction.hideCreateDashboardPopup());
    };
    return (
        <div className="sharing-container">
            <ArgusOffcanvas
                onCanvasClose={onCanvasClose}
                title={i18nLabels.dashboard_create_title}>
                <DashboardDatasetSelection></DashboardDatasetSelection>
            </ArgusOffcanvas>
        </div>
    );
}
export default CreateDashboardContainer;
