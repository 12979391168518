import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import i18nLabels from '../../../services/i18n.service';

type Props = {
    isVizRendered: boolean;
    setExportType: (string) => void;
};


/**
 * React component for showing download dropdowns options
 * @return {DOM} Returm React DOM
 */
function DashboardDownload({ setExportType, isVizRendered }:Props) {
    return (
        <>
            <Dropdown>
                <Dropdown.Toggle disabled={!isVizRendered} className="argus-btn icon-text">
                    <span className="material-icons">download</span>
                    <span className="btn-text action-btn-text">{i18nLabels.dashboard_download}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item as="button"
                        onClick={() => {
                            setExportType('export-pdf');
                        }}>
                        {i18nLabels.download_option_pdf}
                    </Dropdown.Item>
                    <Dropdown.Item as="button"
                        onClick={() => {
                            setExportType('image');
                        }}>
                        {i18nLabels.download_option_image}
                    </Dropdown.Item>
                    <Dropdown.Item as="button"
                        onClick={() => {
                            setExportType('export-powerpoint');
                        }}>
                        {i18nLabels.download_option_ppt}
                    </Dropdown.Item>
                    <Dropdown.Item as="button"
                        onClick={() => {
                            setExportType('export-cross-tab');
                        }}>
                        {i18nLabels.download_option_data}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

export default DashboardDownload;
