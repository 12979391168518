import React from 'react';
import './tableau.scss';
import Button from '../ui/button/Button';
import i18nLabels from '../../../services/i18n.service';
type Props = {
    isFilterChanged: boolean;
    disableFilterBtn: boolean;
    onApplyFilterClick: () => void;
};

function HeaderEditTableau({ isFilterChanged, disableFilterBtn, onApplyFilterClick }: Props) {
    return (
        <div className="tableau-header">
            <div className="publish-instruction">
                {isFilterChanged && !disableFilterBtn? (
                    <span className="text-secondary applied-filters danger">
                        <span className="material-symbols-outlined">warning</span>
                        <strong>{i18nLabels.tableau_edit_warning_apply_filter}</strong>{i18nLabels.tableau_edit_warning_filter_adjustment}
                    </span>
                ) : null}

            {!isFilterChanged && !disableFilterBtn ? (
                                <span
                                    className="text-secondary applied-filters info">
                                    <span className="material-symbols-outlined">info</span>
                                    {i18nLabels.tableau_edit_warning_filter_reapply_instruction}
                                </span>
                            ) : null}

                {disableFilterBtn ? (
                    <span className="text-secondary applied-filters danger">
                    <span className="material-symbols-outlined">warning</span>{i18nLabels.tableau_edit_warning_filter_new_tab_instruction}
                </span>
                ) : null}
            </div>
                <div className="filter-btn-container">
                    <Button disabled={disableFilterBtn} onClick={onApplyFilterClick}>
                        {i18nLabels.tableau_edit_btn_reapply}
                    </Button>
                </div>
        </div>
    );
}

export default HeaderEditTableau;
