import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './signals.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams } from 'react-router-dom';
import { getSignalList } from '../../../services/api.service';
import { setDashboardName, setWorkbookId } from '../../../services/user.service';
import { checkUserInternal, getAlertDetails } from '../../../services/utils';
import allActions from '../../../store/actions';
import HandlingPagination from '../../common/ui/pagination/Pagination';
import { TypeWorkbook } from '../../../types/types';
import { RootState } from '../../../store/store';

import { globalConstant } from '../../../services/constant';
import SignalThumbnails from '../dashboard/SignalThumbnails';
import i18nLabels from '../../../services/i18n.service';

//Signal type for managing tabs and passing filters in API
const signalType = {
    SHARE: 'shared',
    NEW: 'new',
}

function SignalsHome() {
    const navigate = useNavigate();
    const params = useParams();
    const [view, setView] = useState<Array<TypeWorkbook> | null>(null);
    const [isInternalUser, setInternalUser] = useState(false);
    const [workbookCount, setWorkbookCount] = useState<number>(0);
    const [dashboardCount, setDashboardCount] = useState<number>(0);
    const [currentSelectedTab, setCurrentSelectedTab] = useState('');
    const [apiProgress, setApiProgress] = useState(true);
    const dispatch = useDispatch();

    const [siteName, profileName, reloadDashboard, userRole, isSignalsEnabled] = useSelector((state: RootState) => {
        return [
            state.user.siteName,
            state.user.profileName,
            state.user.reloadDashboard,
            state.user.role,
            state.user.isSignalsEnabled
        ];
    });

    useEffect(() =>{
        const isInternalUser = checkUserInternal(userRole);
        setInternalUser(isInternalUser);
        //For internal user default selected tab is "new", for external user default is "share"
        setCurrentSelectedTab(isInternalUser ? (params.signalType || signalType.NEW) : signalType.SHARE);
    }, [userRole])

    useEffect(() => {
        //If signal is not enabled for selected tanant , navigate to home page
        if(!isSignalsEnabled && siteName && profileName) {
            navigate('/');
        }
    }, [isSignalsEnabled])

    useEffect(() => {
        if (siteName && profileName && currentSelectedTab && isSignalsEnabled) {
            setTimeout(() => {
                setView(null);
                setWorkbookCount(0);
                setDashboardCount(0);
                getViews(0, 10);
            }, 200);
        }
    }, [siteName, profileName, currentSelectedTab, isSignalsEnabled]);

    useEffect(() => {
        if (reloadDashboard) {
            getViews(0, 10);
        }
    }, [reloadDashboard]);

    const onTabChange = (tabName) => {
        setCurrentSelectedTab(tabName);
        navigate(`/signals/list/${tabName}`);
    };
    const getViews = async (startNumber: number, lastNumber: number) => {
        if(!isSignalsEnabled){
            return;
        }
        setApiProgress(true);
        dispatch(allActions.loaderAction.showLoader());
        setView([]);
        getSignalList(siteName, profileName, startNumber, lastNumber, currentSelectedTab)
            .then((response) => {
                if(!response){
                    response = {
                        signals: {
                            workBooks:[]
                        }
                    }
                }
                const workbooks = response?.signals?.workBooks || [];
                setWorkbookId(null);
                setView(workbooks);
                setWorkbookCount(response?.signals?.totalCount || 0);
                setDashboardCount(response?.signals?.workBooks?.length || 0);

                dispatch(allActions.userAction.addUser(response.signals));
            })
            .catch((response) => {
                dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
            })
            .finally(() => {
                setApiProgress(false);
                dispatch(allActions.userAction.reloadDashboard(false));
                dispatch(allActions.loaderAction.hideLoader());
            });
    };

    const gotoView = (
        workBookName: string,
        workSheetId: string,
        workBookId: string,
        workbookUrlName: string,
        reportType:string
    ) => {
        setDashboardName(workBookName);
        setWorkbookId(workBookId);
        navigate(`/${reportType}/view/${siteName}/${workbookUrlName}/${workSheetId}`);
    };
    
    return (
        <Row className="signals">
            <Col>
                <div id="outer-wrapper" className="square">
                    <Row className="p-2">
                        <Col md={12}>
                            {' '}
                            <h2 className="text-left dashboard-text">{i18nLabels.signal_listing_title}</h2>
                        </Col>
                    </Row>
                    <div className="pt-0">
                        <Row id="list-dashboard" className="rounded-3 mx-2">
                            {isInternalUser && <div className="signal-headers">
                                <div
                                    onClick={() => onTabChange(signalType.NEW)}
                                    className={`tabs ${
                                        currentSelectedTab === signalType.NEW && 'selected'
                                    }`}>
                                    {i18nLabels.signal_listing_tab_new}
                                </div>
                                <div
                                    onClick={() => onTabChange(signalType.SHARE)}
                                    className={`tabs ${
                                        currentSelectedTab === signalType.SHARE && 'selected'
                                    }`}>
                                    {i18nLabels.signal_listing_tab_shared}
                                </div>
                                 
                            </div>}
                            
                            
                            <Row className='thumbnails-container'>
                            {view?.length ? (
                                <SignalThumbnails
                                    view={view}
                                    reportType={globalConstant.reportType.signals}
                                    gotoView={gotoView}></SignalThumbnails>
                            ) : (
                                <p className="text-center mb-5 mt-5">
                                    {apiProgress ? '' : 'No signals found'}
                                </p>
                            )}
                            </Row>
                        </Row>

                        <Row>
                            {view && <HandlingPagination
                                 totalDashboardCount={workbookCount}
                                 dashboardCountPerPage={dashboardCount}
                                 getViews={getViews}
                                 currentSelectedTab = {currentSelectedTab}
                                 ></HandlingPagination>
                            }
                            
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    );
}
export default SignalsHome;
