import React, { useEffect, useState } from 'react';
import { TypeSearchUser } from '../../../types/types';
import { getUserTypeClassName, sortByKey } from '../../../services/utils';

interface Props {
    selectedUserList: Array<TypeSearchUser>;
    onDeleteUser: (string) => void;
}

function ShareSelectedUsers({ selectedUserList, onDeleteUser }: Props) {
    const [sortOrderByName, setSortOrderByName] = useState<boolean | null>(null);
    const [sortOrderByUserType, setSortOrderByUserType] = useState<boolean | null>(null);
    const [userList, setUserList] = useState<Array<TypeSearchUser>>(selectedUserList);
    const onSortByNameClick = () => {
        let copyUsers = [...userList];
        copyUsers = sortByKey(!sortOrderByName, copyUsers, 'userName');
        setSortOrderByUserType(null);
        setSortOrderByName(!sortOrderByName);
        setUserList(copyUsers);
    };
    const onSortByUserTypeClick = () => {
        let copyUsers = [...userList];
        copyUsers = sortByKey(!sortOrderByUserType, copyUsers, 'userType');
        setSortOrderByName(null);
        setSortOrderByUserType(!sortOrderByName);
        setUserList(copyUsers);
    };

    useEffect(() => {
        let copyUsers = [...selectedUserList];
        if (sortOrderByName != null) {
            copyUsers = sortByKey(sortOrderByName, copyUsers, 'userName');
        }
        if (sortOrderByUserType != null) {
            copyUsers = sortByKey(sortOrderByUserType, copyUsers, 'userType');
        }
        setUserList(copyUsers);
    }, [selectedUserList]);

    const onGroupUserClick = (index) => {
        const userListCopy = [...userList];
        userListCopy[index].isGroupUsersShown = !userListCopy[index].isGroupUsersShown;
        setUserList(userListCopy);
    };

    return (
        <ul className="share-selected-users">
            <li className="selected-item-header">
                <div className="user-name" onClick={onSortByNameClick}>
                    <span>Name</span>
                    {sortOrderByName != null ? (
                        <span className="material-symbols-outlined sort-icon">sort_by_alpha</span>
                    ) : null}
                </div>
                <div className="user-type" onClick={onSortByUserTypeClick}>
                    <span>User Type</span>
                    {sortOrderByUserType != null ? (
                        <span className="material-symbols-outlined sort-icon">sort_by_alpha</span>
                    ) : null}
                </div>
                <span className="user-action">Action</span>
            </li>
            {userList.map((user, index) => {
                return (
                    <li
                        data-testid={user.userId}
                        className="selected-item-holder"
                        key={user.userId}>
                        <div className="selected-item">
                            <span className="user-name">
                                { user.userName }
                                {user.usersInGroup?.length && (
                                    <span
                                        className={user.isGroupUsersShown ? 'material-symbols-outlined group-opener expand-more' : 'material-symbols-outlined group-opener'}
                                        onClick={() => {
                                            onGroupUserClick(index);
                                        }}>
                                        {user.isGroupUsersShown ? 'expand_more' : 'chevron_right'}
                                    </span>
                                )}
                            </span>
                            <span
                                className={
                                    `user-type ${getUserTypeClassName(user)}`
                                }>
                                {user.userType}
                            </span>

                            <span
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onDeleteUser(user);
                                }}
                                className="material-symbols-outlined user-action delete-btn">
                                delete
                            </span>
                        </div>
                        {user.isGroupUsersShown &&
                            user.usersInGroup?.map((groupuser) => {
                                return (
                                    <div
                                        key={groupuser.userName}
                                        className="selected-item group-user">
                                        <span className="user-name">{groupuser.userName}</span>
                                        <span
                                            className={
                                                getUserTypeClassName(groupuser)
                                            }>
                                            {groupuser.userType}
                                        </span>

                                        <span className="user-action delete-btn"></span>
                                    </div>
                                );
                            })}
                    </li>
                );
            })}
        </ul>
    );
}
export default ShareSelectedUsers;
