import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { getDashboardList } from '../../../services/api.service';
import { RootState } from '../../../store/store';
import allActions from '../../../store/actions'
import {getAlertDetails} from '../../../services/utils'
import CreateDashboardContainer from './CreateDashboardContainer';
import {
    getProfile
} from '../../../services/user.service';
import SharingContainer from '../dashboardShare/SharingContainer';
import { globalConstant } from '../../../services/constant';

function DashboardContainer() {
    const dispatch = useDispatch();
   
      const [siteName,  profileName] = useSelector((state: RootState) => {
        return [ state.user.siteName,  state.user.profileName];
    });
      const userState = useSelector((state: RootState) => {
        return state.user;
    });

    const shareDashboardState = useSelector((state: RootState) => {
        return state.shareDashboard;
    });
    const createDashboardState = useSelector((state: RootState) => {
        return state.createDashboard;
    });
    useEffect(() =>{
        if(siteName && !userState.workBooks?.length){
            getDashboardList(siteName,  profileName || getProfile(), 0, 10, globalConstant.reportType.dashboard)
            .then((reponse) => {
                if(!reponse){
                    reponse = {
                        dashboard: {
                            workBooks:[],
                            favouriteDashboard:null
                        }
                    }
                }
                dispatch(allActions.userAction.addUser(reponse.dashboard));
            })
            .catch((response) => {
                dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
            });
        }
    }, [siteName])
    return (
        <>
            <Container fluid-xxl id="page-container" className="dashboard-container container-xxl">
                {shareDashboardState.isOpen ? <SharingContainer></SharingContainer> : null}
                {createDashboardState.isOpen ? <CreateDashboardContainer></CreateDashboardContainer> : null}
                <Outlet />
            </Container>
        </>
    );
}
export default DashboardContainer;
