
const navigationLink = [
    {
        label: 'Home',
        route: '/',
        icon: 'home',
        key: 'home'
    },
    {
        label: 'Dashboards',
        route: '/dashboard/list',
        icon: 'dashboard',
        key: 'dashboard'
    },
    {
        label: 'Signal Finder',
        route: '/signals/list',
        icon: 'radio',
        key: 'signals',
        dependentFlag: 'signalEnabled'
    }
]

export const getNavigationLinks = (flags) => {
    return navigationLink.filter(({ dependentFlag }) => {
        return !dependentFlag || flags[dependentFlag];
    });
};


export const getNavigationLinkForAudit = (navigationLinks) => {
  return navigationLinks.push({
    label:'User Info',
    route:'/userinfo',
    icon:'history',
    key: 'User Info',

})
}

