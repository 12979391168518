import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { RootState } from '../../../store/store';

import SharingContainer from '../dashboardShare/SharingContainer';

function SignalContainer() {
    const shareDashboardState = useSelector((state: RootState) => {
        return state.shareDashboard;
    });

    return (
        <>
            <Container fluid id="page-container" className="dashboard-container">
                {shareDashboardState.isOpen ? <SharingContainer></SharingContainer> : null}
                <Outlet />
            </Container>
        </>
    );
}
export default SignalContainer;
