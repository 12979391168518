import { useDispatch } from 'react-redux';
import allActions from '../store/actions';
import i18nLabels from '../services/i18n.service';

const useDeleteDashboard = () => {
    const dispatch = useDispatch();
    const onDeleteHandler = (workbookId, workBookName) => {
        dispatch(
            allActions.confirmationModelAction.showConfirmationModel({
                workbookId,
                workBookName,
                activity: 'DELETE_DASHBOARD',
                confirmationMessage: i18nLabels.dashboard_delete_confirmation
            })
        );
    };

    return { onDeleteHandler };
};

export default useDeleteDashboard;
