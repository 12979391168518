import React from 'react';
import { TypeWorkbook } from '../../../types/types';
import { dateFormat } from '../../../services/utils';
import { globalConstant } from '../../../services/constant';

type Props = {
    view: Array<TypeWorkbook>;
    gotoView: (
        workBookName: string,
        workSheetId: string,
        workBookId: string,
        workbookUrl: string,
        reportType:string
    ) => void;
};

function RecentSignalThumbnails({ view, gotoView }: Props) {
    return (
        <>
            {view?.map((views: TypeWorkbook) => {
                return (
                    <div
                        key={views.workBookId}
                        title={views.workBookName}
                        className="recent-thumbnail signals"
                        onClick={() =>
                            gotoView(
                                views.workBookName,
                                views.workSheetId,
                                views.workBookId,
                                views.contentUrl,
                                globalConstant.reportType.signals
                            )
                        }>
                        {views.signalType === 'Up' ? 
                            <div className="thumbnail-icon trading-up">
                                <span className="material-symbols-outlined">trending_up</span>
                            </div>
                         : 
                            <div className="thumbnail-icon trading-down">
                                <span className="material-symbols-outlined">trending_down</span>
                            </div>
                        }
                        <div>
                            <p className="thumb-title">{views.workBookName}</p>
                            <p className="signal-date">{dateFormat(views.createdDate, 'MMMM yyyy')}</p>
                        </div>
                    </div>
                );
            })}
        </>
    );
}
export default RecentSignalThumbnails;
