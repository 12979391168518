import React, { useState, useEffect } from 'react';
import allActions from '../../../store/actions';
import Row from 'react-bootstrap/Row';
import ViewTableau from '../../common/tableau/ViewTableau';
import { getVizViewURL } from '../../../services/utils';
import { getTicket } from '../../../services/api.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import useGetFilters from '../../../hooks/useGetFilters';
import i18nLabels from '../../../services/i18n.service';

interface Props {
    workBookName: string | null;
    workSheetId: string | null;
    workBookId: string | null;
    siteName: string | null;
}

/**
 * React component for showing favorite dashbaord
 * @param {string} workBookName - Workbook name
 * @param {string} workSheetId - worksheet id
 * @param {string} workBookId - worksheet id
 * @param {string} siteName - site id
 * @return {DOM} Returm React DOM
 */
function FavoriteDashboard({ workBookName, workSheetId, workBookId, siteName }: Props) {
    const [newUrl, setNewURL] = useState('');
    const [isApiCallProgress, setIsApiCallProgress] = useState(true);
    const dispatch = useDispatch();

    const { appliedFilters } = useSelector((state: RootState) => {
        return state.dimensionFilters;
    });

    const {dimensionFilters} = useGetFilters(workBookId);

    useEffect(() => {
        dispatch(allActions.dimensionFiltersAction.addServerFilters(dimensionFilters));
    }, [dimensionFilters]);
  
    useEffect(() => {
        getTicketDashboard();
    }, [workBookName, workSheetId, siteName]);

    const getTicketDashboard = () => {
        getTicket(siteName)
        .then((reponse) => {
            if (reponse.ticket != null && reponse.ticket != -1) {
                if (workBookName) {
                    setNewURL(
                        getVizViewURL(reponse.ticket, workBookName, workSheetId, siteName)
                    );
                } else {
                    setNewURL('');
                }
            }
        }).finally(() =>{
            setIsApiCallProgress(false);
        })
    };
    if(isApiCallProgress) {
        return (<Row className="favorite-tableau-container empty-container"></Row>)
    }
    return (
        <>
            
                {newUrl ? (
                    <Row className="favorite-tableau-container">
                    <ViewTableau reportType ={'dashboard'} screenType={'FAVORITE'} selectedFilters={appliedFilters} url={newUrl}></ViewTableau>
                    </Row>
                ) : (
                    <Row className="favorite-tableau-container empty-container">
                    <p>{i18nLabels.dashboard_pin_messgae}</p>
                    </Row>
                )}
        </>
    );
}

export default FavoriteDashboard;
