const showConfirmationModel = (payload) => {
    return {
        type: "SHOW_CONFIRMATION_MODEL",
        payload
    }
}

const hideConfirmationModel = () => {
    return {
        type: "HIDE_CONFIRMATION_MODEL"
    }
}

const executeConfirmation = () => {
    return {
        type: "EXECUTE_CONFIRMATION"
    }
}


export default {
    showConfirmationModel,
    hideConfirmationModel,
    executeConfirmation
}