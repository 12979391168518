import { globalConstant } from "../../services/constant";

const initialState = {
    isOpen: false,
    workbookId: '',
    workbookName: '',
    contentUrl: '',
    reportType: globalConstant.reportType.dashboard
}

const shareDashboard = (
    state = initialState,
    { type, payload }
) => {
    switch (type) {
        case 'SHOW_SHARE_POPUP':
            return {
                ...state,
                isOpen: true,
                workbookId: payload.workbookId,
                workbookName:payload.workbookName,
                contentUrl:payload.contentUrl,
                reportType: payload.reportType || globalConstant.reportType.dashboard
            };
        case 'HIDE_SHARE_POPUP':
            return {
                ...state,
                isOpen: false
            };
        default:
            return state;
    }
};
export default shareDashboard;
