import React from 'react';
import './SharingContainer.scss';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../store/actions'
import ArgusOffcanvas from '../../common/ui/offcanvas/Offcanvas';
import DashboardShare from './DashboardShare';
import type { RootState } from '../../../store/store';

function SharingContainer() {
    const dispatch = useDispatch();
    const { workbookId, workbookName, reportType } = useSelector((state: RootState) => {
        return state.shareDashboard;
  });
  
  
  const [siteName, profileName] = useSelector((state: RootState) => {
    return [ state.user.siteName,  state.user.profileName];
});
    const onCanvasClose = () => {
        dispatch(allActions.shareDashboardAction.hideSharePopup());
    }
    return (
        <div className='sharing-container'>
            <ArgusOffcanvas onCanvasClose={onCanvasClose} title={`Share Dashboard - ${workbookName} `}>
                <DashboardShare onClose={onCanvasClose} {...{workbookId, siteName, profileName, workbookName, reportType}}></DashboardShare>
            </ArgusOffcanvas>
        </div>
    );
}
export default SharingContainer;
