import moment from 'moment';
import {
    app_config,
    roles,
    userTypes
} from './constant';
import { AES, enc } from 'crypto-js';
export const getVizViewURL = (ticket, workbookName, dashboardName, siteName) => {
    return `${app_config.TABLEAU_URL}/trusted/${ticket}/t/${siteName}/views/${workbookName}/${dashboardName}`;
};

export const getVizEditURL = (workbookName, dashboardName, siteName) => {
    return `${app_config.TABLEAU_URL}/t/${siteName}/authoring/${workbookName}/${dashboardName}`;
};

export const getVizEditURLWithToken = (ticket, workbookName, dashboardName, siteName) => {
    return `${app_config.TABLEAU_URL}/trusted/${ticket}/t/${siteName}/authoring/${workbookName}/${dashboardName}`;
};

export const getNewVizURL = (siteName, ticket, workbook, sheet) => {
    return `${app_config.TABLEAU_URL}/trusted/${ticket}/t/${siteName}/authoring/${workbook}/${sheet}`;
};


export const openSamlSignin = () => {
    localStorage.removeItem('system-notification-off');
    window.location.replace(`https://${app_config.COGNITO_DOMAIN}/oauth2/authorize?identity_provider=${app_config.IDENTITY_PROVIDER}&redirect_uri=${
        app_config.REDIRECT_SIGN_IN
        }&response_type=code&client_id=${
            app_config.AWS_USER_WEB_CLIENT_ID
        }&scope=phone email openid aws.cognito.signin.user.admin profile`);
};

export const signoutRedirectLogin = () => {
    window.location.replace(`https://${app_config.COGNITO_DOMAIN}/logout?identity_provider=${app_config.IDENTITY_PROVIDER}&redirect_uri=${
        app_config.REDIRECT_SIGN_IN
        }&response_type=code&client_id=${
            app_config.AWS_USER_WEB_CLIENT_ID
        }&scope=phone email openid aws.cognito.signin.user.admin profile`);
};

export const openSamlSignout = () => {
    window.location.replace(`https://${app_config.COGNITO_DOMAIN}/logout?&logout_uri=${
        app_config.REDIRECT_SIGN_OUT
            }&client_id=${
                app_config.AWS_USER_WEB_CLIENT_ID
            }`);
    
};

export const openSignoutPage = () => {
    window.location.href = app_config.REDIRECT_SIGN_OUT;
};



export const getAlertDetails = (apiResponse, type) => {
    return {
        type,
        code: String(apiResponse.errorCode || apiResponse.code || ''),
        message: String(apiResponse.message || '')
    };
};

const getHrsMinutes = (diff) => {
    const diffHours = diff / (60 * 60);
    const getHours = Math.abs(Math.round(diffHours)); //Get Hours Difference between 2 dates

    const diffMinutes = diff / 60;
    const getMinutes = Math.abs(Math.round(diffMinutes)); //Get Minutes Difference between 2 dates

    if (getHours > 0) {
        return getHours == 1 ? getHours + ' hour ago' : getHours + ' hours ago';
    } else {
        if (getMinutes == 0) {
            return 'Just Now';
        } else if (getMinutes == 1) {
            return getMinutes + ' min ago';
        } else {
            return getMinutes + ' mins ago';
        }
    }
};

export const getDays = (lastAccessedDate) => {
    const accessedDate = lastAccessedDate.replace(/-/g, '/');
    const current = new Date();
    const currentDate = `${
        current.getUTCMonth() + 1
    }/${current.getUTCDate()}/${current.getUTCFullYear()} ${current.getUTCHours()}:${current.getUTCMinutes()}:${current.getUTCSeconds()}`;

    const diff = (new Date(currentDate) - new Date(accessedDate)) / 1000;
    const diffDays = diff / (60 * 60 * 24);
    const getDays = Math.abs(Math.round(diffDays)); //Get Days Difference between 2 dates

    if (getDays == 0) {
        return getHrsMinutes(diff);
    } else if (getDays < 7) {
        return getDays == 1 ? getDays + ' day ago' : getDays + ' days ago';
    } else if (getDays >= 7 && getDays < 30) {
        const diffWeek = diff / (60 * 60 * 24 * 7);
        const getWeek = Math.abs(Math.round(diffWeek));
        return getWeek == 1 ? getWeek + ' week ago' : getWeek + ' weeks ago';
    } else if (getDays >= 30) {
        const diffMonth = diff / (60 * 60 * 24 * 7 * 4);
        const getMonth = Math.abs(Math.round(diffMonth));
        return getMonth == 1 ? getMonth + ' month ago' : getMonth + ' months ago';
    }
};

export const sortByKey = (isAscendingOrder, list, key) => {
    const copyList = list;
    if (isAscendingOrder) {
        return copyList.sort((a, b) => a[key].localeCompare(b[key]));
    } else {
        return copyList.sort((a, b) => b[key].localeCompare(a[key]));
    }
};

export const getUserTypeClassName = (user ) => {
    let className;
    if(user.isGroup) {
        return 'group-user';
    }
    switch (user.userType) {
        case userTypes.ARGUSUSER:
            className = 'internal-user';
            break;
        case userTypes.GROUPUSER:
            className = 'group-user';
            break;
        default:
            className = 'external-user';
    }

    return className;
};

export const getRedirectRoute = (currentPathName) => {
    if (currentPathName.includes('/dashboard')) {
        return '/dashboard/list';
    } else if (currentPathName.includes('/signals')) {
        return '/signals/list';
    } else if (currentPathName.includes('/contactus')) {
        return '/contactus';
    } else if (currentPathName.includes('/faq')) {
        return '/faq';
    } else if (currentPathName.includes('/glossary')) {
        return '/glossary';
    } else if (currentPathName.includes('/tutorialtraining')) {
        return '/tutorialtraining';
    }else if (currentPathName.includes('/audit')) {
        return '/audit';
    }
     else {
        return  '/';
    }
};

export const checkUserInternal = (role) =>{
    return roles.INTERNAL_USERS.indexOf(role) > -1;
}

export const isAuditAllowed = (role) =>{
    return roles.AUDIT_USERS.indexOf(role) > -1;
}

export const encrpt = (text) =>{
    AES.encrypt(text, 'test');
    return AES.encrypt(text, 'test')
}

export const dcrpt = (text) =>{
    const bytes = AES.decrypt(text, 'test');
    return bytes.toString(enc.Utf8);
}


export const isSessionExpired = () =>{
    const _sessionTime = localStorage.getItem('session-expiry-time');
    const expiryTime = _sessionTime && dcrpt(_sessionTime);
    const expTime = new Date(String(expiryTime)).getTime() - new Date().getTime();
    return !isNaN(expTime)  &&  expTime < 0;
}

export const dateFormat = (date, format) =>{
    return moment(date).format(format);
}


export const addLogs = (key, message) =>{
    const logs = localStorage.getItem(key) ?? 'start>>';
    const logsArr = logs.split('\n');
    const logsString = logsArr.length > 10 ? logsArr.slice(-10) : logsArr
    localStorage.setItem(key, `${logsString.join('\n')} \n ${new Date()} >> ${message}`);
}

export const clearCache = () => {
    const signoutLogs = localStorage.getItem('signout');
    const workbookLogs = localStorage.getItem('Workbook-log');
    const filtersLogs = localStorage.getItem('filter-logs');
    const isSessionTimeout = localStorage.getItem('session-timeout');

    localStorage.clear();

    localStorage.setItem('signout', signoutLogs);
    localStorage.setItem('Workbook-log', workbookLogs);
    localStorage.setItem('filter-logs', filtersLogs);
    if(isSessionTimeout) {
        localStorage.setItem('session-timeout', isSessionTimeout);
    }
}

export const globalLogout = (Auth) => {
    try {
        if (!window.location.href.includes('localhost:3000')) {
            Auth.signOut({ global: true })
        }
    } catch (error) {
        addLogs('signout', `Signout response>>> ${error}`);
    }
}

export const formatOwnerName = ({ownerName, ownerCompany}) =>{
    const companyName = ownerCompany ? `, ${ownerCompany}` : '';
    return ownerName ? `${ownerName}${companyName}` : 'NA'
}

const maskBankName = (bankName) =>{
   return bankName?.includes('Argus2-') ? bankName.split('Argus2-')[1] : bankName
}

export const calculateDateStatus = (timeStamp) => {
    const currentDate = new Date();
    const givenDate = new Date(timeStamp * 1000);
    // const givenDate = new Date();  for mockserver testing
    const formatter = new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
    const [{value: month},,{value: day},,{value: year},,{value: hour},,{value: minute},,{value: dayPeriod}] = formatter.formatToParts(givenDate);

    currentDate.setHours(0,0,0,0);
    givenDate.setHours(0,0,0,0);

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth()+1;
    const currentDay = currentDate.getDate();
    const givenYear = givenDate.getFullYear();
    const givenMonth = givenDate.getMonth()+1;
    const givenDay = givenDate.getDate();

    if (givenYear === currentYear && givenMonth === currentMonth && givenDay === currentDay) {
        return `Today, ${hour}:${minute} ${dayPeriod.toUpperCase()} `;
    } else if (givenYear === currentYear && givenMonth === currentMonth && givenDay === (currentDay-1)){
        return `Yesterday, ${hour}:${minute} ${dayPeriod.toUpperCase()} `;
    } else {
        return `${year}-${month}-${day}, ${hour}:${minute} ${dayPeriod.toUpperCase()} `
    }
}

const formatSessionTime = (counter) =>{
    const seconds = counter < 0 ? 0 : counter;
    const min = Math.floor(seconds / 60).toString().padStart(2, '0');
    const sec = Math.floor(seconds % 60).toString().padStart(2, '0');
    return `${min}: ${sec}`
 }
 
export const getModifiedDescFromGroupName = (group) => {
    return  group.slice(group.split('_', 2).join('_').length + 1).replaceAll("_", " ");
}

export const getSiteAndProfileName = (group) => {
    return  group.slice(0, group.split('_', 2).join('_').length + 1);
}

export default { maskBankName, formatSessionTime, getModifiedDescFromGroupName, getSiteAndProfileName}