import { Container, Row, DropdownButton, Dropdown } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import "./Audit.scss";
import "../../../../App.scss";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../store/actions";
import { RootState } from "../../../../store/store";
import { useNavigate } from "react-router-dom";
import { auditRoles } from "../../../../services/constant";
import UserAccess from "./UserAccess";
import { getUserAccessList } from '../../../../services/api.service';
import { UserAccessData as Data, UserAccessEvents as Events } from "../types/auditTypes";
import * as ExcelJS from 'exceljs';
import {saveAs} from 'file-saver';
import moment from 'moment';
import {getAlertDetails} from '../../../../services/utils'

const UserAccessHome = () => {

    const [disableDownload, currentRole] = useSelector((state: RootState) => {
        return [state.audit.disableDownload, state.audit.currentRole];
    })

    const [siteName, profileName, userEmail] = useSelector((state: RootState) => {
        return [state.user.siteName, state.user.profileName, state.user.userEmail];
    });

    const [userAccessData, setUserAccessData] = useState<Array<Data>>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(0);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleDownload = (type) => {
        dispatch(allActions.auditAction.addDownloadType(type));
    }
    useEffect(() => {

        if (currentRole && !auditRoles.includes(currentRole.split("_")[1])) {
            navigate('/');
        }
    }, [currentRole])

    useEffect(() => {
        if (siteName && profileName) {
            fetchUserAccessList();
        }
    }, [siteName, profileName])

    const fetchUserAccessList = () => {
        dispatch(allActions.loaderAction.showLoader());
        getUserAccessList(siteName).then((response: Events) => {
            setUserAccessData(response !== null && response.userAccessInfo ? response.userAccessInfo : [])
        }).catch((response) => {
            dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
            setUserAccessData([])
        }
        ).finally(() => {
            dispatch(allActions.loaderAction.hideLoader())
        })
    }

    const downloadData = (type) => {
        dispatch(allActions.loaderAction.showLoader());
        let accessData = Array.from(userAccessData);
        if (type === 'Current'){
            accessData = Array.from(userAccessData).splice(currentPage*rowsPerPage, rowsPerPage);
        }
        exportFile(accessData);
        dispatch(allActions.loaderAction.hideLoader());
    }

    const addBorder = (sheet, cell) => {
        sheet.getCell(cell).border = {
            top: {style: 'thin', color: { argb: 'FFFFFF'}},
            bottom: {style: 'thin', color: { argb: 'FFFFFF'}},
            left: {style: 'thin', color: { argb: 'FFFFFF'}},
            right: {style: 'thin', color: { argb: 'FFFFFF'}}
        }
    }

    const exportFile = async (data) => {
        const workbookName = 'Access_' + moment(new Date()).format("YYYYMMDDHHmmss");
        const workbook = new ExcelJS.Workbook();
        const sheet1 = workbook.addWorksheet('Summary');
        sheet1.mergeCells("F4", "G4");
        sheet1.mergeCells("F5", "G5");
        sheet1.views = [{showGridLines: false}];

        sheet1.getCell("F4").alignment = { horizontal: 'center', vertical: 'middle'};
        sheet1.getCell("F4").fill = { type: 'pattern', pattern: 'solid', fgColor: {argb: '333F4F'}};
        sheet1.getCell("F4").value = "User Access";
        sheet1.getCell("F6").value = "Tenant Name";
        sheet1.getCell("F6").fill = { type: 'pattern', pattern: 'solid', fgColor: {argb: '333F4F'}};
        sheet1.getCell("G6").value = siteName;
        sheet1.getCell("G6").fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'BFBFBF'}};
        sheet1.getCell("F7").value = "Generated By";
        sheet1.getCell("F7").fill = { type: 'pattern', pattern: 'solid', fgColor: {argb: '333F4F'}};
        sheet1.getCell("G7").value = userEmail;
        sheet1.getCell("G7").fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'BFBFBF'}};
        sheet1.getCell("F8").value = "Generated On";
        sheet1.getCell("F8").fill = { type: 'pattern', pattern: 'solid', fgColor: {argb: '333F4F'}};
        sheet1.getCell("G8").value =  moment(new Date()).format("DD/MM/YYYY hh:mm:ss");
        sheet1.getCell("G8").fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'BFBFBF'}};
        addBorder(sheet1, "F6");
        addBorder(sheet1, "F7");
        addBorder( sheet1, "F8");
        addBorder(sheet1, "G6");
        addBorder(sheet1, "G7");
        addBorder(sheet1,"G8");
        sheet1.getColumn('F').width = 40;
        sheet1.getColumn('G').width = 40;
        sheet1.getColumn('F').font = { color: {argb: 'FFFFFF'}, size: 12};
        sheet1.getColumn('G').font = { color: {argb: 'FFFFFF'}, size: 12};
        const sheet2 = workbook.addWorksheet('Data');
        const columns = ["S.No", "Type", "User Name", "Profile", "Group", "Role" ];
        sheet2.addRow(columns).font = {bold: true, color: {argb: 'FFFFFF'}};
        sheet2.getCell("A1").fill =  { type: 'pattern', pattern: 'solid', fgColor: {argb: '333F4F'}};
        sheet2.getCell("B1").fill =  { type: 'pattern', pattern: 'solid', fgColor: {argb: '333F4F'}};
        sheet2.getCell("C1").fill =  { type: 'pattern', pattern: 'solid', fgColor: {argb: '333F4F'}};
        sheet2.getCell("D1").fill =  { type: 'pattern', pattern: 'solid', fgColor: {argb: '333F4F'}};
        sheet2.getCell("E1").fill =  { type: 'pattern', pattern: 'solid', fgColor: {argb: '333F4F'}};
        sheet2.getCell("F1").fill =  { type: 'pattern', pattern: 'solid', fgColor: {argb: '333F4F'}};
        
        data.forEach((ele, index: number) => {
            sheet2.addRow([index+1, ele.userType, ele.user, ele.profile, ele.groups, ele.role]);
        })
        sheet2.getColumn('A').width = 10;
        sheet2.getColumn('B').width = 20;
        sheet2.getColumn('C').width = 20;
        sheet2.getColumn('D').width = 20;
        sheet2.getColumn('E').width = 20;
        sheet2.getColumn('F').width = 20;
        const blob = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([blob]), workbookName+'.xlsx');
    }


    return (
        <>
            <div className="audit">
                <Container fluid style={{ marginLeft: '2%', width: '96.6%', marginTop: '67px' }}>
                    <Row >
                        <div className="d-flex justify-content-between">
                            <div className="audit-page-title  pt-1">User Access</div>
                            {process.env.REACT_APP_AUDIT_DOWNLOAD === "true" &&
                                <div className="ms-5 ps-5">
                                    <DropdownButton onSelect={handleDownload} disabled={disableDownload}
                                        title="Download" className={`justify-content-between audit-page-download ${!disableDownload ? "audit-page-download-enable" : "audit-page-download-disable "}`} variant={!disableDownload ? "outline-warning" : "outline-secondary"} id="dropdown-basic">
                                        <Dropdown.Item eventKey="All" id="All" className="audit-page-download-item" onClick={() => downloadData("All")}>All Logs</Dropdown.Item>
                                        <Dropdown.Item eventKey="Current" id="Current" className="audit-page-download-item" onClick={() => downloadData("Current")}>Current Page</Dropdown.Item>


                                    </DropdownButton></div>}
                        </div>
                    </Row>
                </Container>
                <Container fluid className="  audit-page">
                    <UserAccess userAccessData={userAccessData} setCurrentPage = {setCurrentPage} setRowsPerPage = {setRowsPerPage} />
                </Container>
            </div>
        </>
    )
}

export default UserAccessHome;
