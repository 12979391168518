import { useState } from 'react';
import moment from 'moment';
import { globalConstant, nonOverallFilters } from '../../../services/constant';
import { addLogs } from '../../../services/utils';

const checkAllAppliedMatching = (options, appliedValues) => {
    const optionCopy = [...options];
    if (optionCopy[0] === 'All') {
        optionCopy.shift();
    }

    if (optionCopy.length !== appliedValues.length) {
        return false;
    }
    const anyNotApplied = optionCopy.find((element) => {
        return appliedValues.indexOf(element) === -1;
    });
    return !anyNotApplied;
};

const getGroupMatchingDim = (groupId, filters) => {
    return filters.find((filter) => {
        return groupId.indexOf(filter.key) > -1;
    }).key;
};

const checkApplyNotRequired = (fieldKey, tableauApplied, localOptions, usedDim) => {
    return (
        (checkAllAppliedMatching(localOptions, tableauApplied) &&
            (localOptions.indexOf('All') === -1 ||
                nonOverallFilters.indexOf(fieldKey) > -1 ||
                usedDim.indexOf(fieldKey) > -1)) ||
        (usedDim.indexOf(fieldKey) === -1 &&
            tableauApplied.length === 1 &&
            tableauApplied.indexOf('Overall') > -1 &&
            (localOptions.indexOf('All') > -1 || localOptions.indexOf('Overall') > -1)) || 
            (tableauApplied.length === 0 && localOptions.indexOf('All') > -1 && nonOverallFilters.indexOf(fieldKey) > -1)
    );
};

const getGroupFilter = (tableauFilters, filter) => {
    return tableauFilters.filter((tableauFilter) => {
        return (
            tableauFilter.fieldId.indexOf('(group)') &&
            tableauFilter.fieldId.indexOf(filter.key) > -1
        );
    });
};

const useTableauFilter = () => {
    const [isFilterChanged, setIsFilterChanged] = useState(false);
    //This state is for test case only
    const [allAppliedFilters, setAllAppliedFilters] = useState('');
    const traceAppliedFilters = (key, filter) =>{
     setAllAppliedFilters((previousState) => `${previousState}>>${key}::${filter.join()}`)

    }

    const applyFilters = async (sheet, usedDimenstion, filters) => {
        return new Promise((resolve) => {
            console.log('start apply filter');
            sheet.getFiltersAsync().then(async (tableauFilters) => {
                for (const filter of filters) {
                    const matchedAppliedFilter = tableauFilters.find((dim) => {
                        return dim.fieldName === filter.key;
                    });
                    if (matchedAppliedFilter) {
                        const appliedValuesLabels = matchedAppliedFilter.appliedValues?.map(
                            (usedDimValue) => {
                                if (filter.key === 'reporting_date') {
                                    return moment(usedDimValue.formattedValue).format(globalConstant.dateDisplayFormat);
                                }
                                return usedDimValue.value;
                            }
                        );
                        if (
                            checkApplyNotRequired(
                                filter.key,
                                appliedValuesLabels || [],
                                filter.options,
                                usedDimenstion
                            )
                        ) {
                            console.log('No need to apply filter');
                        } else {
                            //Not used dimension
                            if (
                                (filter.options.indexOf('All') > -1 || filter.options.indexOf('Overall') > -1) &&
                                usedDimenstion.indexOf(filter.key) === -1 &&
                                nonOverallFilters.indexOf(filter.key) === -1
                            ) {
                                await sheet
                                    .applyFilterAsync(filter.key, ['Overall'], 'replace')
                                    .then(() => {
                                        setIsFilterChanged(true);
                                        traceAppliedFilters(filter.key, ['Overall'])
                                        console.log(filter.key + ' filters>> ' + 'Overall');
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                                const groupFilters = getGroupFilter(tableauFilters, filter);
                                for (const gfilter of groupFilters) {
                                    await sheet.clearFilterAsync(gfilter.fieldName);
                                    await sheet.applyFilterAsync(
                                        gfilter.fieldName,
                                        ['Overall'],
                                        'replace'
                                    );
                                    traceAppliedFilters(gfilter.fieldName, ['Overall'])
                                }
                            } else {
                                let op = filter.options.filter((el) => {
                                    return el !== 'All';
                                });
                               
                                if (filter.key === 'reporting_date') {
                                    op = op.map((value) => {
                                        return moment(value).format(globalConstant.dateFormat);
                                    });
                                }
                                console.log('op>>>' + op);
                                await sheet
                                    .applyFilterAsync(filter.key, op, 'replace')
                                    .then(() => {
                                        setIsFilterChanged(true);
                                        console.log(
                                            sheet.name +
                                                ' > ' +
                                                filter.key +
                                                ' filters2>> ' +
                                                op.join()
                                        );
                                        traceAppliedFilters(filter.key, op)
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                            }
                        }
                    } else {
                        if (
                            (filter.options.indexOf('All') > -1 || filter.options.indexOf('Overall') > -1) &&
                            usedDimenstion.indexOf(filter.key) === -1 &&
                            nonOverallFilters.indexOf(filter.key) === -1 &&
                            !filter.isCustom
                        ) {
                            await sheet
                                .applyFilterAsync(filter.key, ['Overall'], 'replace')
                                .then(() => {
                                    console.log(
                                        sheet.name + ' > ' + filter.key + ' filters3>> ' + 'Overall'
                                    );
                                    traceAppliedFilters(filter.key, ['Overall'])
                                    setIsFilterChanged(true);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        } else if (
                            nonOverallFilters.indexOf(filter.key) > -1 &&
                            filter.options.indexOf('All') > -1
                        ) {
                            console.log('No need to apply filter');
                        } else {
                            let op = filter.options.filter((el) => {
                                return el !== 'All';
                            });
                           
                            if (filter.key === 'reporting_date') {
                                op = op.map((value) => {
                                    return moment(value).format(globalConstant.dateFormat);
                                });
                            }
                            console.log('op>>>' + op);
                            await sheet
                                .applyFilterAsync(filter.key, op, 'replace')
                                .then(() => {
                                    console.log(
                                        sheet.name + ' > ' + filter.key + ' filters4>> ' + op.join()
                                    );
                                    traceAppliedFilters(filter.key, op)
                                    setIsFilterChanged(true);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        }
                    }
                }
                console.log('finish apply filters');
                resolve('success');
            });
        });
    };

    async function applyFilterOnWoksheet(sheet, filters, viz) {
        console.log('apply filter triggered');
        addLogs('filter-logs', 'Applying filter started');
        const usedDimenstion: string[] = [];
        await sheet.getSummaryDataAsync().then((res) => {
            res.columns.forEach(({ fieldName, fieldId }) => {
                if (fieldId.indexOf('(group)') > -1) {
                    const matchedDim = getGroupMatchingDim(fieldId, filters);
                    usedDimenstion.push(matchedDim);
                }
                usedDimenstion.push(fieldName);
            });
        });
        if (usedDimenstion.length) {
            await viz.pauseAutomaticUpdatesAsync();
            await applyFilters(sheet, usedDimenstion, filters);
            await viz.resumeAutomaticUpdatesAsync();
        } else {
            await applyFilters(sheet, usedDimenstion, filters);
            const usedUpdatedDimenstion: string[] = [];

            await sheet.getSummaryDataAsync().then(async (res) => {
                res.columns.forEach(({ fieldName, fieldId }) => {
                    if (fieldId.indexOf('(group)') > -1) {
                        const matchedDim = getGroupMatchingDim(fieldId, filters);
                        usedUpdatedDimenstion.push(matchedDim);
                    }
                    usedUpdatedDimenstion.push(fieldName);
                });
                if (usedUpdatedDimenstion.length) {
                    await applyFilters(sheet, usedUpdatedDimenstion, filters);
                }
            });
        }
        return Promise.resolve(true);
        
    }

    async function  applySelectedFilters (sheet, filters, isVizRendered, viz) {
        if(!isVizRendered || !filters?.length || !sheet) {
            return;
        }
        if (sheet.sheetType === globalConstant.reportType.dashboard) {
            if (sheet.worksheets) {
                for (const worksheet of sheet.worksheets) {
                    await applyFilterOnWoksheet(worksheet, filters, viz);
                }
            } else {
                sheet.initializeWithPublicInterfaces();
                for (const worksheet of sheet.worksheetsImpl) {
                    await applyFilterOnWoksheet(worksheet, filters, viz);
                }
            }
        } else {
            await applyFilterOnWoksheet(sheet, filters, viz);
        }
        addLogs('filter-logs', 'Applying filter Completed');
    }
    return { applySelectedFilters, isFilterChanged, setIsFilterChanged, allAppliedFilters };
};

export default useTableauFilter;
