import React, { useState, useEffect } from "react";
import "./Audit.scss";
import {Row,Col,Container,Offcanvas,Card} from "react-bootstrap";
import { AuditDetail ,Attachment} from "../types/auditTypes";
import { downloadExcel, getAttachmentUrl } from "../../../../services/api.service";
import allActions from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../store/store';
type Props = {
  details: AuditDetail
}

const AuditDetails = (props: Props) => {
  const { details } = props;
  const [show, setShow] = useState<boolean>(false);
  useEffect(() => {
    setShow(true)
  }, [details])
  const dispatch = useDispatch();
  const [siteName] = useSelector((state: RootState) => {
    return [state.user.siteName];
  });

  const handleClose = () => { setShow((prevState) => !prevState); }
  const getStateChangeDetails = () => {
    if (details.stateChangeCurrent !== undefined || details.stateChangePrevious !== undefined) {
      if (Object.keys(details?.stateChangeCurrent).length > 0 || Object.keys(details?.stateChangePrevious).length > 0) {
        const stateChangeArray: Array<object> = [];
        stateChangeArray.push(details.stateChangeCurrent);
        stateChangeArray.push(details.stateChangePrevious);
        const uniquekeys = Object.keys(Object.assign({}, ...stateChangeArray));
        return (
          <>
            <Row className="mb-4">
              <Col >
                <Card>
                  <Card.Header>Before</Card.Header>
                  <Card.Body>
                    <Card.Text>
                      {uniquekeys.map((key) => (
                        <div key={key} className="fs-12 l-h-16 details" id={key}><span className="details-key">{key}:</span><span className="details-value">{' '} {details.stateChangePrevious[key]}</span></div>
                      ))}
                    </Card.Text>

                  </Card.Body>
                </Card></Col>
              <Col >
                <Card>
                  <Card.Header>After</Card.Header>
                  <Card.Body>
                    <Card.Text>
                      {uniquekeys.map((key) => (
                        <div key={key} className="fs-12 l-h-16 details" id={key}><span className="details-key">{key}:</span><span className="details-value">{' '} {details.stateChangeCurrent[key]}</span></div>
                      ))}
                    </Card.Text>

                  </Card.Body>
                </Card></Col>
            </Row>
          </>
        )
      }
    }
  }

  const additionalData = () => {
    if (details.additionalData !== undefined || (details.sendNotification!=='N' && details.channels?.length!==0)) {
      return (
        <>
          <Card>
            <Card.Header className="fs-14 l-h-14">Details</Card.Header>
            <Card.Body>
              <Card.Text>
                {details.additionalData !== undefined && Object.keys(details.additionalData).map((key) => (
                  <div key={key} className="fs-12 l-h-16 details" id={key}><span className="details-key">{key}:</span> <span className="details-value">{' '} {details.additionalData[key]}</span></div>
                ))}
                {details.sendNotification!=='N' && details.channels?.length!==0 && <div>
                  <div className="fs-12 l-h-16 details"><span className="details-key">sendNotification:</span><span className="details-value">{' '}{details.sendNotification}</span></div>
                  <div className="fs-12 l-h-16 details">
                  <span className="details-key">recipients:{' '}
                    {details.channels?.map((chan,ind)=>(
                      chan.recipients.map((recipient,index)=>
                        <span key={""+index} className="details-value">{recipient}{(index!==chan.recipients.length-1 || ind !==(details.channels?.length && (details.channels?.length-1)))  && ","}</span>)
                    ))}
                    </span>
                  </div>
                  </div>}
              </Card.Text>

            </Card.Body>
          </Card>
        </>
      )
    }
  }
  const downloadAttachment = (attachment : Attachment) => {
    dispatch(allActions.loaderAction.showLoader()); 
                  getAttachmentUrl(attachment).then(response => {
                    downloadExcel(response);
                  }).finally(() =>  dispatch(allActions.loaderAction.hideLoader()) );
  }

  const displayAttachmentDetails = () => {
    if(details.attachment !== undefined && details.attachment.keyId !== undefined) {
      return (
        <div className="fs-14 l-h-16 details">
          <span> Attachment: {' '} </span> <span className="attachment" onClick={() => downloadAttachment(details.attachment)}> Click here to download the file </span>
        </div>
      )
    }
  }

  return (
  
    <Offcanvas className="details-offcanvas" style={{width:"35%"}} show={show} onHide={handleClose} placement="end">
      <div className="audit">
      <Offcanvas.Header className="details-offcanvas-header"closeButton>
        <Offcanvas.Title className="w-100 details-offcanvas-header-title text-color-black"><div>Event Details</div></Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="details-offcanvas-body">
        <Container className="details-container scrollbar">
          <Row className="mb-3 ">
            <Col className="fs-14 l-h-24 text-color fw-500" sm={{ span: 12 }}> Tenant Name:  <span className="tenant-details-name">{' '}{siteName}</span></Col></Row>
          <Row className="mb-4">
            <Col size="12">
              {additionalData()}
            </Col>
          </Row>
          {getStateChangeDetails()}
          <div className="fs-14 l-h-24" >
            <div className="comment-tag">Comments</div>
            <hr />
            <div className="comment"> {details.eventText}</div>
            <br />
            {((details.comment!== undefined && details.comment!=="") || details.attachment!==undefined) &&<div><div className="comment-tag">Reason</div>
            <hr />
            <div className="comment"> {details?.comment}</div>
            {displayAttachmentDetails()}</div>}
          </div>
        </Container>
      </Offcanvas.Body>
      </div>
    </Offcanvas>


  )
}
export default AuditDetails;
