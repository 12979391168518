export const tableau_new_workbook_code = 'newWorkbook';

const activity = {
    USERACTIVITY: 'User Activity',
    SYSTEM_MESSAGE: 'System Message',
    SIGNAL_FINDER: 'Signal Finder'
};

const module = {
    USER: 'User',
    DASHBOARD: 'Dashboard',
    NOTIFICATION: 'Notification',
    FAILURE: 'Failure',
    SIGNAL_FINDER: 'Signal Finder'
};

const eventType = 'AUDIT';

export const auditEvents = {
    signal: {
        create: {
            userAction: 'Create',
            eventGroupingId: activity.USERACTIVITY,
            eventName: module.SIGNAL_FINDER,
            eventType: eventType
        },
        shared: {
            userAction: 'Sharing',
            eventGroupingId: activity.USERACTIVITY,
            eventName: module.SIGNAL_FINDER,
            eventType: eventType
        },
        update: {
            userAction: 'Update',
            eventGroupingId: activity.USERACTIVITY,
            eventName: module.SIGNAL_FINDER,
            eventType: eventType
        },
        view: {
            userAction: 'View',
            eventGroupingId: activity.USERACTIVITY,
            eventName: module.SIGNAL_FINDER,
            eventType: eventType
        }
    },
    dashboard: {
        download: {
            userAction: 'Download',
            eventGroupingId: activity.USERACTIVITY,
            eventName: module.DASHBOARD,
            eventType: eventType
        },
        edit: {
            userAction: 'Update',
            eventGroupingId: activity.USERACTIVITY,
            eventName: module.DASHBOARD,
            eventType: eventType
        },
        view: {
            userAction: 'View',
            eventGroupingId: activity.USERACTIVITY,
            eventName: module.DASHBOARD,
            eventType: eventType
        },
        create: {
            userAction: 'Create',
            eventGroupingId: activity.USERACTIVITY,
            eventName: module.DASHBOARD,
            eventType: eventType
        },
        pinned: {
            userAction: 'Pin Favorite',
            eventGroupingId: activity.USERACTIVITY,
            eventName: module.DASHBOARD,
            eventType: eventType
        },
        shared: {
            userAction: 'Sharing',
            eventGroupingId: activity.USERACTIVITY,
            eventName: module.DASHBOARD,
            eventType: eventType
        },
        delete: {
            userAction: 'Delete',
            eventGroupingId: activity.USERACTIVITY,
            eventName: module.DASHBOARD,
            eventType: eventType
        }
    },
    user: {
        login: {
            userAction: 'Login',
            eventGroupingId: activity.USERACTIVITY,
            eventName: module.USER,
            eventType: eventType,
            comment: 'User logged in'
        },
        logout: {
            userAction: 'Logout',
            eventGroupingId: activity.USERACTIVITY,
            eventName: module.USER,
            eventType: eventType
        },
        profileSwitch: {
            userAction: 'Switch Profile',
            eventGroupingId: activity.USERACTIVITY,
            eventName: module.USER,
            eventType: eventType
        }
    },
    notification: {
        timeout: {
            userAction: 'Timeout Message',
            eventGroupingId: activity.SYSTEM_MESSAGE,
            eventName: module.NOTIFICATION,
            eventType: eventType
        },
        remainLoggedIn: {
            userAction: 'Remain Logged In',
            eventGroupingId: activity.SYSTEM_MESSAGE,
            eventName: module.NOTIFICATION,
            eventType: eventType
        }
    },
    failure:{
        apiFailure:{
            userAction: 'System Failure',
            eventGroupingId: activity.SYSTEM_MESSAGE,
            eventName: module.FAILURE,
            eventType: eventType
        }
    }
};
