import React, { useEffect, useState, useCallback } from 'react';
import i18nLabels from '../../../services/i18n.service';
import { TypeFilterOption } from '../../../types/types';
import Button from '../../common/ui/button/Button';

import Form from 'react-bootstrap/Form';

type Props = {
    filterIndex: number;
    displayName: string;
    options: Array<TypeFilterOption>;
    appliedFilterOptions: Array<TypeFilterOption>;
    updateFilters: (num1: number, num2: number, bol: boolean) => void;
    applySelectedFilter: (num: number) => void;
    resetSelection: (num1: number) => void;
    clearOptions: (num1: number) => void;
};
function VizFilters({
    filterIndex,
    displayName,
    options,
    updateFilters,
    resetSelection,
    applySelectedFilter,
    clearOptions,
    appliedFilterOptions
}: Props) {
    const [toggleFilter, setToggleFilter] = useState(false);
    const [selectedCount, setSelectedCount] = useState(0);
    const [selectedLabel, setSelectedLabel] = useState('');
    const [disableApplyBtn, setDisableApplyBtn] = useState(true);
    const [isPartialSelected, setIsPartialSelected] = useState(true);
    const close = useCallback(() => {
        clearOptions(filterIndex);
        setToggleFilter(false);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (toggleFilter) {
                window.addEventListener('click', close);
            } else {
                window.removeEventListener('click', close);
            }
        }, 0);
    }, [toggleFilter]);

    useEffect(() => {
        let selectedValue = '';
        let result;
        let count = 0;
        appliedFilterOptions.forEach((option) => {
            if (option.value) {
                selectedValue = option.label;
                count++;
            }
        });
        if (count === appliedFilterOptions.length) {
            result = 'All Selected';
        } else if (count === 1) {
            result = selectedValue;
        } else {
            result = `${count} Selected`;
        }

        setSelectedLabel(result);
        setSelectedCount(count);

        const isChangeinOptions = options.some((option, index) => {
            return option.value !== appliedFilterOptions[index].value
        })

        const isAnySelected = options.some((option) => {
            return option.value
        });
        let currentSelectedCount = 0;
        options.forEach((option) => {
            if (option.value) {
                currentSelectedCount++;
            }
        });
        setIsPartialSelected(currentSelectedCount > 0 && currentSelectedCount < options.length)
        setDisableApplyBtn(!isAnySelected || !isChangeinOptions)


    }, [options, appliedFilterOptions]);
    

    const filterSelectionHandler = (optionIndex: number, value: boolean) => {
        updateFilters(filterIndex, optionIndex, value);
    };

    return (
        <div  className={`filter-item ${displayName === "Portfolio" ? "portfolio-width" : ""}`}>
            <div className="filter-header-text" title={displayName}>{displayName}</div>
            <header
                className={`filter-header ${displayName === "Portfolio" ? "portfolio-width" : ""}`}
                onClick={() => {
                    setToggleFilter(!toggleFilter);
                }}>
                {selectedCount === 0 ? (
                    <span className="filter-placeholder">{i18nLabels.global_drop_down_select_option}</span>
                ) : (
                    <div className="selected-count">
                        <span className="option-selected-count">{selectedLabel}</span>
                        <span
                            className="option-unselect-btn"
                            onClick={(event) => {
                                event.stopPropagation();
                                resetSelection(filterIndex);
                            }}>
                            X
                        </span>
                    </div>
                )}
                <i className="bi bi-caret-down-fill drop-icon"></i>
            </header>
            {toggleFilter ? (
                <div className={`filter-options-container ${displayName === "Portfolio" ? "portfolio-width" : ""}`}>
                    <ul>
                        <Form>
                            {options.map((option: TypeFilterOption, index: number) => {
                                return (
                                    <li
                                        className={`filter-list-item ${
                                            option.value ? 'selected' : ''
                                        } ${
                                            (!index && isPartialSelected) ? 'partial-selected' : ''
                                        }`}
                                        title={option.label}
                                        key={option.label}
                                        onClick={(e) => e.stopPropagation()}>
                                        <Form.Group
                                            className="check-box-holder"
                                            controlId={option.label}
                                            >
                                            <Form.Check
                                                checked={option.value}
                                                type="checkbox"
                                                title={option.label}
                                                label={option.label}
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    filterSelectionHandler(
                                                        index,
                                                        e.currentTarget.checked
                                                    );
                                                }}
                                            />
                                        </Form.Group>
                                    </li>
                                );
                            })}
                        </Form>
                    </ul>
                    <div className="filter-btns">
                        <Button
                            type="secondry"
                            onClick={() => {
                                clearOptions(filterIndex);
                            }}>
                            {i18nLabels.global_btn_cancel}
                        </Button>
                        <Button
                            disabled={disableApplyBtn}
                            onClick={() => {
                                applySelectedFilter(filterIndex);
                            }}>
                            {i18nLabels.global_btn_apply}
                        </Button>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
}

export default VizFilters;
