import React,{useCallback, useEffect, useMemo, useState,useRef} from "react";
import {AgGridReact} from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "./Audit.scss";
import { AuditDataGrid, AuditDetails } from "audit-component-library";
import { AuditDetail,AuditData as Data } from "../types/auditTypes";
import { downloadExcel, downloadLogs, getAuditDetails, getPresignedUrl } from "../../../../services/api.service";
import { OverlayTrigger ,Tooltip} from "react-bootstrap";
import { useDispatch, useSelector} from 'react-redux';
import { RootState } from '../../../../store/store';
import allActions from "../../../../store/actions";
import Pagination from 'react-bootstrap/Pagination';
import moment from 'moment';
import DateFilter from "./DateFilter";
import * as uuid from 'uuid';



type Props = {
    
    filterList:Array<string>,
    auditData:Array<Data>
}

const AuditData = (props:Props) => {
    const gridApiRef = useRef<any>(null);
    const {filterList,auditData}:Props = props;
    const gridStyle = useMemo(() => ({height:'93%',width:'100%' }), []);
    const [auditDataForDisplay,setAuditDataForDisplay] = useState<Array<Data>>([]);
    const [details,setDetails] = useState<AuditDetail>();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(0);
    const [totalDisplayedRows, setTotalDisplayedRows] = useState<number>(0)
    const [noOfFiltersApplied, setNoOfFiltersApplied] = useState<number>(0);
  
    const [refreshGrid,setRefreshGrid] = useState<boolean>(false);
    const [clearDownloadInterval,setClearDownloadInterval] = useState<boolean>(false);
    const [tenantId, tenantIds, siteName, downloadType] = useSelector((state: RootState) => {
        return [state.audit.tenantId, state.audit.tenantIds, state.user.siteName, state.audit.downloadType];
    });
    
    const [currentProfile, currentRole] = useSelector((state: RootState) => {
        return [state.audit.currentProfile, state.audit.currentRole];
    })
    const [eventsFrom, eventsTo,downloadAsyncId] = useSelector((state: RootState) => {
        return [state.audit.eventsFrom, state.audit.eventsTo,
             state.audit.downloadAsyncId];
    })
    const [totalPages,setTotalPages] = useState<number>(0);

    // useEffect(() => {
    //     window.addEventListener('error', (e) => {
    //       if (e.message.toString().includes('ResizeObserver') || e.message === 'Script error.' ) {
    //         const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div')
    //         const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
    //         if (resizeObserverErr) {
    //           resizeObserverErr.setAttribute('style', 'display: none')
    //         }
    //         if (resizeObserverErrDiv) {
    //           resizeObserverErrDiv.setAttribute('style', 'display: none')
    //         }
    //       }
    //     })
    //   }, [])
    const getWidth = () => {
        let width = 400;
        if(window.innerWidth < 700) {
           width=110;
        }else if(window.innerWidth < 1000) {
            width=180;
        } 
        else if(window.innerWidth < 1300) {
        width=220;
       } else if(window.innerWidth < 1600) {
        width=320;
       } else if(window.innerWidth < 1900) {
        width=380;
       }
       return width;
}


    useEffect(() => {
        const asyncId = uuid.v4();
        const payload = {
            eventQuery:{product:'Argus Performance Insights'},
            asyncId,
            downloadType: "Audit",
            tenantId
        }
        dispatch(allActions.auditAction.disableDownload(true));
        if (downloadType === "All") {
            if (filterList.length > 0) {
                let eventGroupingId:Array<string> = [];
                let eventName: Array<string> = [];
                let userAction: Array<string> = [];
                filterList.forEach(filter => {
                    const filterSplit = filter.toString().split("--");
                    eventGroupingId.push(capitalizeString(filterSplit[0]));
                    eventName.push(capitalizeString(filterSplit[1]));
                    userAction.push(capitalizeString(filterSplit[2]));
                })
                eventGroupingId = [...new Set(eventGroupingId)];
                eventName = [...new Set(eventName)];
                userAction = [...new Set(userAction)];
                payload.eventQuery["eventGroupingId"] = eventGroupingId;
                payload.eventQuery["eventName"] = eventName;
                payload.eventQuery["userAction"] = userAction;
            }
            payload.eventQuery["eventFromTime"] = eventsFrom;
            payload.eventQuery["eventEndTime"] = eventsTo;
            payload.eventQuery["eventType"] = "AUDIT";
         
            payload.eventQuery["role"] = currentRole;
            payload.eventQuery["profile"] = currentProfile;
            payload.eventQuery["tenantId"] = tenantIds.get(siteName);
            dispatch(allActions.auditAction.addDownloadType(""));
            dispatch(allActions.auditAction.addDownloadAsyncId(asyncId));
            dispatch(allActions.loaderAction.showLoader());
            downloadLogs(payload).then((response) => {
                dispatch(allActions.auditAction.addPresignedUrl(response))
            }).catch(() =>  "")
        } else if (downloadType === "Current") {
            const skList: Array<string> = [];
                const rowsNode:Array<any> = [];
                const startIndex:number = rowsPerPage * currentPage;
                const endIndex:number = startIndex + rowsPerPage > totalDisplayedRows? totalDisplayedRows: startIndex+rowsPerPage;
             gridApiRef?.current?.forEachNodeAfterFilterAndSort((node) => {
               
                rowsNode.push(node.data);
            })
            let i=0;
            for(i=startIndex;i<=endIndex-1;i++){        
                        skList.push(window.btoa(rowsNode[i].sk));
                
            }

            payload.eventQuery["skList"] = skList;
            payload.eventQuery["tenantId"] = tenantIds.get(siteName);
            payload.eventQuery["eventType"] = "AUDIT";
            payload.eventQuery["eventFromTime"] = eventsFrom;
            payload.eventQuery["eventEndTime"] = eventsTo;
            payload.eventQuery["role"] = currentRole;
            payload.eventQuery["profile"] = currentProfile;
        
            dispatch(allActions.auditAction.addDownloadType(""))
            dispatch(allActions.auditAction.addDownloadAsyncId(asyncId));
         
            downloadLogs(payload).then((response) => {
                dispatch(allActions.auditAction.addPresignedUrl(response))
            })        }
   
    }, [downloadType])

    useEffect(() => {
       downloadFile();
    },[downloadAsyncId])

    const downloadFile = () => {
        if(downloadAsyncId.length > 0) {
            dispatch(allActions.loaderAction.showLoader());
         const interval =   setInterval(()=> {
            
               const payload={
                asyncId:downloadAsyncId
               }
            
               getPresignedUrl(payload).then((response) => {
        
                if(response.status === "C" && response.presignedUrl !== ""){
                        dispatch(allActions.auditAction.addPresignedUrl(response.presignedUrl));
                    
                        downloadExcel(response.presignedUrl);  
                        setClearDownloadInterval(true);
                        dispatch(allActions.loaderAction.hideLoader());
                        dispatch(allActions.auditAction.addDownloadAsyncId(""));
                        clearDownloadInt(interval);
                        dispatch(allActions.auditAction.disableDownload(false));
                    

                }else if(response.status === "F") {
                     clearDownloadInt(interval);
                     dispatch(allActions.auditAction.disableDownload(false));
                     dispatch(allActions.loaderAction.hideLoader());
                     dispatch(allActions.alertAction.showAlert({type:"danger",message:"Download failed"}));
                }
               }).finally(()=> gridApiRef.current.sizeColumnsToFit())
           },8000)
        }
    }
    const clearDownloadInt= (interval) => {
            clearInterval(interval);
    }
    useEffect(() => {
        if(clearDownloadInterval)
        clearInterval("interval");
    },[clearDownloadInterval])

    const capitalizeString = (text: string) => {
        const words = text.split(" ");
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substring(1);
        }
        return words.join(' ');
    }

    useEffect(() => {
        if(auditDataForDisplay.length ==0) {
            dispatch(allActions.auditAction.disableDownload(true));
        }else {
        dispatch(allActions.auditAction.disableDownload(false));
        }
     
    },[auditDataForDisplay]);


    const onFilterChange = (filterModel) => {
        if (filterModel.dateFrom == null) {
                setRefreshGrid(prev => !prev);
            return ;
        }
        const filteredData = auditDataForDisplay.filter((row: Data) => {
           
            let firstDate = new Date(row.eventTime);
            firstDate =new Date(firstDate.getUTCFullYear(),firstDate.getUTCMonth(),firstDate.getUTCDate(),firstDate.getUTCHours(),
            firstDate.getUTCMinutes(),firstDate.getUTCSeconds());
            const cellValue = firstDate.toLocaleDateString(window.navigator.language);
            moment.locale(window.navigator.language);
            const format = moment.localeData().longDateFormat("L");

            const date = moment(cellValue, format).toDate();
            date.setHours(0, 0, 0, 0);

            if (filterModel.filterType === "equals") {
                if (date > filterModel.dateFrom) {
                    return false;
                } else if (date < filterModel.dateFrom) return false;
                else return true;
            }
            if (filterModel.filterType === "lessThan") {
                return date < filterModel.dateFrom;
            }
            if (filterModel.filterType === "greaterThan") {
                return date > filterModel.dateFrom;
            }
            if (filterModel.filterType === "inRange") {
        
                return date >= filterModel.dateFrom && date <= filterModel.dateTo;
            }
            return true; // No filter applied
        });

        const returnData = filteredData.map((row: Data) => row);

        setAuditDataForDisplay(returnData);
      
        gridApiRef.current.setRowData(returnData);
    };
  
    /** 
    * Column definatins for the grid
    */
    const columnDefs = [
        {
            field: 'eventTime', headerName: 'Timestamp',suppressMovable:true, resizable: true, minWidth: 180, maxWidth: 190, sortable: true,
          
            suppressSizeToFit:true,
            valueGetter: (p) => {
               
                const date = new Date(p.data.eventTime);
                const cellDate = new Date(date.getUTCFullYear(),date.getUTCMonth(),date.getUTCDate(),date.getUTCHours(),date.getUTCMinutes(),date.getUTCSeconds());

                return (
                    cellDate.toLocaleDateString(window.navigator.language) +
                    " " +
                    cellDate.toLocaleTimeString(window.navigator.language)
                );
            },
            filter: DateFilter,
            filterParams: {
                onFilterChanged: onFilterChange,
            },
            cellStyle:{'backgroundColor':'#E5F2F6','border':'#E5F2F6'}
        },
        {
            field: 'eventText',tooltipComponent: TooltipComponent,tooltipField:"eventText", headerName: 'Change Summary',suppressMovable:true, suppressSizeToFit: true, width: getWidth(), cellClass: 'change-summary', resizable: true, sortable: true,
            cellStyle:{'backgroundColor':'#E5F2F6','border':'#E5F2F6'}
        },
        { field: 'userAction', headerName: 'Action', resizable: true,suppressMovable:true,minWidth:140, sortable: true, 
        cellStyle:{'backgroundColor':'#E5F2F6','border':'#E5F2F6'},
        filter: 'agTextColumnFilter' },
        { field: 'eventGroupingId', headerName: 'Category', suppressMovable:true,resizable: true, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'userType', headerName: 'Type', suppressMovable:true,resizable: true, sortable: true, filter: 'agTextColumnFilter', 
        valueGetter: (params: any) => {

            if(  params.data.userType === "" || params.data.userType === undefined)
            return "";
            else 
            return capitalizeString(params.data.userType);
        }},
        { field: 'eventName', headerName: 'Module', resizable: true,suppressMovable:true, sortable: true, filter: 'agTextColumnFilter' },
       
        { field: 'principalName', headerName: 'User',suppressMovable:true, resizable: true, filter: 'agTextColumnFilter', sortable: true,
        valueGetter: (params: any) => {

            if(  params.data.principalName === "" || params.data.principalName === undefined){
          
               if(params.data.principalId.length > 0) {
                let name = params.data.principalId;
                if(name.includes("@"))
                name = name.substring(0,name.indexOf("@")).split(".").join(" ");
    
                return capitalizeString(name);
    
               }
              }
            else 
            return params.data.principalName
          } },
        {
            field: 'status', headerName: 'Status',suppressMovable:true, resizable: true, filter: 'agTextColumnFilter', sortable: true,
            cellRenderer: (params: any) => {
                if (params.data.status && params.data.status.toString().toUpperCase() === "SUCCESS") {
                    return <div>
                        <span style={{
                            height: '10px',
                            width: '10px',
                            backgroundColor: '#12AD2B',
                            borderRadius: '50%',
                            display: 'inline-block',
                            marginRight: '7px',
                        }}></span>
                        <span>Success</span>
                    </div>
                } else {
                    return <div>
                        <span style={{
                            height: '10px',
                            width: '10px',
                            backgroundColor: 'red',
                            borderRadius: '50%',
                            display: 'inline-block',
                            marginRight: '7px',
                        }
                        }></span>
                        <span>Failure</span>
                    </div>
                }
            }
        },
        
        {
            field: 'principalName', headerName: 'Details', suppressMovable:true,resizable: true, suppressSizeToFit: true, width: 80,
            cellRenderer: (params: any) => {
                return <div>
                    <span id={params.data.sk} onClick={() => detailsClickHandler(params)
                    } className="material-symbols-outlined" style={{  marginLeft: '10%', marginTop: '6px', fontSize: '18px' }}>info</span></div>

            }
        }
    ];

    
   const detailsClickHandler = (params:any) => {    
    const payload = {    
        skList:[params.data.sk]
    }
    if(tenantIds.has(siteName)){
        
          payload["tenantId"]= tenantIds.get(siteName);
        }else if(tenantId){
        
          payload["tenantId"]=tenantId;
        }
         dispatch(allActions.loaderAction.showLoader());
        getAuditDetails(payload).then((response:AuditDetail)=>{        
        setDetails(response);
        }).catch(error =>  dispatch(allActions.alertAction.showAlert({type:"danger",message: error}))).finally(() => dispatch(allActions.loaderAction.hideLoader()))
       
    }
       

     
      useEffect(()=>{
        if (gridApiRef.current) {
          gridApiRef.current.sizeColumnsToFit();
        }
      },[auditDataForDisplay,details,currentPage,rowsPerPage])
   


    useEffect(() => {
            if(filterList && filterList.length>0){
             const filteredData = auditData.filter((el:Data) => {
                const filter=el.eventGroupingId+"--"+el.eventName+"--"+el.userAction;
                if(filterList.includes(filter.toLowerCase())){
                    return true;
                }else {
                    return false;
                }
            })
        
            if(filteredData !== undefined) {
                filteredData.sort((a,b) => {
                    const timeA:Date = new Date(a.eventTime);
                   const timeB:Date = new Date(b.eventTime);
                     return timeB.getTime() - timeA.getTime();
               })
            }
                setAuditDataForDisplay(filteredData);

        } else {
            if(auditData !== undefined) {
            auditData.sort((a,b) => {
                const timeA:Date = new Date(a.eventTime);
               const timeB:Date = new Date(b.eventTime);
                 return timeB.getTime() - timeA.getTime();
           })
        }
                setAuditDataForDisplay(auditData);
            }
       

    }
        , [filterList, auditData,refreshGrid])



    const onGridReady = (event) => {
        gridApiRef.current = event.api;
        setRowsPerPage(gridApiRef.current.paginationGetPageSize());
        setTotalPages(gridApiRef.current.paginationGetTotalPages());
        setCurrentPage(0);
    
    }
    useEffect(() => {
        if(gridApiRef.current !== null){
        setRowsPerPage(gridApiRef.current.paginationGetPageSize());
        setTotalPages(gridApiRef.current.paginationGetTotalPages());
        setCurrentPage(0);
        gridApiRef.current?.paginationGoToPage(0);
        }
    },[auditDataForDisplay]);
     useEffect(() => {
       if(gridApiRef.current) {
        gridApiRef.current.deselectAll();
           }
     },[currentPage])
      const onPaginationChanged = useCallback(() => {

        if (gridApiRef.current) {      
             setTotalDisplayedRows(gridApiRef.current.getDisplayedRowCount());
        setTotalPages(gridApiRef.current.paginationGetTotalPages());
        setRowsPerPage(gridApiRef.current.paginationGetPageSize());
        }
      }, []); 
      const createPaginationComponent = () => {
        let i=0;  
        const items:React.JSX.Element[]=[];
        let startPage=1;
        let endPage= totalPages>4 ? 4:totalPages;
       
        if(currentPage>=3) {
           
            startPage= currentPage-1;
        }
        if(currentPage >= 3) {
            endPage = currentPage+2<=totalPages? currentPage+2: totalPages;     

        }
        for(i=startPage;i<=endPage;i++) {
        items.push(createPageItem(i));
           
      } return items;
    }

    function createPageItem(page) {
        return <Pagination.Item key={page} className={`page-item ${page-1 == currentPage ? 'page-active' : 'page-inactive'}` } active={page - 1 == currentPage} id={page} onClick={() => onPageChangeHandler(page - 1)}><div className="page-item-number">{page}</div></Pagination.Item>

    }
    const onPageChangeHandler = (page) => {
        setCurrentPage(page);
        gridApiRef.current?.paginationGoToPage(page);
    }
    const prevPaginationHandler = () => {
        gridApiRef.current?.paginationGoToPage(currentPage - 1);
        setCurrentPage(currentPage - 1);
    }
    const nextPaginationHandler = () => {
        gridApiRef.current?.paginationGoToPage(currentPage + 1);
        setCurrentPage(currentPage + 1);
    }

    const getRowInfo = () => {
       
        if (currentPage === 0 && rowsPerPage > totalDisplayedRows) {
            return <span>{1} - {totalDisplayedRows} of {totalDisplayedRows} items</span>
        }
        return <span>{currentPage === 0 ? 1 : ((currentPage) * rowsPerPage) + 1} - {(currentPage + 1) * rowsPerPage > totalDisplayedRows ? totalDisplayedRows : (currentPage + 1) * rowsPerPage} of {totalDisplayedRows} items</span>
    }
    useEffect(() => {
               setNoOfFiltersApplied(filterList.length);
    },[filterList]);

    useEffect(() => {
        setCurrentPage(0);
        gridApiRef.current?.paginationGoToPage(0);
    }, [noOfFiltersApplied, auditData,auditDataForDisplay])
  const onGridFilterChanged = () => {
    if (gridApiRef.current) {
        setTotalDisplayedRows(gridApiRef.current.getDisplayedRowCount());
        setTotalPages(gridApiRef.current.paginationGetTotalPages());
        setRowsPerPage(gridApiRef.current.paginationGetPageSize());
        gridApiRef.current?.paginationGoToPage(0);
        setCurrentPage(0);
    }
  }



    return (
        <>

            {details &&
                <AuditDetails details={details} activeTenantName={siteName} />}
            <div style={gridStyle} className="ag-theme-alpine audit">
            
            <AuditDataGrid auditDataForDisplay={auditDataForDisplay} 
    detailsClickHandler={detailsClickHandler}
    gridApiRef={gridApiRef}
    columnDefs={columnDefs}
    filterList={filterList}
    tenantName={siteName}
    setParentCurPage={setCurrentPage}
    setParentRowsPerPage={setRowsPerPage}
    setParentTotalDisplayedRows={setTotalDisplayedRows}/>
            </div>

        </>
    );
}
export default AuditData;


const TooltipComponent = (props:any) => {
    const data = useMemo(
      () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
      []
    );
 
    return (
      <div
        className="audit-custom-tooltip"
      >
        <p>
          <span>{data.eventText}</span>
        </p>
       
      </div>
    );
  };
