import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18nLabels from '../../../services/i18n.service';
import { app_config } from '../../../services/constant';
import moment from 'moment';
import { calculateDateStatus } from '../../../services/utils';

type Props = {
    cssClass?: string;
};

function Footer({ cssClass }: Props) {
    const [lastTimeStamp, setLastTimeStamp] = useState("");
    const lastLoginTimestamp = localStorage.getItem('last-login-timestamp');
    
    useEffect(() => {
        if (lastLoginTimestamp) {
            setLastTimeStamp(lastLoginTimestamp);
        }
    }, [lastLoginTimestamp])
    const currentYear = moment().format('YYYY');
    const copyrightText = `${i18nLabels.footer_copyright_1} ${currentYear} ${i18nLabels.footer_copyright_2}`;
    
    useEffect(() => {
        if (lastLoginTimestamp) {
            setLastTimeStamp(lastLoginTimestamp);
        }
    }, [lastLoginTimestamp])
    
    return (
        <>
            <Container className={`footer-wrapper gx-0 ${cssClass}`}>
                <Row className="fs-12 footer  p-2 mt-4">
                    <Col sm={8} className="footer-text">
                    {copyrightText}
                    </Col>
                    <Col sm={4} className="align-right policy">
                        <span className="login-header">{lastTimeStamp ? 'Last Login: ': ''}</span>
                        <span className="login-time">{lastTimeStamp ? calculateDateStatus(lastTimeStamp) : ''}</span>
                        <a
                            href={app_config.PRIVACY_POLICY_URL}
                            target="_blank"
                            rel="noopener noreferrer">
                            {i18nLabels.footer_label_privacy_policy}
                        </a>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Footer;
