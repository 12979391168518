import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Dropdown from 'react-bootstrap/Dropdown';
import Argus_Logo from '../../../assets/images/logo.svg';
import UserInfoLogo from '../../../assets/images/user-info-icon.png';
import { getNavigationLinkForAudit, getNavigationLinks } from '../../../services/navigation.service';
import { TypesNavigationLink } from '../../../types/types';
import SwitchProfile from '../switchProfile/SwitchProfile';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import { auditRoles } from '../../../services/constant';
import SystemNotification from '../systemNotification/SystemNotification';
import utils from '../../../services/utils';
import i18nLabels from '../../../services/i18n.service';
import { downloadDashboardSignalData, getLastLoginData, updateLastLoginData } from '../../../services/api.service';
import allActions from '../../../store/actions';
import { getAlertDetails } from '../../../services/utils';
import * as ExcelJS from 'exceljs';
import {saveAs} from 'file-saver';
import moment from 'moment';

type Props = {
    signOut?: () => void;
    cssClass?: { hover: string, up: string };
};

function Header({ signOut, cssClass }: Props) {
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [hideHeader, setHideHeader] = useState(true);
    const [showSwitchProfile, setShowSwitchProfile] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [showProfilePopup, setShowProfilePopup] = useState(false);
    const [activeMenu, setActiveMenu] = useState('');
    const [navigationActiveLinks, setNavigationActiveLinks] = useState<Array<TypesNavigationLink>>([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userState = useSelector((state: RootState) => {
        return state.userProfile;
    });


    const [siteName, profileName, isSignalsEnabled] = useSelector((state: RootState) => {
        return [state.user.siteName, state.user.profileName, state.user.isSignalsEnabled];
    });
    const [currentRole] = useSelector((state: RootState) => {
        return [state.audit.currentRole];
    })
    const handleProfile = () => {
        setShowSwitchProfile(true);
        if (open) {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (location.pathname.indexOf('dashboard') > -1) {
            setActiveMenu('dashboard');
        }
        if (location.pathname.indexOf('signals') > -1) {
            setActiveMenu('signals');
        }
        if (location.pathname === '/') {
            setActiveMenu('home');
        }
        if (location.pathname.indexOf('userinfo') > -1) {
            setActiveMenu('User Info');
        }

        setTimeout(() => {
            setHideHeader(false);
        }, 700);
    }, [location])


    function getLastLoginDetail() {
        if (!localStorage.getItem('last-login-timestamp') ) {
            dispatch(allActions.loaderAction.showLoader());
            getLastLoginData().then((response) => {
                localStorage.setItem('last-login-timestamp', response?.lastLoginTime);
            }
            ).then(() => {
                updateLastLoginData().then((res) => {
                    if (res.lastLoginTime) {
                        console.log("time updated");
                    }
                }).catch((error) => console.log(error));
            }).catch(error =>
                allActions.alertAction.showAlert({ type: "danger", message: error }))
            .finally(() => {
                dispatch(allActions.loaderAction.hideLoader())});

        }
    }

    useEffect(() => {
        getLastLoginDetail();
    }, [])

    useEffect(() => {
        const navigationLiks = getNavigationLinks({ signalEnabled: isSignalsEnabled });
        if(process.env.REACT_APP_AUDIT === "true" && auditRoles.includes(currentRole.split("_")[1])){
        getNavigationLinkForAudit(navigationLiks);
        }

        setNavigationActiveLinks(navigationLiks);
    }, [isSignalsEnabled, currentRole])




    const contactUs = () => {
        navigate(`/contactus`);
    };
    const faq = () => {
        navigate(`/faq`);
    };
    const glossary = () => {
        navigate(`/glossary`);
    };

    const tutorialtraining = () => {
        navigate(`/tutorialtraining`);
    }

    const userActivity = () => {
        navigate(`/userinfo/activity`);
        setActiveMenu("User Info");
    };

    const userAccess = () => {
        navigate(`/userinfo/access`);   
        setActiveMenu("User Info");
    };

    const downloadData = () => {
        dispatch(allActions.loaderAction.showLoader());
        downloadDashboardSignalData(siteName, profileName)
                .then((response) => {
                    exportFile(response.data);
                })
                .catch((response) => {
                    dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
                })
                .finally(() => {
                    dispatch(allActions.loaderAction.hideLoader());
                })
    }

    const exportFile = async (data) => {
        const workbookName = 'Dashboard Data_'+ siteName + '_' + profileName + '_' + moment(new Date()).format("YYYYMMDDHHmmss");
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('Dashboard Data');
        const columns = ["Type", "User Name", "Dashboard / Signal Name", "Dashboard Owner" ];
        sheet.addRow(columns).font = {bold: true};
        
        data.forEach((ele) => {
            sheet.addRow([ele.userType, ele.userName, ele.workbookName, ele.ownerName]);
        })
        sheet.getColumn('A').width = 30;
        sheet.getColumn('B').width = 30;
        sheet.getColumn('C').width = 30;
        sheet.getColumn('D').width = 30;
        const blob = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([blob]), workbookName+'.xlsx');
    }

    const { email, given_name, family_name } = userState;

    return (
        <>
            <div className={`${cssClass && cssClass.hover} ${cssClass && cssClass.up} ${hideHeader ? 'hide-header' : ''}`}>
                <div className="landing-header">
                    {activeMenu === 'home' && <SystemNotification></SystemNotification>}

                    <Navbar bg="light" expand="sm">
                        <Container fluid-xxl className="container-xxl">
                            <Navbar.Brand className="mr-1">
                                <Nav.Link href="/">
                                    <img src={Argus_Logo} alt="Argus" className="logocss" />
                                </Nav.Link>
                            </Navbar.Brand>

                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto mt-3 main-nav">
                                    {navigationActiveLinks.map((link: TypesNavigationLink) => {

                                        return (

                                            link.key === "User Info" ?
                                                (
                                                    <div className="">
                                                    <Nav.Link className={`user-info-icon ${activeMenu === link.key ? 'active' : ''}`}>
                                                    
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant="outline"
                                                            className={`fs-14`}
                                                            data-testid="user-info-icon"
                                                            id="dropdown-toggle">
                                                            {
                                                                <span className="material-symbols-outlined input-group-addon">
                                                                   <img src={UserInfoLogo}/>
                                                                </span>
                                                            }{' '}
                                                            {link.label}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <div
                                                                id="example-fade-text .Link-link nav sec"
                                                                className="user-info rounded-3 pt-1 pb-1 position-absolute support-link-wrapper">
                                                                <Dropdown.Item
                                                                    onClick={userActivity}
                                                                    className="support-text">
                                                                    {i18nLabels.user_activity}{' '}
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={userAccess}
                                                                    className="support-text">
                                                                    {i18nLabels.user_access}{' '}
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={downloadData}
                                                                    className="support-text">
                                                                    {i18nLabels.download_dashboard_data}{' '}
                                                                </Dropdown.Item>
                                                            </div>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    </Nav.Link>
                                                    </div>
                                                )
                                                : (
                                                    <Nav.Link
                                                        className={`fs-14 ms-1 me-2 nav-sec ${location.pathname.includes('/dashboard/list')
                                                                ? 'dashboard-header'
                                                                : ''
                                                            } ${activeMenu === link.key ? 'active' : ''}`}
                                                        key={link.label}
                                                        to={link.route}
                                                        as={NavLink}>
                                                        {' '}
                                                        {
                                                            <span className="material-symbols-outlined me-1">
                                                                {link.icon}
                                                            </span>
                                                        }{' '}
                                                        {link.label}
                                                    </Nav.Link>
                                                )
                                        );
                                    }
                                    )}
                                </Nav>
                            </Navbar.Collapse>
                            <div className='header-profile-name'>{`${utils.maskBankName(siteName)}, ${profileName}`}</div>
                            <div className="">
                                <Nav className="user-profile-icon">
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            variant="link outline-info"
                                            className="pt-1 pe-0"
                                            id="dropdown-basic">
                                            <span className="material-symbols-outlined fs-20 ">
                                                question_mark
                                            </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div
                                                id="example-fade-text"
                                                className="user-profile rounded-3 pt-4 pb-4 position-absolute support-link-wrapper">
                                                <Dropdown.Item
                                                    onClick={contactUs}
                                                    className="support-text">
                                                    {i18nLabels.contact_info}{' '}
                                                    <span className="material-icons-outlined support-icons">
                                                        contact_page
                                                    </span>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={faq}
                                                    className="support-text">
                                                    {i18nLabels.faq}{' '}
                                                    <span className="material-icons-outlined support-icons">
                                                        featured_play_list
                                                    </span>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={tutorialtraining}
                                                    className="support-text">
                                                    {i18nLabels.tutorial_and_training}{' '}
                                                    <span className="material-icons-outlined support-icons">
                                                        local_library
                                                    </span>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={glossary}
                                                    className="support-text">
                                                    {i18nLabels.glossary}{' '}
                                                    <span className="material-icons-outlined support-icons">
                                                        dashboard
                                                    </span>
                                                </Dropdown.Item>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown
                                        show={showProfilePopup}
                                        onToggle={(isOpen) => setShowProfilePopup(isOpen)}>
                                        <Dropdown.Toggle
                                            variant="link outline-info"
                                            className="pt-1 padding-right-0"
                                            data-testid="user-profile-icon"
                                            id="drogpdown-basic">
                                            <span className="material-symbols-outlined fs-21 pt-1 pe-0 ps-0 padding-right-0">
                                                person_outline
                                            </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu
                                            align={{ lg: 'start' }}
                                            className="square rounded-0">
                                            <div
                                                id="example-fade-text"
                                                className="user-profile rounded-3 pt-1 pb-4 position-absolute">
                                                <div className="row">
                                                    <div className="profile-close-btn">
                                                        <span
                                                            className="material-symbols-outlined"
                                                            onClick={() => {
                                                                setShowProfilePopup(false);
                                                            }}>
                                                            close
                                                        </span>
                                                    </div>
                                                    <div className="col-md-3 pe-4">
                                                        <span className="profile-icon p-2  ms-3  material-symbols-outlined rounded-circle">
                                                            person_outline
                                                        </span>
                                                    </div>
                                                    <div className="col-md-9 ps-0">
                                                        <h4 className="primary-color mb-1 person-outline-title">
                                                            {given_name} {family_name}
                                                        </h4>
                                                        <p
                                                            title={email}
                                                            className="fs-12 mb-2 grey-color person-email">
                                                            {email}
                                                        </p>
                                                        <p className="fs-12">
                                                            <span className="fs-12 signout">
                                                                {' '}
                                                                <a
                                                                    href="#"
                                                                    onClick={(event) => {
                                                                        signOut && signOut();
                                                                        localStorage.getItem('last-login-timestamp')&& localStorage.removeItem('last-login-timestamp');
                                                                        event.stopPropagation();
                                                                    }}>
                                                                    {i18nLabels.header_signout}
                                                                </a>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>

                                                <hr className="mt-2" />
                                                <div className="row">
                                                    <h4 className="ps-4 grey-color current-profile-title">
                                                        {i18nLabels.header_current_profile}
                                                    </h4>
                                                    <div className="col-md-8 ps-4">
                                                        <p className="fs-14 mb-0 primary-color bank-name">
                                                            {utils.maskBankName(siteName)}
                                                        </p>
                                                        <span className="fs-12  grey-color profile-name">
                                                            {profileName}
                                                        </span>
                                                    </div>
                                                    <div className="col-md-4 ps-0">
                                                        {' '}
                                                        <button
                                                            className="fs-12 switch-profile-btn"
                                                            disabled={disableBtn}
                                                            onClick={() => {
                                                                handleProfile();
                                                            }}>
                                                            {' '}
                                                            {i18nLabels.header_switch_profile}{' '}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Nav>
                            </div>
                        </Container>
                    </Navbar>
                </div>
            </div>

            <SwitchProfile
                setShowSwitchProfile={setShowSwitchProfile}
                showSwitchProfile={showSwitchProfile}
                setDisableBtn={setDisableBtn}
            />
        </>
    );
}

export default Header;
