import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../../../store/store';
import './dashboard.scss';
import { getDataSources } from '../../../services/api.service';
import { useNavigate } from 'react-router-dom';
import { TypeCreateDataset } from '../../../types/types';
import Button from '../../common/ui/button/Button';
import allActions from '../../../store/actions';
import { getAlertDetails } from '../../../services/utils';
import i18nLabels from '../../../services/i18n.service';

function DashboardDatasetSelection() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [datasets, setDatasets] = useState<Array<TypeCreateDataset>>([]);
    const [selectedDataset, setSelectedDataset] = useState<TypeCreateDataset | null>(null);
    const [isDataSourceLoading, setIsDataSourceLoading] = useState(true);

    const { siteName, profileName } = useSelector((state: RootState) => {
        return state.user;
    });

    useEffect(() => {
        const payload = [
            {
                siteName: siteName,
                profiles: [
                    {
                        name: profileName
                    }
                ]
            }
        ];

        if (siteName && profileName) {
            dispatch(allActions.loaderAction.showLoader());
            getDataSources(siteName, profileName, payload)
                .then((response) => {
                    if(response.tenantDataSets.length) {
                        setSelectedDataset(response.tenantDataSets[0]?.profiles[0]?.dataSets[0]);
                        setDatasets(response.tenantDataSets[0]?.profiles[0]?.dataSets);
                    }
                })
                .catch((error) => {
                    dispatch(allActions.alertAction.showAlert(getAlertDetails(error, 'danger')));
                })
                .finally(() => {
                    setIsDataSourceLoading(false)
                    dispatch(allActions.loaderAction.hideLoader());
                });
        }
    }, []);

    const onContinue = () => {
        const template = {
            workbookName: selectedDataset?.contentUrl,
            worksheetId: selectedDataset?.workSheetId,
            workbookId: selectedDataset?.workBookId
        };
        dispatch(allActions.createDashboardAction.setCreateDashboardTemplate(template));
        dispatch(allActions.createDashboardAction.hideCreateDashboardPopup());
        navigate(`/dashboard/new`);
    };

    const onCancel = () => {
        dispatch(allActions.createDashboardAction.hideCreateDashboardPopup());
    };

    return (
        <div className="dataset-selection">
            <div className="datasets-list">
                <ul className="share-selected-users">
                    {datasets.map((dataset) => {
                        return (
                            <li
                                data-testid={dataset.name}
                                onClick={() => {
                                    setSelectedDataset(dataset);
                                }}
                                className={
                                    selectedDataset?.contentUrl === dataset.contentUrl
                                        ? 'datasets-item selected'
                                        : 'datasets-item'
                                }
                                key={dataset.name}>
                                <span className="dataset-col-radio p-2">
                                    <Form.Check
                                        inline
                                        name="group1"
                                        checked={selectedDataset?.name === dataset.name}
                                        type="radio"
                                        id={`inline-'radio'-1`}
                                    />
                                </span>
                                <span>{dataset.name}</span>
                            </li>
                        );
                    })}{' '}
                    {!isDataSourceLoading && !datasets.length ? i18nLabels.dashboard_no_data_source : null}
                </ul>
            </div>
            <div className="dataset-btns">
                <Button type="secondry" onClick={onCancel}>
                    {i18nLabels.global_btn_cancel}
                </Button>
                <Button disabled={!datasets?.length} onClick={onContinue}>{i18nLabels.global_btn_create}</Button>
            </div>
        </div>
    );
}

export default DashboardDatasetSelection;
