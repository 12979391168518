import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from '../../../store/store';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { TypeWorkbook } from '../../../types/types';

type Props = {
    hideShare?: boolean;
    view: Array<TypeWorkbook>;
    reportType: string;
    gotoView: (
        workBookName: string,
        workSheetId: string,
        workBookId: string,
        workbookUrlName: string,
        reportType: string
    ) => void;
    shareDashboard: (workBookId: string, workBookName: string, contentUrl: string) => void;
    deleteDashboard: (workBookId: string, workBookName: string) => void;
};
/**
 * React component for dsiplaying list of thumbnails
 * @param {Array} view - list of dashboard
 * @param {string} gotoView - callback method on click of thumbnail
 * @return {DOM} Returm React DOM
 */
function DashboardThumbnails({
    view,
    gotoView,
    shareDashboard,
    deleteDashboard,
    reportType
}: Props) {
    const [thumbHeightClass , setThumbHeightClass] = useState('');
    const confirmationModelState = useSelector((state: RootState) => {
        return state.confirmationModel;
    });

    useEffect(() => {
        setTimeout(() => {
            setThumbHeightClass('');
        }, 500)
    }, [view])

    useEffect(() => {
        const titleElements = document.getElementsByClassName("workbook-name") || [];
        let isDoubleLine = false;
        for (let i = 0; i < titleElements.length - 1; i++) {
            if (titleElements[i].clientHeight > 25) {
                isDoubleLine = true;
                break;
            }
        }
        setThumbHeightClass(isDoubleLine ? 'double-line' : '');
    })
    return (
        <>
            {view.map((views: TypeWorkbook) => {
                return (
                    <Col key={views.workBookId} className="card-box px-2 mb-2 mt-1">
                        <Card
                            className={`cursor rounded-2 border-0 p-3 pb-3 ${
                                confirmationModelState.workbookId === views.workBookId
                                    ? 'selected'
                                    : null
                            }`}
                            onClick={() =>
                                gotoView(
                                    views.workBookName,
                                    views.workSheetId,
                                    views.workBookId,
                                    views.contentUrl,
                                    reportType
                                )
                            }>
                            <Card.Img
                                variant="top"
                                className="border-0 p-1"
                                src={views.image}
                            />
                            <Card.Body className="p-0 pt-3">
                                <Card.Title title={views.workBookName} className={`fs-14 workbook-name ${thumbHeightClass}`}>{views.workBookName}</Card.Title>
                                <Card.Text className="fs-12 thumb-time-share">
                                    <span>{views.getDays}</span>
                                       
                                        <div className="thumb-btns">
                                        <span className="material-symbols-outlined thumbnail-share" onClick={(event) => {
                                            event.stopPropagation();
                                            shareDashboard(
                                                views.workBookId,
                                                views.workBookName,
                                                views.contentUrl
                                                
                                            );
                                        }}>
                                            share
                                        </span>
                                        {views.allowEditWorkBook && <span className="material-icons thumbnail-share delete-icon"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                deleteDashboard(
                                                    views.workBookId,
                                                    views.workBookName
                                                );
                                            }}>
                                                delete
                                            </span>}
                                        
                                        </div>
                                        
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                );
            })}
        </>
    );
}
export default DashboardThumbnails;
