import React from 'react';
import { TypeWorkbook } from '../../../types/types';
import { globalConstant } from '../../../services/constant';

type Props = {
    view: Array<TypeWorkbook>;
    gotoView: (
        workBookName: string,
        workSheetId: string,
        workBookId: string,
        workbookUrl: string,
        reportType:string
    ) => void;
};

function RecentLeftThumbnails({ view, gotoView}: Props) {
    return (
        <>
            {
                view?.map((views: TypeWorkbook, index: number) => {
                    return (
                        <div
                            key={index}
                            title={views.workBookName}
                            className={`recent-thumbnail`}
                            onClick={() =>
                                gotoView(
                                    views.workBookName,
                                    views.workSheetId,
                                    views.workBookId,
                                    views.contentUrl,
                                    globalConstant.reportType.dashboard
                                )
                            }>
                            <div className="thumbnail-image">
                                <img src={views.image} />
                            </div>
                            <p className="thumb-title">{views.workBookName}</p>
                        </div>
                    );
                })}
        </>
    );
}
export default RecentLeftThumbnails;
