import React from 'react';
import { useSelector } from 'react-redux';
import { TypeFavorite } from '../../../types/types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import Button from '../../common/ui/button/Button';
import {
    setDashboardName,
    setWorkbookId
} from '../../../services/user.service';
import FavoriteDashboard from '../dashboard/FavoriteDashboard';
import { RootState } from '../../../store/store';
import i18nLabels from '../../../services/i18n.service';

interface Props {
    favoriteDashboard: TypeFavorite | null;
}

function FavoriteContainer({ favoriteDashboard }: Props) {
    const navigate = useNavigate();
    const siteName = useSelector((state: RootState) => {
        return state.user.siteName;
    });
    
    const gotoFavoriteView = () => {
        setDashboardName(favoriteDashboard && favoriteDashboard.workBookName);
        setWorkbookId(favoriteDashboard && favoriteDashboard.workBookId);
        const workBookName = favoriteDashboard && favoriteDashboard.contentUrl;
        navigate(
            `/dashboard/view/${siteName}/${favoriteDashboard && workBookName}/${
                favoriteDashboard && favoriteDashboard.workSheetId
            }`
        );
    };
    return (
        <div className="landing-section">
            <Row className="landing-header favorite-header">
                <Col md={9} className="landing-title" title={favoriteDashboard?.workBookName || 'Homepage Dashboard'}>
                    <h4 className='text-ellipsis'>{favoriteDashboard?.workBookName || 'Homepage Dashboard'}</h4>
                </Col>
                {favoriteDashboard && favoriteDashboard.workSheetId && (
                    <Col md={3} className="align-right">
                        <Button onClick={gotoFavoriteView}>
                            {i18nLabels.dashboard_home_btn_go_dashboard}
                        </Button>
                    </Col>
                )}
            </Row>
            <FavoriteDashboard
                workSheetId={favoriteDashboard && favoriteDashboard.workSheetId}
                workBookId={favoriteDashboard && favoriteDashboard.workBookId}
                siteName={siteName}
                workBookName={
                    favoriteDashboard && favoriteDashboard.contentUrl
                }></FavoriteDashboard>
        </div>
    );
}
export default FavoriteContainer;
