// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from 'react-redux';
import allActions from '../../../store/actions';
import { getAlertDetails } from '../../../services/utils';
import type { RootState } from '../../../store/store';
import { getDataSources, checkDatasourceAlligned } from '../../../services/api.service';
import { TypeDatasets, TypeServerPayloadTenant, TypeSearchUser } from '../../../types/types';
import Button from '../../common/ui/button/Button';
import { addSourceWorkbookInProfile, getDefaultDataset, getMaxWorkbookinDataset } from './dashboardShareUtils';
import i18nLabels from '../../../services/i18n.service';
import DatasetAllignedMessage from './DatasetAllignedMessage';
import moment from 'moment';

interface Props {
    tenantId: string;
    profileId: string;
    workbookId: string;
    selectedUserList: TypeSearchUser[];
    selectedTenantList: TypeServerPayloadTenant[];
    onBack: () => void;
    onShareHandler: (multiTenantPayload: any) => void;
}

function DatasetSelection({
    tenantId,
    profileId,
    selectedTenantList,
    workbookId,
    onBack,
    onShareHandler,
    selectedUserList
}: Props) {
    const [datasets, setDatasets] = useState<Array<TypeDatasets>>([]);
    const [showDatasetList, setShowDatasetList] = useState(false);
    const [selectedDataset, setSelectedDataset] = useState({});
    const [draftSelectedDataset, setDraftSelectedDataset] = useState({});
    const [sourcedDataset, setSourcedDataset] = useState('');
    const [isDataSourceLoading, setIsDataSourceLoading] = useState(true);
    const [destinationtenants, setDestinationtenants] = useState('');
    const [publishWorkbookName, setPublishedWorkbook] = useState('');
    const [maxDataset, setMaxDataset] = useState(100);
    const dispatch = useDispatch();
    const [datasourceMatchingStatus, setDatasourceMatchingStatus] = useState({});

    const { contentUrl } = useSelector((state: RootState) => {
        return state.shareDashboard;
    });

    const [siteName, profileName] = useSelector((state: RootState) => {
        return [state.user.siteName, state.user.profileName];
    });

    //Get selected tenant datasets
    useEffect(() => {
        dispatch(allActions.loaderAction.showLoader());
        getDataSources(tenantId, profileId, addSourceWorkbookInProfile(selectedTenantList, contentUrl))
        // getDataSources(tenantId, profileId, selectedTenantList)
            .then((response) => {
                setDestinationtenants(response.tenantDataSets);
                const datasets = response.tenantDataSets[0]?.profiles[0]?.dataSets || [];
                const defaultDataset = getDefaultDataset(datasets);
                setSelectedDataset(defaultDataset);
                setDraftSelectedDataset(defaultDataset);
                setDatasets(response.tenantDataSets[0]?.profiles[0]?.dataSets);
                setMaxDataset(
                    getMaxWorkbookinDataset(response.tenantDataSets[0]?.profiles[0]?.dataSets) ||
                        100
                );
            })
            .catch((error) => {
                dispatch(allActions.alertAction.showAlert(getAlertDetails(error, 'danger')));
            })
            .finally(() => {
                setIsDataSourceLoading(false);
                dispatch(allActions.loaderAction.hideLoader());
            });
    }, []);

    useEffect(() => {
        if (sourcedDataset && destinationtenants && destinationtenants.length) {
            dispatch(allActions.loaderAction.showLoader());
            const payload = {
                publish: {
                    sourceSite: siteName,
                    sourceProfile: profileName,
                    sourceDatasource: sourcedDataset,
                    sourceWorkBookId: workbookId,
                    sourceWorkBookName: contentUrl,
                    destinationSites: [
                        {
                            tenantId: destinationtenants[0].tenantId,
                            siteName: destinationtenants[0].siteName,
                            profiles: [
                                {
                                    id: destinationtenants[0].profiles[0].id,
                                    name: destinationtenants[0].profiles[0].name,
                                    templateWorkbookName: selectedDataset?.workBookName,
                                    templateWorkbookId: selectedDataset?.workBookId,
                                    compareMessage: '',
                                    userList: selectedUserList.map((user) => {
                                        return user.userId;
                                    })
                                }
                            ]
                        }
                    ]
                }
            };
            checkDatasourceAlligned(tenantId, profileId, payload)
                .then((response) => {
                    setDatasourceMatchingStatus(response.publish.destinationSites[0].profiles[0]);
                    setPublishedWorkbook(
                        response.publish.destinationSites[0].profiles[0].workBookName
                    );
                })
                .catch((error) => {
                    dispatch(allActions.alertAction.showAlert(getAlertDetails(error, 'danger')));
                })
                .finally(() => {
                    dispatch(allActions.loaderAction.hideLoader());
                });
        }
    }, [selectedDataset, sourcedDataset]);

    useEffect(() => {
        if (contentUrl) {
            const sourceDatasetPayload = [{ profiles: [{ workBookName: contentUrl }] }];
            getDataSources(tenantId, profileId, sourceDatasetPayload)
                .then((response) => {
                    setSourcedDataset(response.tenantDataSets[0]?.profiles[0]?.dataSets[0]?.name);
                })
                .catch((error) => {
                    dispatch(allActions.alertAction.showAlert(getAlertDetails(error, 'danger')));
                });
        }
    }, [contentUrl]);
    const toggleDatasetList = () => {
        setSelectedDataset(draftSelectedDataset);
        setShowDatasetList(!showDatasetList);
    };

    const shareBtnHandler = () => {
        const selectedTenant = { ...selectedTenantList };
        selectedTenant[0].profiles[0].dataset = selectedDataset;
        if (siteName && profileName) {
            const payload = {
                publish: {
                    sourceSite: siteName,
                    sourceProfile: profileName,
                    sourceDatasource: sourcedDataset,
                    sourceWorkBookId: workbookId,
                    sourceWorkBookName: contentUrl,
                    destinationSites: [
                        {
                            tenantId: destinationtenants[0].tenantId,
                            siteName: destinationtenants[0].siteName,
                            profiles: [
                                {
                                    id: destinationtenants[0].profiles[0].id,
                                    name: destinationtenants[0].profiles[0].name,
                                    templateWorkbookName: selectedDataset?.workBookName,
                                    templateWorkbookId: selectedDataset?.workBookId,
                                    workBookName: publishWorkbookName,
                                    compareMessage: '',
                                    userList: selectedUserList.map((user) => {
                                        return user.userId;
                                    })
                                }
                            ]
                        }
                    ]
                }
            };
            onShareHandler(payload);
        }
    };
    return (
        <div className="dataset-selection-container">
            <div className="dataset-section-header">Select Destination Dataset from the list</div>

            <div className="datset-bounderies">
                {datasourceMatchingStatus.compareMessage && (
                    <div className="dataset-instructions">
                        <DatasetAllignedMessage
                            message={datasourceMatchingStatus.compareMessage}
                            isSuccess={
                                datasourceMatchingStatus.datasetAlligned
                            }></DatasetAllignedMessage>
                    </div>
                )}

                <div>
                    <Form.Group className="dataset-source">
                        <Form.Label>Origin Dataset</Form.Label>
                        <Form.Control
                            className="source-dataset disable-field"
                            value={sourcedDataset || 'Loading...'}
                        />
                        <Form.Label>Destination dataset</Form.Label>
                        <Form.Control
                            value={
                                selectedDataset?.name || (isDataSourceLoading ? 'Loading...' : '')
                            }
                        />
                        <Button
                            type={`secondry toggle ${showDatasetList ? 'selected' : ''}`}
                            disabled={!datasets?.length}
                            onClick={() => {
                                toggleDatasetList();
                            }}>
                            Change <i className="bi bi-caret-down-fill drop-icon"></i>
                        </Button>
                    </Form.Group>
                    {!isDataSourceLoading && !datasets?.length ? (
                        <span className="p-1">{i18nLabels.no_data_source}</span>
                    ) : null}
                </div>

                {showDatasetList ? (
                    <div className="datasets-list-container">
                        <div className="datasets-list-header">Connect to data</div>

                        <div className="datasets-list">
                            <div className="datasets-list-heading">
                                <span className="dataset-col-radio"></span>
                                <span className="dataset-col-name">Name</span>
                                <span className="dataset-col-workbooks">Workbooks</span>
                                <span className="dataset-col-time">Live/Last Extract</span>
                                <span className="dataset-col-owner">Owner</span>
                            </div>
                            <div className="datasets-list">
                                <ul className="share-selected-users">
                                    {datasets.map((dataset) => {
                                        return (
                                            <li
                                                data-testid={dataset.name}
                                                className={
                                                    draftSelectedDataset?.name === dataset.name
                                                        ? 'datasets-item selected'
                                                        : 'datasets-item'
                                                }
                                                onClick={() => {
                                                    setDraftSelectedDataset(dataset);
                                                }}
                                                key={dataset.name}>
                                                <span className="dataset-col-radio">
                                                    <Form.Check
                                                        inline
                                                        disabled={true}
                                                        name="group1"
                                                        checked={
                                                            draftSelectedDataset?.name ===
                                                            dataset.name
                                                        }
                                                        type="radio"
                                                        id={`inline-'radio'-1`}
                                                    />
                                                </span>
                                                <span className="dataset-col-name">
                                                    {dataset.name}
                                                </span>
                                                <span className="dataset-col-workbooks">
                                                    <span
                                                        className="background-count"
                                                        style={{
                                                            width: `${
                                                                (dataset.workBookCount * 100) /
                                                                maxDataset
                                                            }%`
                                                        }}>
                                                        {dataset.workBookCount}
                                                    </span>
                                                </span>
                                                <span className="dataset-col-time">
                                                    {moment(dataset.lastExtract).format(
                                                        'MMM, DD,YYYY, h:mm A'
                                                    )}
                                                </span>
                                                <span className="dataset-col-owner">
                                                    {dataset.ownerName}
                                                </span>
                                            </li>
                                        );
                                    })}{' '}
                                </ul>
                            </div>
                        </div>
                        <div className="dataset-btn-holder">
                            <Button
                                type="secondry"
                                onClick={() => {
                                    toggleDatasetList();
                                }}>
                                Okay
                            </Button>
                        </div>
                    </div>
                ) : null}
            </div>
            {!showDatasetList ? (
                <div className="dataset-btn-holder">
                    <Button type="secondry" onClick={onBack}>
                        Back
                    </Button>
                    <Button onClick={shareBtnHandler} disabled={!selectedDataset}>
                        Share
                    </Button>
                </div>
            ) : null}
        </div>
    );
}

export default DatasetSelection;
