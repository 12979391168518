const awsmobile2 ={
    Auth: {
      region: process.env.REACT_APP_AWS_PROJECT_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
      oauth: {
        domain: process.env.REACT_APP_DOMAIN_URL,
        scope: ["phone", "email", "openid", "aws.cognito.signin.user.admin", "profile"],
        "redirectSignIn": process.env.REACT_APP_REDIRECT_SIGNIN,
        "redirectSignOut":  process.env.REACT_APP_REDIRECT_SIGNOUT,
        responseType: "code"
      }
    },
    API: {
      endpoints: [
        {
          name: "MyBlogPostAPI",
          endpoint: "<enter here the API gateway endpoint url>"
        }
      ]
    }
  };

export default awsmobile2;
