import React, { useState, useEffect, ReactNode } from 'react';
import './SwitchProfile.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store';
import { getAlertDetails, getRedirectRoute } from '../../../services/utils';
import { setBank, setProfile, setTenant } from '../../../services/user.service';
import allActions from '../../../store/actions';
import { setLastAccessed, getLastPreference, getTenantId } from '../../../services/api.service';
import useAddAudit from '../../../hooks/useAddAudit';
import { auditEvents } from '../../../services/auditActions';
import utils from '../../../services/utils';
import i18nLabels from '../../../services/i18n.service';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type Props = {
    setShowSwitchProfile: (boolean: boolean) => void;
    showSwitchProfile: boolean;
    setDisableBtn: (boolean: boolean) => void;
};
type typeItemNew = {
    bankname?: any;
    role?: string;
    parent?: any[];
};
type typeProfile = {
    bankname?: string;
    bankProfile?: string;
    role?: string;
};
type typeProfileItem = {
    bankProfile?: string;
    role?: string;
};

const findRoles = (allRoles, site) => {
    try {
        return allRoles
            .find((role) => {
                return role.indexOf(site) > -1;
            })
            .split('_')[1];
    } catch {
        console.log('Error in role mapping');
    }
};
/**
 * SwitchProfile Component
 * @param {Function} setShowSwitchProfile -  Passing boolean value, For hiding switch panel after click on cross button
 * @param {Boolean} showSwitchProfile -  For showing switch panel as per condition
 * @param {Function} setDisableBtn - For disabling switch button, if one bank and one profile
 * @return {Object} Return SwitchProfile Component
 */
function SwitchProfile({ setShowSwitchProfile, showSwitchProfile, setDisableBtn }: Props) {
    const handleClose = () => setShowSwitchProfile(false);
    const navigate = useNavigate();
    const [switchUserProfile, setSwitchUserProfile] = useState<typeProfile>({});
    const [userRole, setUserRole] = useState(null);
    const [bankProfileItem, setBankProfileItem] = useState<typeProfileItem>({});
    const [selectedProfileName, setSelectedProfileName] = useState('');
    const [jsonbanklist, setJsonbanklist] = useState<typeItemNew[]>([]);
    const [active, setActive] = useState(0);
    const [bankName, setBankName] = useState();
    const {addAuditEvent} = useAddAudit();

    const [profileNumber, setProfileNumber] = useState(0);
    const [disableSwitchBtn, setDisableSwitchBtn] = useState(true);
    let arraylist: Array<ReactNode> = [];
    const location = useLocation();
    let counterNumber = -1;

    const dispatch = useDispatch();
    const userProfile = useSelector((state: RootState) => {
        return state.userProfile;
    });

    const [siteName, profileName, profileIndex] = useSelector((state: RootState) => {
        return [state.user.siteName, state.user.profileName, state.user.profileIndex];
    });
    const [tenantIds, tenantId] = 
    useSelector((state: RootState) => {
        return [ state.audit.tenantIds, state.audit.tenantId];
    });

    useEffect(() => {
        if (siteName || profileName) {
            setBankName(siteName);
            setProfileNumber(profileIndex);
            setSelectedProfileName(profileName)
            const bankListIndex = jsonbanklist.findIndex((item) => item.bankname === siteName);
            setActive(bankListIndex);
        }
    }, [siteName, profileName]);

    useEffect(() =>{
        if (tenantId) {
            const newLogin = localStorage.getItem('audit-login');
            if (newLogin) {
                try {
                    addAuditEvent(auditEvents.user.login, 'Logged in')
                } catch (err) {
                    console.log(err)
                }
            }
            localStorage.removeItem('audit-login');
        }
    },[tenantId])

    useEffect(() => {
        const bank_profile = userProfile.profile.replace(/users_ARGUS2/g, "ARGUS2");
        const profile_roles = userProfile.roles.split(',');

        const profileItem = bank_profile.replace('[', '');
        const profileItemnew = profileItem.replace(']', '');
        const profile = profileItemnew.split(',');

        if (profile.length == 1) {
            const valueparent = profile[0].split('_');
            const realvalue = valueparent.slice(2);
            if (realvalue.length == 1) {
                setDisableBtn(true);
            }
        }

        const profileList = profile.map((item, index) => {
            const profiles = profile[index].split('_');
            const itemnew: typeItemNew = {};
            itemnew.bankname = profiles[1];
            itemnew.role = findRoles(profile_roles, itemnew.bankname);
            const newarray = profiles.slice(2);
            itemnew.parent = newarray;
            return itemnew;
        });

        const uniqueBankName: Array<unknown> = [
            ...new Set(profileList.map((item) => item.bankname))
        ];
        uniqueBankName.sort();
        const profileListnew = uniqueBankName.map((item, index) => {
            const itemarray: typeItemNew = {};
            itemarray.bankname = uniqueBankName[index];
            itemarray.role = findRoles(profile_roles, itemarray.bankname);

            arraylist = [];
            for (const element of profileList) {
                if (uniqueBankName[index] == element.bankname) {
                    element.parent?.forEach((profile_name) => {
                        arraylist.push(profile_name);
                    });
                }
            }
            itemarray.parent = arraylist;
            return itemarray;
        });

        setJsonbanklist(profileListnew);
        if(profileListnew[0]?.bankname) {
            getPreference(profileListnew, profile_roles);
        } else {
            console.log('profile not found')
        }
        dispatch(allActions.userAction.addAllProfile(profileListnew));
    }, [userProfile]);

   
    const defaultProfile = (profileListnew) => {
        const defaultProfileObj = JSON.parse(JSON.stringify(profileListnew[0]));
        dispatch(allActions.userAction.addSiteName(defaultProfileObj.bankname));
        dispatch(allActions.userAction.addProfileName(defaultProfileObj.parent[0]));
        dispatch(allActions.userAction.addRole(defaultProfileObj.role));

         updateCurrentProfileRoleForAudit(defaultProfileObj.parent[0]
            ,defaultProfileObj.role,defaultProfileObj.bankname);
            dispatch(allActions.userAction.addSignalsEnabled(userProfile));
    };

    const getPreference = async (profileListnew, profile_roles) => {
        const defaultProfiles = JSON.parse(JSON.stringify(profileListnew[0]));
        getLastPreference(defaultProfiles.bankname, defaultProfiles.parent[0])
            .then((reponse) => {
                const bank_name = reponse.userPreferences?.bankId;
                const prefProfileName = reponse.userPreferences?.lastusedProfile;
                const prefUserRole = findRoles(profile_roles, bank_name);
                const prefProfileIndex = reponse.userPreferences?.index;

                if (bank_name) {
                    dispatch(allActions.bankAction.bankName(bank_name));
                    dispatch(allActions.userAction.addSiteName(bank_name));
                    dispatch(allActions.userAction.addProfileName(prefProfileName));
                    dispatch(allActions.userAction.addRole(prefUserRole));
                    dispatch(allActions.userAction.addProfileIndex(prefProfileIndex));
                    dispatch(allActions.userAction.addSignalsEnabled(userProfile));
                    setTenant(bank_name);
                
                     updateCurrentProfileRoleForAudit(prefProfileName,prefUserRole
            ,bank_name);
                } else {
                    defaultProfile(profileListnew);
                }
            })
            .catch((response) => {
                defaultProfile(profileListnew);
                dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
            });
    };


        const getTenantID = (currentBankName) =>  {           
            dispatch(allActions.loaderAction.showLoader());
            const tenantMap = new Map();
            getTenantId().then((response) => {
                for (const profile in response) {
                    tenantMap.set(response[profile]["tenant"], response[profile]["tenantId"]);
                }
                dispatch(allActions.auditAction.addTenantIds(tenantMap));
                dispatch(allActions.auditAction.addTenantId(tenantMap.get(currentBankName)));
            }
            ).catch(error =>
                allActions.alertAction.showAlert({type:"danger",message: error}))
                .finally(() => dispatch(allActions.loaderAction.hideLoader()))
    
    }

   

    const handleClick = (event, bankname) => {
        setBankName(bankname);
        setActive(event.target.id);
        setProfileNumber(-1) 
        setSelectedProfileName('');
        setDisableSwitchBtn(true);
    };

    const profileSwitch = (event, bankname, bankProfile, role) => {
        setSwitchUserProfile(bankname);
        setBankProfileItem(bankProfile);
        setSelectedProfileName(bankProfile.bankProfile)
        setUserRole(role);
        setDisableSwitchBtn(false);
        setProfileNumber(event.target.id);
    };

    const switchUserProfileBtn = () => {
        localStorage.removeItem('system-notification-off');
        setShowSwitchProfile(false);

        const addLogs = {
            'stateChangeCurrent': { profile: `${switchUserProfile.bankname}/${bankProfileItem.bankProfile}` },
            'stateChangePrevious': { profile: `${siteName}/${profileName}` }
        }
        addAuditEvent(auditEvents.user.profileSwitch, `Profile switched to ${switchUserProfile.bankname}/${bankProfileItem.bankProfile}`, 'SUCCESS', {}, addLogs)
        dispatch(allActions.bankAction.bankName(switchUserProfile.bankname));
        dispatch(allActions.userAction.addRole(userRole));
        dispatch(allActions.userAction.addSiteName(switchUserProfile.bankname));
        dispatch(allActions.userAction.addProfileName(bankProfileItem.bankProfile));
        dispatch(allActions.userAction.addProfileIndex(profileNumber));
        dispatch(allActions.userAction.addSignalsEnabled(userProfile));
        updateCurrentProfileRoleForAudit(bankProfileItem.bankProfile,
            userRole,switchUserProfile.bankname)
        setBank(switchUserProfile.bankname);
        setProfile(bankProfileItem.bankProfile);
        navigate(getRedirectRoute(location.pathname));
        
        setLastAccessed(
            switchUserProfile.bankname,
            bankProfileItem.bankProfile,
            profileNumber
        ).catch(() =>{
            console.log('error in post preference api')
        })
        setDisableSwitchBtn(true);
    };

    const updateCurrentProfileRoleForAudit = (profile,role,bankName) => {
        if (process.env.REACT_APP_AUDIT === "true") {
            if (profile !== undefined && role !== undefined && bankName !== undefined) {
                const userProfiles = userProfile.profile.replace(']', '').replace('[', '');
                const profiles = userProfiles.split(',');
                const profile_roles = userProfile.roles.split(',');
                const currentProfile = profiles.filter(el => el.includes(profile));
                const currentRole = profile_roles.filter(el => el.includes(bankName) && el.includes(role));

                setTenant(bankName);
                dispatch(allActions.auditAction.addCurrentProfile(currentProfile[0].trim()));
                dispatch(allActions.auditAction.addCurrentRole(currentRole[0]));

                if (!tenantIds?.size) { 
                    getTenantID(bankName) 
                }
                if(tenantIds.has(bankName)) {
                    dispatch(allActions.auditAction.addTenantId(tenantIds.get(bankName)));
                }
                dispatch(allActions.auditAction.addTenantId((tenantIds.get(currentProfile[0].split("_")[2]))));
            }
        }
    }

    const getMessage = (message: string) => (<Tooltip id="tooltip" > <div style={{width: 'max-content'}}>{message} </div></Tooltip>);

    return (
        <div className="row">
            <Offcanvas
                className="switch-profile "
                placement="end"
                show={showSwitchProfile}
                onHide={handleClose}>
                <Offcanvas.Header closeButton className="pt-2 pb-2">
                    <Offcanvas.Title className="fs-13 col-md-9 switch-section-title">
                    {i18nLabels.switch_profile_title}
                    </Offcanvas.Title>
                    <Offcanvas.Title className="fs-13 col-md-2 mt-1">
                        <button
                            className="switch-profile fs-12 fw-bold"
                            disabled={disableSwitchBtn}
                            data-testid="switch-btn"
                            onClick={() => {
                                switchUserProfileBtn();
                            }}>
                            {i18nLabels.switch_profile_switch_btn}
                        </button>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="row mb-2">
                        <h6 className="fs-13 mb-2 col-md-6 client-list-title">{i18nLabels.switch_profile_header_bank}</h6>
                        <h6 className="fs-13 mb-2 col-md-6 ps-0 profile-list-title">{i18nLabels.switch_profile_header_profile}</h6>
                    </div>
                    <div className="nav-switch-profile">
                        <ul className="ps-0 client-list">
                            {jsonbanklist.map(({ bankname }, index: number) => {
                                return (
                                    <li
                                        key={bankname}
                                        id={'' + index}
                                        className={bankName == bankname ? 'active' : undefined}
                                        onClick={(event) => {
                                            handleClick(event, bankname);
                                        }}>

                                        {utils.maskBankName(bankname)?.length > 23 ? <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={getMessage(utils.maskBankName(bankname))}
                                        >
                                            <div>{utils.maskBankName(bankname).slice(0,15)+ '...'}</div>
                                        </OverlayTrigger> : utils.maskBankName(bankname)}
                                    </li>
                                );
                            })}
                        </ul>

                        <ul>
                            {jsonbanklist.map(({ bankname, parent, role }, index: number) => {
                                return (
                                    <div
                                        key={bankname}
                                        id={'' + index}
                                        className={active == index ? 'active' : undefined}>
                                        <ul className="Profile-section">
                                            {parent?.map(function (bankProfile) {
                                                {
                                                    counterNumber++;
                                                }
                                                return (
                                                    <li
                                                        key={bankProfile}
                                                        id={'' + counterNumber}
                                                        className={
                                                            selectedProfileName === bankProfile
                                                                ? 'active'
                                                                : undefined
                                                        }
                                                        onClick={(event) => {
                                                            profileSwitch(
                                                                event,
                                                                { bankname },
                                                                { bankProfile },
                                                                role
                                                            );
                                                        }}>
                                                        {bankProfile?.length > 23 ? <OverlayTrigger
                                                            placement="top"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={getMessage(bankProfile)}
                                                        >
                                                            <div>{bankProfile.slice(0,15)+'...'}</div>
                                                        </OverlayTrigger> : bankProfile}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                );
                            })}
                        </ul>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default SwitchProfile;
