import React, { useEffect } from 'react';
import './alert.scss';
import Alert from 'react-bootstrap/Alert';
import { useSelector, useDispatch } from 'react-redux';
import allActions from '../../../../store/actions';
import type { RootState } from '../../../../store/store';
import i18nLabels from '../../../../services/i18n.service';

type Props = {
    isHeaderVisible: boolean;
};
function ArgusAlert({isHeaderVisible}: Props) {
    const dispatch = useDispatch();
    const alertState = useSelector((state: RootState) => {
        return state.alert;
    });
    useEffect(() => {
        if (alertState.isOpen && alertState.type === 'success') {
            setTimeout(() => {
                dispatch(allActions.alertAction.hideAlert());
            }, 3000);
        }
    }, [alertState.isOpen]);
    if (!alertState.isOpen) {
        return null;
    }
    return (
        <div className={`alert-container ${isHeaderVisible ? 'top-position' : ''}`}>
            <Alert
                variant={alertState.type}
                onClose={() => dispatch(allActions.alertAction.hideAlert())}
                dismissible>
                {alertState.type === 'danger' && (
                    <span className="material-icons danger">cancel</span>
                )}
                {alertState.type === 'success' && (
                    <span className="material-icons success">check_circle</span>
                )}
                { alertState.showCode && <span>
                    <strong>{alertState.code}</strong>
                </span>}
                
                <span>{alertState.message || i18nLabels.generic_error_msg}</span>
            </Alert>
        </div>
    );
}

export default ArgusAlert;
