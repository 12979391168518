const alert = (
    state = { showLoader: false },
    { type }
) => {
    switch (type) {
        case 'SHOW_LOADER':
            return {
                ...state,
                showLoader: true
            };
        case 'HIDE_LOADER':
            return {
                ...state,
                showLoader: false
            };
        default:
            return state;
    }
};
export default alert;
