import React, { useEffect, useState, useCallback } from 'react';
import {
    TypeSelectedFilter
} from '../../../types/types';
import { useDispatch, useSelector } from 'react-redux';
import useContainerSize from './useContainerSize';
import allActions from '../../../store/actions';
import type { RootState } from '../../../store/store';
import { getTranformedFilters } from './tableauUtils';
import useSyncFilter from './useSyncFilter';

import useTableauFilter from './useTableauFilter'
import { addLogs } from '../../../services/utils';
import { globalConstant } from '../../../services/constant';
import useAddAudit from '../../../hooks/useAddAudit';
import { auditEvents } from '../../../services/auditActions';
type Props = {
    url: string;
    selectedFilters?: Array<TypeSelectedFilter>;
    exportType?: string | null;
    setExportType?: () => void;
    showFilters?:boolean;
    setCurrentTabSheet?: (worksheetId: string) => void;
    setDashboardIsVizRendered?: (flag: boolean) => void;
    setSelectedFilters?: () => void;
    screenType: string;
    reportType: string;
};

let viz;
let tabChangeHandler;


function ViewTableau({
    url,
    selectedFilters,
    exportType,
    setExportType,
    setDashboardIsVizRendered,
    showFilters,
    screenType,
    setCurrentTabSheet,
    reportType
}: Props) {
    const [isVizRendered, setIsVizRendered] = useState(false);
    const { vizHeight } = useContainerSize(screenType);
    const {syncLocalFilters}  = useSyncFilter();
    const { applySelectedFilters } = useTableauFilter();
    
    const { applySelectedFilter } = useSelector((state: RootState) => {
        return state.dimensionFilters;
  });
    const dispatch = useDispatch();
    const {addAuditEvent} = useAddAudit();

    useEffect(() => {
        if (selectedFilters?.length && isVizRendered && applySelectedFilter) {
            const dimFilters = getTranformedFilters(selectedFilters);
            applySelectedFilters(viz.workbook.activeSheet, dimFilters, isVizRendered, viz);
            dispatch(allActions.dimensionFiltersAction.triggerApplyFilter(false));
        }
    }, [selectedFilters, isVizRendered, applySelectedFilter]);

    useEffect(() => {
        if (showFilters) {
            initSyncLocalFilters(false);
        }
    }, [showFilters]);

    useEffect(() => {
        if(isVizRendered) {
            if(screenType !== 'FAVORITE' && reportType === globalConstant.reportType.dashboard) {
                const addLogs = {
                    'Dashboard': viz.workbook.name
                };
                addAuditEvent(auditEvents.dashboard.view, `${viz.workbook.name} viewed`, 'SUCCESS', addLogs)
            }else if(reportType === globalConstant.reportType.signals){
                const addLogs = {
                    'SF Name': viz.workbook.name
                };
                addAuditEvent(auditEvents.signal.view, `${viz.workbook.name} viewed`, 'SUCCESS', addLogs)
            }
        }
        
    }, [isVizRendered])

    useEffect(() => {
        addLogs('Workbook-log', 'View Workbook loading start');
        setIsVizRendered(false);
        viz = document.getElementById('tableauViz');
        viz.removeEventListener('firstinteractive', onFirstInteractiveHandler);
        viz.addEventListener('firstinteractive', onFirstInteractiveHandler);
        return () => {
            viz.removeEventListener('firstinteractive', onFirstInteractiveHandler);
        };
    }, [url]);

    useEffect(() => {
        if (viz) {
            viz.removeEventListener('tabswitched', tabChangeHandler);
            tabChangeHandler = () => {
                const currentTabSheetId = viz.workbook.activeSheet.url.split('/').pop();
                setCurrentTabSheet && setCurrentTabSheet(currentTabSheetId);
                initSyncLocalFilters();
            };
            viz.addEventListener('tabswitched', tabChangeHandler);
        }
    }, [isVizRendered]);
    useEffect(() => {
        if (exportType === 'image') {
            viz.exportImageAsync();
            
        } else if (exportType) {
            viz.displayDialogAsync(exportType);
        }
        if(exportType){
            const downloadFormat= globalConstant.exportType[exportType];
            const addLogs = {
                'Dashboard': viz.workbook.name,
                'Exported format': downloadFormat
            };
            addAuditEvent(auditEvents.dashboard.download, `${viz.workbook.name} downloaded in ${downloadFormat} format`, 'SUCCESS', addLogs)
        }
        if (exportType && setExportType) {
            setExportType();
        }
    }, [exportType]);

    const onFirstInteractiveHandler = useCallback(() => {
        dispatch(allActions.userAction.addWorkkbookName(viz.workbook.name));
        if (setDashboardIsVizRendered) {
            setDashboardIsVizRendered(true);
        }
        setIsVizRendered(true);
        initSyncLocalFilters();
        addLogs('Workbook-log', 'View Workbook loading completed');
        
    }, []);

    const initSyncLocalFilters = async (isApplyFilterRequired = true) => {
        const sheet = viz.workbook.activeSheet;
        if(reportType === globalConstant.reportType.dashboard) {
            syncLocalFilters(sheet, isApplyFilterRequired);
        }
    };

    return (
        <>
            <div className="tableau-container">
                <div className="viz-container">
                    <tableau-viz
                        id="tableauViz"
                        toolbar="hidden"
                        height={vizHeight}
                        width="100%"
                        src={url}>
                    </tableau-viz>
                </div>
            </div>
        </>
    );
}
export default ViewTableau;
