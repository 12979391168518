import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import Alert from 'react-bootstrap/Alert';
import './sessiontimeout.scss';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import utils, { addLogs, checkUserInternal, encrpt, isSessionExpired } from '../../../../services/utils';
import { globalConstant } from '../../../../services/constant';
import { auditEvents } from '../../../../services/auditActions';
import useAddAudit from '../../../../hooks/useAddAudit';
import i18nLabels from '../../../../services/i18n.service';

let sessionOutTimeInterval: number | null = 0;
let refreshTokenInterval: number | null = 0;
let counterInterval: number | null = 0;

let popupStartTime;
let isUserInternal;
let sessionExpiryTime;


type Props = {
    Auth: any;
    logOut: () => void;
    isHeaderVisible: boolean;
};

const notificationTime = 300; //Seconds
const sessionOutNotificationTime = notificationTime*1000;

function SessionTimeOut({ logOut, Auth, isHeaderVisible }: Props) {
    const { role } = useSelector((state: RootState) => {
        return state.user;
    });
    
    const [counter, setCounter] = useState(globalConstant.inactiveTimerDuration);
    const [showSessionTimeOut, setShowSessionTimeOut] = useState(false);
    const { addAuditEvent } = useAddAudit();
    useEffect(() => {
        if(role) {
            isUserInternal = checkUserInternal(role);
            sessionExpiryTime = isUserInternal ? globalConstant.expiryTime.internal : globalConstant.expiryTime.external;
            refreshToken();
        }
        
    }, [role]);

    useEffect(()=>{
        if(showSessionTimeOut) {
            try{
                addAuditEvent(auditEvents.notification.timeout, 'Timed out by system');
                
            }catch(err) {
                console.log(err)
            }
        }
    }, [showSessionTimeOut])

    useEffect(() => {
        if (showSessionTimeOut) {
            popupStartTime = moment();
            clearInterval(counterInterval || 0);
            counterInterval = window.setInterval(() => {
                const timeLeft = moment().diff(popupStartTime, 'seconds');
                setCounter(notificationTime - timeLeft);
            }, 1000);
        }
    }, [showSessionTimeOut]);

    useEffect(() => {
        if (showSessionTimeOut && counter <= 1) {
            clearInterval(counterInterval ?? 0);
            counterInterval = null;
            const _expiryTime = localStorage.getItem('session-expiry-time');
            const expTime =
                new Date(String(_expiryTime)).getTime() -
                new Date().getTime();
                    if (expTime > sessionOutNotificationTime) {
                        setShowSessionTimeOut(false);
                        sessionOutTimeInterval = window.setTimeout(
                            () => {showTimeOutPopUp()},
                            expTime - sessionOutNotificationTime
                        );
                    } else {
                        localStorage.setItem('session-timeout', 'true');
                        logOut();
                    }
                
        }
    }, [counter]);

    useEffect(() => {
        const sessionManage = (e) => {
            if (e.type !== 'mousemove' && showSessionTimeOut) {
                clearTimeout(sessionOutTimeInterval ?? 0);
                sessionOutTimeInterval = null;
                clearInterval(counterInterval ?? 0);
                counterInterval = null;
                setShowSessionTimeOut(false);
                setCounter(0);
                refreshToken();
                return;
            }
            if (!refreshTokenInterval && !showSessionTimeOut) {
                refreshToken();
            }
        };
        document.addEventListener('mousemove', sessionManage, false);
        document.addEventListener('click', sessionManage, false);
        return () => {
            document.removeEventListener('mousemove', sessionManage, false);
            document.removeEventListener('click', sessionManage, false);
        };
    }, [showSessionTimeOut]);

    const showTimeOutPopUp = () => {
        const _expiryTime = localStorage.getItem('session-expiry-time');
                const expTime =
                    new Date(String(_expiryTime)).getTime() -
                    new Date().getTime();
                if (expTime > sessionOutNotificationTime) {
                    addLogs('signout', 'Timer not required: extend')
                    setShowSessionTimeOut(false);
                    sessionOutTimeInterval = window.setTimeout(
                        () => {showTimeOutPopUp()},
                        expTime - sessionOutNotificationTime
                    );
                } else {
                    addLogs('signout', 'Timer appear')
                    setShowSessionTimeOut(true);
                }
           
    };

    const hideAlertMessage = () => {
        try{
            addAuditEvent(auditEvents.notification.remainLoggedIn, 'Remain logged in')
        }catch(err) {
            console.log(err)
        }
        setShowSessionTimeOut(false);
        refreshToken();
    };

    const refreshToken = async () => {
        if (isSessionExpired()) {
            addLogs('signout', 'session already expired: logout called ')
            localStorage.setItem('session-timeout', 'true');
            logOut();
            return;
        }
            
        if (sessionExpiryTime) {
            clearTimeout(refreshTokenInterval || 0);
            refreshTokenInterval = null;
            clearInterval(counterInterval || 0);
            counterInterval = null;
            refreshTokenInterval = window.setTimeout(() => {
                clearTimeout(refreshTokenInterval || 0);
                refreshTokenInterval = null;
                console.log('Ready for refresh token');
            }, 60000 * 2);

            await Auth.currentAuthenticatedUser()
                .then(async (user) => {
                    user.refreshSession(
                        user.signInUserSession.refreshToken,
                        refreshTokenhandler)
                })
                .catch((e) => {
                    addLogs('signout', `currentAuthenticatedUser error logout called > ${e}`);
                    logOut();
                });
        }
    };

    const refreshTokenhandler = async (err, session) => {
        if (err) {
            addLogs('signout', `refresh session failed >> ${err} `)
            logOut();
        }
        if (session) {
            clearTimeout(sessionOutTimeInterval ?? 0);
            sessionOutTimeInterval = null;
            const _sessionExpiryTime = moment().add(sessionExpiryTime, 'seconds');
            localStorage.setItem('session-expiry-time', encrpt(String(_sessionExpiryTime)));
            const expTimeInSecond = new Date(String(_sessionExpiryTime)).getTime() - new Date().getTime();
            addLogs('signout', `Next Sessionout Time: ${_sessionExpiryTime.format('h:mm:ss A')}`)
            sessionOutTimeInterval = window.setTimeout(
                () => {showTimeOutPopUp()},
                (expTimeInSecond) - sessionOutNotificationTime
            );
        }
    }

    const onFocusHandler = useCallback(() => {
        if (!showSessionTimeOut) {
            refreshToken();
        }
    }, [showSessionTimeOut]);

    useEffect(() => {
        window.addEventListener('focus', onFocusHandler);
        return () => {
            window.removeEventListener('focus', onFocusHandler);
        };
    }, [showSessionTimeOut]);
    useEffect(() => {
        localStorage.setItem('session-timeout', 'false');
    }, []);

    if (!showSessionTimeOut) {
        return <></>;
    }

    return (
        <div className={`alert-session-timeout ${isHeaderVisible ? 'top-position' : ''}`}>
            <Alert variant="success" className="border-0 rounded-bottom">
                <p className="mb-0 mt-2">
                    {' '}
                    {i18nLabels.session_time_out_alert1} {utils.formatSessionTime(counter)} {i18nLabels.session_time_out_alert2}
                </p>

                <Button
                    variant="warning"
                    className="rounded-pill border-0 mt-1 ms-1"
                    onClick={hideAlertMessage}>
                    {i18nLabels.session_time_out_stay_signin}
                </Button>
            </Alert>
        </div>
    );
}
export default SessionTimeOut;
