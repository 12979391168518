const addUser = (payload) => {
    return {
        type: "ADD_USER",
        payload
    }
}

const addFavorite = (payload) => {
    return {
        type: "ADD_FAVORITE",
        payload
    }
}

const addSiteName = (payload) => {
    return {
        type: "ADD_SITE_NAME",
        payload
    }
}

const addSignalsEnabled = (userProfile) => {
    return {
        type: "ADD_SIGNALS_ENABLED",
        payload: userProfile
    }
}

const updateSystemNotification = (showNotification) => {
    return {
        type: "SHOW_SYSTEM_NOTIFICATION",
        payload: showNotification
    }
}

const addProfileName = (payload) => {
    return {
        type: "ADD_PROFILE_NAME",
        payload
    }
}
const addProfileIndex = (payload) => {
    return {
        type: "ADD_PROFILE_INDEX",
        payload
    }
}

const addUserID = (payload) => {
    return {
        type: "ADD_USER_ID",
        payload
    }
}

const addUserEmail = (payload) => {
    return {
        type: "ADD_USER_EMAIL",
        payload
    }
}

const addRole = (payload) => {
    return {
        type: "ADD_ROLE",
        payload
    }
}

const addWorkkbookName = (payload) => {
    return {
        type: "ADD_WORKBOOK_NAME",
        payload
    }
}
const addWorkbookId = (payload) => {
    return {
        type: "ADD_WORKBOOK_ID",
        payload
    }
}
const addAllProfile = (payload) => {
    return {
        type: "ADD_ALL_PROFILE",
        payload
    }
}

const reloadDashboard = (payload) => {
    return {
        type: "RELOAD_DASHBOARD",
        payload
    }
}



export default {
    addUser,
    addFavorite,
    addRole,
    addWorkkbookName,
    addSiteName,
    addProfileName,
    addProfileIndex,
    addUserID,
    addAllProfile,
    addWorkbookId,
    addUserEmail,
    reloadDashboard,
    addSignalsEnabled,
    updateSystemNotification
}