let userId;
let userRole;
let bankId;
let dashboardName;
let workbookId;
let profileName;
let Auth
let dashboardUserId;
let tenant;


export const getUserId = () => {
    return userId;
};

export const setUserId = (id) => {
    userId = id;
};

export const getUserRole = () => {
    return userRole;
};

export const setUserRole = (role) => {
    userRole = role;
};

export const getBank = () => {
    return bankId;
};

export const setBank = (bank) => {
    bankId = bank;
};
export const getProfile = () => {
    return profileName;
};

export const setProfile = (profile) => {
    profileName = profile;
};


export const getDashboardName = () => {
    return dashboardName;
};

export const setDashboardName = (dashboard) => {
    dashboardName = dashboard;
};

export const getWorkbookId = () => {
    return workbookId;
};

export const setWorkbookId = (workbook) => {
    workbookId = workbook;
};

export const getAuthObject = () => {
    return Auth;
}

export const setAuthObject = (auth) => {
    Auth = auth; 
    if (window.location.href.includes('localhost:3000')) {
        Auth.currentAuthenticatedUser = () => {
            return Promise.resolve({ "accessToken": process.env.REACT_APP_TOKEN, "ExpiresIn": 3600, "idToken": { jwtToken: process.env.REACT_APP_TOKEN }, "TokenType": "Bearer" })
        }
        Auth.currentSession = Auth.currentAuthenticatedUser;
    }
};


export const setDashboardUserId = (id) => {
    dashboardUserId = id;
};


export const getDashboardUserId = () => {
    return dashboardUserId; 
};
export const getTenant = () => {
    return tenant;
}
export const setTenant = (tenantName) => {
    tenant=tenantName;
}