
const initialState = {
    isOpen: false,
    activity:'',
    workbookId: '',
    workBookName:'',
    confirmationMessage: ''
}

const deleteDashboard = (
    state = initialState,
    { type, payload }
) => {
    switch (type) {
        case 'SHOW_CONFIRMATION_MODEL':
            return {
                ...state,
                isOpen: true,
                workbookId: payload.workbookId,
                workBookName: payload.workBookName,
                activity:payload.activity,
                confirmationMessage:payload.confirmationMessage
            };
        case 'HIDE_CONFIRMATION_MODEL':
            return initialState;
        default:
            return state;
    }
};
export default deleteDashboard;
