import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
type Props = {
    title?: string;
    onCanvasClose: () => void;
    children: JSX.Element;
};

function ArgusOffcanvas({ title, onCanvasClose, children }: Props) {
    const [show, setShow] = useState(true);

    const handleClose = () =>  { 
        setShow(false);
        onCanvasClose()
    }

    return (
        <>
            <Offcanvas className="argus-offcanvas" placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton className="pt-2 pb-2">
                    <Offcanvas.Title title={title} className="fs-12 col-md-11 title">{title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>{children}</Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
export default ArgusOffcanvas;
