import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../../../store/store';
import allActions from '../../../store/actions';
import './VizFilters.scss';

import { TypeFilter } from '../../../types/types';
import FilterItem from './VizFilters';
import i18nLabels from '../../../services/i18n.service';

function VizFilterContainer() {
    const dispatch = useDispatch();
    const stateSelectedFilters = useSelector((state: RootState) => {
        return state.dimensionFilters.selectedFilters;
    });
    const stateAppliedFilters = useSelector((state: RootState) => {
        return state.dimensionFilters.appliedFilters;
    });
    const updateFilters = (filterIndex: number, optionIndex: number, value: boolean) => {
        dispatch(
            allActions.dimensionFiltersAction.updateFilterOption(filterIndex, optionIndex, value)
        );
    };
    const applySelectedFilter = (filterIndex) => {
        dispatch(allActions.dimensionFiltersAction.updateFilterOnApply(filterIndex, true));
        dispatch(allActions.dimensionFiltersAction.triggerApplyFilter(true));
    };

    const clearOptions = (filterIndex: number) => {
        dispatch(allActions.dimensionFiltersAction.updateFilterOnApply(filterIndex, false));
    };
    const resetSelection = (filterIndex: number) => {
        dispatch(allActions.dimensionFiltersAction.resetSelectedDimension(filterIndex));
        dispatch(allActions.dimensionFiltersAction.triggerApplyFilter(true));
    };
    const clearAll = () => {
        dispatch(allActions.dimensionFiltersAction.clearAll());
        dispatch(allActions.dimensionFiltersAction.triggerApplyFilter(true));
    };

    if(stateSelectedFilters && !stateSelectedFilters.length) {
        return <div className="filter-container">
            <div className='no-filters'>{i18nLabels.dashboard_filters_no_filter}</div>
        </div>
    }
    return (
        <>
            <div className="filter-container">
                {stateSelectedFilters.map((filter: TypeFilter, index: number) => {
                    return (
                        <FilterItem
                            resetSelection={resetSelection}
                            clearOptions={clearOptions}
                            key={filter.displayName}
                            updateFilters={updateFilters}
                            applySelectedFilter={applySelectedFilter}
                            filterIndex={index}
                            appliedFilterOptions={stateAppliedFilters[index].options}
                            {...filter}></FilterItem>
                    );
                })}
            </div>
            <div className="filter-clear-all">
                <a
                    className="clear-all-link"
                    onClick={(e) => {
                        e.stopPropagation();
                        clearAll();
                    }}>
                    {i18nLabels.dashboard_filters_btn_clear_all}
                </a>
            </div>
        </>
    );
}

export default VizFilterContainer;
