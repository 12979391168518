import React, { useEffect, useState, useCallback } from 'react';
import { TypeFilterOption } from '../../../../types/types';
import './MultiSelectDropdown.scss'

import Form from 'react-bootstrap/Form';

type Props = {
    displayName: string;
    options: Array<TypeFilterOption>;
    updateParentSelection: ( num2: number, bol: boolean) => void;
    placeHolderText:string,
    onResetChange: () => void;
    disable?: boolean;
};

const getMultiSelectItemClass = (option, index, isPartialSelected) =>{
    const allClass = option.isAll ? 'all-item' : '';
    const ChildClass = option.parentId ? 'child-item' : '';
    const lastChildClass = option.isLast ? 'last-child-item' : '';
    const labelClass = option.type === 'label' ? 'label-item' : '';
    const selectedClass = option.value ? 'selected' : ''
    const partialSelectedClass = (!index && isPartialSelected) ? 'partial-selected' : ''
    return `filter-list-item ${lastChildClass} ${labelClass} ${allClass} ${ChildClass}  ${selectedClass} ${partialSelectedClass} `
} 
function MultiSelectDropdown({
    displayName,
    options,
    updateParentSelection,
    placeHolderText,
    onResetChange,
    disable
}: Props) {
    const [toggleFilter, setToggleFilter] = useState(false);
    const [selectedCount, setSelectedCount] = useState(0);
    const [selectedLabel, setSelectedLabel] = useState('');
    const [isPartialSelected, setIsPartialSelected] = useState(true);
    const close = useCallback(() => {
        setToggleFilter(false);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (toggleFilter) {
                window.addEventListener('click', close);
            } else {
                window.removeEventListener('click', close);
            }
        }, 0);
    }, [toggleFilter]);

    useEffect(() => {
        let selectedValue = '';
        let result;
        let count = 0;
        options.forEach((option, index) => {
            if (option.value && index && option.type !== 'label') {
                selectedValue = option.label;
                count++;
            }
        });
        if (count >= (options.length - 1)) {
            result = 'All Selected';
        } else if (count === 1) {
            result = selectedValue;
        } else {
            result = `${count} Selected`;
        }

        setSelectedLabel(result);
        setSelectedCount(count);

      
        let currentSelectedCount = 0;
        let totalCheckbox = 0;
        options.forEach((option, index) => {
            if(option.type !== 'label' && index){
                totalCheckbox++
            }
            if (option.value && index && option.type !== 'label') {
                currentSelectedCount++;
            }
        });
        setIsPartialSelected(currentSelectedCount > 0 && currentSelectedCount < totalCheckbox)


    }, [options]);
    
    
    const onSelectionChange = (optionIndex: number, value: boolean) => {
        updateParentSelection(optionIndex, value);
    };

    const onResetClick = () => {
        onResetChange();
    };

    return (
        <div className="filter-item">
            <div className="filter-header-text">{displayName}</div>
            <header
                data-testid="drop-down-header"
                className={disable ? 'filter-header disable' : 'filter-header'}
                onClick={() => {
                    if(!disable) {
                        setToggleFilter(!toggleFilter);
                    }
                    
                }}>
                {selectedCount === 0 ? (
                    <span className="filter-placeholder">{placeHolderText}</span>
                ) : (
                    <div className="selected-count">
                        <span className="option-selected-count">{selectedLabel}</span>
                        <span
                            className="option-unselect-btn"
                            onClick={(event) => {
                                event.stopPropagation();
                                onResetClick();
                            }}>
                            X
                        </span>
                    </div>
                )}
                <i className="bi bi-caret-down-fill drop-icon"></i>
            </header>
            {!disable && toggleFilter ? (
                <div className="filter-options-container">
                    <ul>
                        <Form>
                            {options.map((option: TypeFilterOption, index: number) => {
                                return (
                                    <li
                                        className={getMultiSelectItemClass(option, index, isPartialSelected)}
                                        title={option.label}
                                        key={option.label}
                                        onClick={(e) => e.stopPropagation()}>
                                            { option.type !== 'label' ? <Form.Group
                                            className="check-box-holder"
                                            controlId={option.label}
                                            >
                                            <Form.Check
                                                checked={option.value}
                                                type="checkbox"
                                                title={option.label}
                                                label={option.label}
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    onSelectionChange(
                                                        index,
                                                        e.currentTarget.checked
                                                    );
                                                }}
                                            />
                                        </Form.Group> : option.label}
                                        
                                    </li>
                                );
                            })}
                        </Form>
                    </ul>
                    
                </div>
            ) : (
                ''
            )}
        </div>
    );
}

export default MultiSelectDropdown;
