import React, { useEffect, useState } from 'react';
import { getSystemNotification } from '../../../services/api.service';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store';
import allActions from '../../../store/actions';

function SystemNotification() {
    const dispatch = useDispatch();
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState<string[]>([]);

    const [siteName, profileName] = useSelector((state: RootState) => {
        return [state.user.siteName, state.user.profileName];
    });

    const updateSystemNotification = (showNotification) => {
        setShowNotification(showNotification);
        dispatch(allActions.userAction.updateSystemNotification(showNotification));
    }

    useEffect(() => {
        //After refresh if if local stoarage system-notification-off is true no need to show notification
        if (!siteName || localStorage.getItem('system-notification-off') === 'true') {
            return;
        }
        getSystemNotification(siteName, profileName)
            .then((response) => {
                if (response.messageOfDays?.length) {
                    updateSystemNotification(true);
                    const messages = response.messageOfDays.map(msgObj => msgObj.messageOfDay)
                    setNotificationMessage(messages);
                } else {
                    updateSystemNotification(false);
                    setNotificationMessage([])
                }
            })
            .catch(() => {
                updateSystemNotification(false);
                setNotificationMessage([]);
                console.log('No system notification');
            })
    }, [siteName, profileName])

    const hideNotification = () => {
        //storing this in local storage so that after page refresh we can use this flag to hide notification 
        localStorage.setItem('system-notification-off', 'true');
        updateSystemNotification(false)
    }

    if (!showNotification) {
        return <></>
    }
    return (
        <div className="system-notification">
            <div>{notificationMessage.map((message, index) => {
                return <span key={index}>{message}</span>
            })}</div>
            <div className='system-notification-close' onClick={hideNotification}>
                <span className="material-symbols-outlined">
                    close
                </span></div>
        </div>
    );
}

export default SystemNotification;
