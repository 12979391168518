const bankName = (
 
    state = { bank_Name:''},
    { type, payload }
  
) => {
    switch (type) {
     
        case 'BANK_NAME':
            return {
                ...state,
                bank_Name: payload,
            };
        default:
            return state;
    }
};



export default bankName;
