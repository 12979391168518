import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../common/ui/button/Button';
import Form from 'react-bootstrap/Form';
import SearchInput from '../../common/ui/searchInput/SearchInput';
import { RootState } from '../../../store/store';
import ShareSelectedUsers from './ShareSelectedUsers';
import { globalConstant, roles } from '../../../services/constant';
import { excludeLoggedInUser, getAlltenant, isValidGroupDescPattern } from './dashboardShareUtils';
import SearchList from './SearchList';
import utils, { checkUserInternal, getAlertDetails } from '../../../services/utils';
import { searchUser, shareDashboard, multiTenantshareHandler, getDataSources } from '../../../services/api.service';
import { TypeSearchUser, TypeServerPayloadTenant } from '../../../types/types';
import i18nLabels from '../../../services/i18n.service';
import allActions from '../../../store/actions';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import DatasetSelection from './DatasetSelection';
import TenantProfileSelection from './TenantProfileSelection';
import MultiTenantProfileSelection from './MultiTenantProfileSelection';
import MultiTenantDatasetSelection from './MultiTenantDatasetSelection';
import useAddAudit from '../../../hooks/useAddAudit';
import '../dashboard/dashboard.scss';

interface Props {
    workbookId: string;
    siteName: string;
    profileName: string;
    workbookName: string;
    reportType: string;
    onClose: () => void;
}


const getUserListById = (selectedUserList) => {
    return selectedUserList.map((user) => {
        return user.userId;
    });
};

const getUserNames = (selectedUserList) => {
    return selectedUserList.map((user) => {
        return user.userName;
    });
};

function DashboardShare({ workbookId, siteName, profileName, onClose, reportType, workbookName }: Props) {
    const [userList, setUserList] = useState<TypeSearchUser[]>([]);
    const [isCrossTenant, setIsCrossTenant] = useState(false);
    const [crossTenantEnabled, setCrossTenantEnabled] = useState(false);
    const [selectedView, setParentSelectedView] = useState('self');
    const [isMultipleTenant, setIsMultipleTenant] = useState(false);
    const [showDatasetsView, setShowDatasetsView] = useState(false);
    const [selectedSahringOptions, setSelectedSahringOptions] = useState('radio1');
    const [selectedUserList, setSelectedUserList] = useState<TypeSearchUser[]>([]);
    const [serverUserList, setServerUserList] = useState<TypeSearchUser[]>([]);
    const [selectedTenantList, setSelectedTenantList] = useState<TypeServerPayloadTenant[]>([]);
    const [currentTenant, setCurrentTenant] = useState(null);
    const [currentProfile, setCurrentProfile] = useState(null);
    const [inputSearchText, setSearchText] = useState('');
    const [sourceWorkbookHaveDataset, setSourceWorkbookHaveDataset] = useState(false);
    const [sourceDatasetLoading, setSourceDatasetLoading] = useState(true);
    const [multiTenantEnable, setMultiTenantEnable] = useState(true);
    const refModal = useRef<HTMLDivElement>(null);
    const [notifyEmail, setNotifyEmail] = useState(false);

    const dispatch = useDispatch();

    const profileRole = useSelector((state: RootState) => {
        return state.user.role;
    });
    const allProfile = useSelector((state: RootState) => {
        return state.user.allProfile;
    });
    const userEmail = useSelector((state: RootState) => {
        return state.user.userEmail;
    });
    const { contentUrl } = useSelector((state: RootState) => {
        return state.shareDashboard;
    });
    const {logShare} = useAddAudit();

    useEffect(() => {
        const allTenant = getAlltenant(allProfile, siteName, profileName);
        setCrossTenantEnabled(roles.MULTITENANT.indexOf(profileRole) > -1 && allTenant.length);
    }, [profileRole, allProfile]);

    useEffect(() => {
        if (profileRole) {
            setMultiTenantEnable(checkUserInternal(profileRole));
        }
    }, [profileRole]);

    useOnClickOutside(refModal, () => setUserList([]));
    useEffect(() => {
        const sourceDatasetPayload = [{ profiles: [{ workBookName: contentUrl }] }];
        
        if(reportType === globalConstant.reportType.dashboard) {
            dispatch(allActions.loaderAction.showLoader());
            getDataSources(siteName, profileName, sourceDatasetPayload)
            .then((response) => {
                setSourceWorkbookHaveDataset(response.tenantDataSets[0]?.profiles[0]?.dataSets?.length)
            })
            .catch((error) => {
                dispatch(allActions.alertAction.showAlert(getAlertDetails(error, 'danger')));
            })
            .finally(() =>{
                setSourceDatasetLoading(false);
                dispatch(allActions.loaderAction.hideLoader());
            });
        }else{
            setSourceDatasetLoading(false);
        }
        
    }, []);
    useEffect(() => {
        if (serverUserList?.length) {
            setUserList([...serverUserList]);
        }
    }, [serverUserList]);

    useEffect(() => {
        if (!isCrossTenant) {
            setCurrentTenant(null);
            setCurrentProfile(null);
        } else {
            setServerUserList([]);
            setSelectedUserList([]);
            setUserList([]);
        }
    }, [isCrossTenant]);

    const onMultiTenantSelectionChange = (multiTenant) => {
        setServerUserList([]);
        setSelectedUserList([]);
        setUserList([]);
        setSelectedTenantList(multiTenant);
    };

    const onSingleTenantSelectionChange = (multiTenant) => {
        setServerUserList([]);
        setSelectedUserList([]);
        setUserList([]);
        setCurrentTenant(multiTenant[0].siteName);
        setCurrentProfile(multiTenant[0].profiles[0].name);
        setSelectedTenantList(multiTenant);
    };

    const onNextHandler = () => {
        setShowDatasetsView(true);
    };

    const handleSharedResponse = (sharedPromise) => {
        const userArrayList = selectedUserList.reduce((acc: string[], ele) => {
            if (ele.isGroup) {
                ele.usersInGroup?.map((groupList) => {
                    if (groupList.userEmail) {
                        acc.push(groupList.userEmail);
                    }
                })
            }
            else {
                if (ele.userEmail) {
                    if (ele.userEmail) {
                        acc.push(ele.userEmail);
                    }
                }
            }
            return acc;
        }, [])
        
        let shareType = 'Same Tenant/Profile'
        if(isCrossTenant){
            shareType = "Different Tenant/Profile"
        }else if(isMultipleTenant) {
            shareType= "Multiple Tenant"
        }
        sharedPromise
            .then((response) => {
                dispatch(allActions.loaderAction.hideLoader());
                const notificationObj = {
                    message: response.serviceMessage.message,
                    code: 'Success',
                    type: 'success',
                    showCode: true
                }
                if(notifyEmail && userArrayList.length ){
                    logShare(workbookName, shareType, getUserNames(selectedUserList), reportType, true, {"sendNotification":"Y", "channels": [{"recipients":userArrayList, "type":"email"}]} )
                } else {
                    logShare(workbookName, shareType, getUserNames(selectedUserList), reportType, true)
                }
               
                dispatch(allActions.alertAction.showAlert(notificationObj));
                onClose();
            })
            .catch((response) => {
                if(notifyEmail && userArrayList.length){
                    logShare(workbookName, shareType, getUserNames(selectedUserList), reportType, false, {"sendNotification":"Y", "channels": [{"recipients":userArrayList, "type":"email"}]} )
                } else {
                    logShare(workbookName, shareType, getUserNames(selectedUserList), reportType, false )
                }
                dispatch(allActions.loaderAction.hideLoader());
                dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
            });
    };

    const onShareHandler = () => {
        dispatch(allActions.loaderAction.showLoader());
        handleSharedResponse(
            shareDashboard(siteName, profileName, workbookId, getUserListById(selectedUserList))
        );
    };
    const onMultiTenantShareHandler = (multiTenantPayload) => {
        dispatch(allActions.loaderAction.showLoader());
        handleSharedResponse(multiTenantshareHandler(siteName, profileName, multiTenantPayload));
    };
   
    const onUserSelect = (selectedUser) => {
        selectedUser.selected = !selectedUser.selected;
        setUserList([...userList]);
        const newList = serverUserList.filter((user) => {
            return user.selected;
        });
        setSelectedUserList(newList);
    };
    const onDeleteUser = (deletedUser) => {
        deletedUser.selected = false;

        setUserList([...userList]);

        const newList = serverUserList.filter((user) => {
            return user.selected;
        });

        setSelectedUserList(newList);
    };
    const onCloseSearch = () => {
        setUserList([]);
    };
    const onBack = () => {
        setShowDatasetsView(false);
    };
    const onSearchChange = (searchText) => {
        const filteredList = serverUserList.filter((userDetails) => {
            return (
                userDetails.userName.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) >
                    -1 ||
                userDetails.userEmail?.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) >
                    -1
            );
        });
        setSearchText(searchText);
        setUserList(searchText ? filteredList : [...serverUserList]);
    };

    const checkboxOnChangeHandler = () => {
        setNotifyEmail((prev) => !prev);
    }
    const onFieldFocus = () => {
        if (serverUserList.length) {
            onSearchChange(inputSearchText);
            return
        }
        const tenant = currentTenant || siteName;
        const profile = currentProfile || profileName;
        dispatch(allActions.loaderAction.showLoader());
        searchUser(tenant, profile)
            .then((response) => {
                const allusers = isCrossTenant
                    ? response.userList
                    : excludeLoggedInUser(response.userList, userEmail);
                const users: TypeSearchUser[] = [];
                allusers.forEach((user) => {

                    if ((user.isGroup && isValidGroupDescPattern(utils.getModifiedDescFromGroupName(user.groupName))) || !user.isGroup) {
                        const name = (user.groupName && utils.getModifiedDescFromGroupName(user.groupName)) || user.userName;
                        users.push({
                            userId: user.groupId || user.userId,
                            userEmail: user.userEmail,
                            userType: utils.maskBankName(user.groupType || user.userType),
                            userName: name,
                            usersInGroup: user.usersInGroup,
                            isGroup: user.isGroup
                        })
                    }
                });
                setServerUserList(users);
            })
            .catch((response) => {
                dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
            })
            .finally(() => {
                dispatch(allActions.loaderAction.hideLoader());
            });
    };
    if(sourceDatasetLoading) {
        return <></>
    }

    if(reportType !== globalConstant.reportType.signals && !sourceDatasetLoading && !sourceWorkbookHaveDataset) {
        return <>
        Unable to get Dashboard Datasource
        </>
    }
    
    return (
        <div className="sharing-module">
            <div ref={refModal}>
                {(crossTenantEnabled && reportType === globalConstant.reportType.dashboard) ? (
                    <div>
                        <div className="sharing-options-container">
                            Sharing Options
                            <div className="sharing-options">
                                <Form.Check
                                    inline
                                    defaultChecked={true}
                                    checked={selectedSahringOptions === 'radio1'}
                                    label="Same Tenant/Profile"
                                    name="group1"
                                    onClick={() => {
                                        setSelectedSahringOptions('radio1');
                                        setServerUserList([]);
                                        setSelectedUserList([]);
                                        setUserList([]);
                                        setSearchText('');
                                        setIsCrossTenant(false);
                                        setIsMultipleTenant(false);
                                    }}
                                    type="radio"
                                    id="radio1"
                                />
                                <Form.Check
                                    inline
                                    label="Different Tenant/Profile"
                                    checked={selectedSahringOptions === 'radio2'}
                                    name="group1"
                                    type="radio"
                                    onClick={() => {
                                        setSelectedSahringOptions('radio2');
                                        setServerUserList([]);
                                        setSelectedUserList([]);
                                        setUserList([]);
                                        setSearchText('');
                                        setIsCrossTenant(true);
                                        setIsMultipleTenant(false);
                                    }}
                                    id="radio2"
                                />
                                {multiTenantEnable && (
                                    <Form.Check
                                        inline
                                        label="Multiple Tenant"
                                        name="group1"
                                        checked={selectedSahringOptions === 'radio3'}
                                        type="radio"
                                        onClick={() => {
                                            setSelectedSahringOptions('radio3');
                                            setSearchText('');
                                            setIsCrossTenant(false);
                                            setIsMultipleTenant(true);
                                        }}
                                        id="radio3"
                                    />
                                )}
                            </div>
                        </div>
                        {isCrossTenant && (
                            <TenantProfileSelection
                                onProfileChange={onSingleTenantSelectionChange}
                                siteName={siteName}
                                profileName={profileName}></TenantProfileSelection>
                        )}
                        {isMultipleTenant && (
                            <MultiTenantProfileSelection
                                setParentSelectedView={setParentSelectedView}
                                onProfileChange={onMultiTenantSelectionChange}
                                siteName={siteName}
                                profileName={profileName}></MultiTenantProfileSelection>
                        )}
                    </div>
                ) : null}

                {!isMultipleTenant ? (
                    <SearchInput
                        onCloseSearch={onCloseSearch}
                        onFieldFocus={onFieldFocus}
                        searchText={inputSearchText}
                        onSearchChange={onSearchChange}></SearchInput>
                ) : null}
                {userList.length ? (
                    <div className="search-list-container">
                        <SearchList userList={userList} onUserSelect={onUserSelect}></SearchList>
                    </div>
                ) : null}
            </div>
            {selectedUserList.length ? (
                <div className="selected-user-container">
                    <ShareSelectedUsers
                        selectedUserList={selectedUserList}
                        onDeleteUser={onDeleteUser}></ShareSelectedUsers>
                </div>
            ) : null}
            {!selectedUserList.length && !isMultipleTenant ? (
                <div className="search-placeholder">
                    <div>
                        <span className="material-symbols-outlined">info</span>
                    </div>
                    <p>{i18nLabels.dashboard_share_instruction}</p>
                </div>
            ) : null}
            <div className='notify-flex'>
            {!(isCrossTenant || isMultipleTenant) && <Form.Check className="notify-check" type="checkbox" checked={notifyEmail} 
              onChange={checkboxOnChangeHandler} value="selectAll" label={i18nLabels.notify_user}></Form.Check>}

            <div className="sharing-btns">
                <Button type="secondry" onClick={onClose}>
                    Close
                </Button>
                {!(isCrossTenant || isMultipleTenant) ? (
                    <Button disabled={selectedUserList.length === 0} onClick={onShareHandler}>
                        Share
                    </Button>
                ) : (
                    <Button
                        disabled={
                            (isMultipleTenant && !selectedTenantList.length) ||
                            (!isMultipleTenant && selectedUserList.length === 0)
                        }
                        onClick={onNextHandler}>
                        Next
                    </Button>
                )}
            </div>
            </div>
            {showDatasetsView && (
                <div className="dataset-container">
                    {isMultipleTenant && (
                        <MultiTenantDatasetSelection
                            onBack={onBack}
                            onShareHandler={onMultiTenantShareHandler}
                            selectedTenantList={selectedTenantList}
                            tenantId={siteName}
                            selectedView={selectedView}
                            workbookId={workbookId}
                            profileId={profileName}></MultiTenantDatasetSelection>
                    )}
                    {!isMultipleTenant && (
                        <DatasetSelection
                            onBack={onBack}
                            onShareHandler={onMultiTenantShareHandler}
                            selectedTenantList={selectedTenantList}
                            tenantId={siteName}
                            workbookId={workbookId}
                            selectedUserList={selectedUserList}
                            profileId={profileName}></DatasetSelection>
                    )}
                </div>
            )}
        </div>
    );
}
export default DashboardShare;
