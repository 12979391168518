import React, { useState, useEffect, useRef } from 'react';
import CloseButton from 'react-bootstrap/CloseButton';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import allActions from '../../../store/actions';
import ViewTableau from '../../common/tableau/ViewTableau';
import { addFavorite, getSignalDate, getTicket } from '../../../services/api.service';
import Button from '../../common/ui/button/Button';
import { getVizViewURL, getAlertDetails, checkUserInternal, dateFormat } from '../../../services/utils';
import VizFilterContainer from '../dimensionFilters/VizFilterContainer';
import 'bootstrap/dist/css/bootstrap.min.css';
import type { RootState } from '../../../store/store';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { globalConstant, roles } from '../../../services/constant';
import { getWorkbookId, getUserId } from '../../../services/user.service';
import useGetFilters from '../../../hooks/useGetFilters';
import DashboardDownload from './DashboardDownload';
import { auditEvents } from '../../../services/auditActions';
import useAddAudit from '../../../hooks/useAddAudit';
import i18nLabels from '../../../services/i18n.service';

interface Props {
    reportType: string;
}

/**
 * React container component for embedding workbook in view mode
 * @return {DOM} Returm React DOM
 */
function ViewDashboard({reportType}: Props) {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userState = useSelector((reduxState: RootState) => {
        return reduxState.user;
    });
    const { appliedFilters, totalSelectedFilters } = useSelector((reduxState: RootState) => {
        return reduxState.dimensionFilters;
    });

    const [siteName, profileId, profileRole, reloadDashboard] = useSelector((state: RootState) => {
        return [state.user.siteName, state.user.profileName, state.user.role, state.user.reloadDashboard];
    });

    const [newUrl, setNewURL] = useState('');
    const [isInternalUser, setInternalUser] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [disableAddFavorite, setDisableAddFavorite] = useState(false);
    const [exportType, setExportType] = useState<string | null>(null);
    const [isVizRendered, setIsVizRendered] = useState(false);
    const [currentTabSheet, setCurrentTabSheet] = useState('');
    const [signalCreationDate, setSignalCreationDate] = useState('');
    const { dimensionFilters, selectedWorkBookId, authorName : { ownerName, ownerCompany} } = useGetFilters(
        null,
        params?.workbook,
        params?.dashboard,
        reportType
    );
    const {addAuditEvent} = useAddAudit();
    const refModal = useRef<HTMLDivElement>(null);

    const onShareHandler = () => {
        dispatch(
            allActions.shareDashboardAction.showSharePopup({
                workbookId: getWorkbookId(),
                workbookName: userState.currentWorkbookName,
                contentUrl: params.workbook,
                reportType
            })
        );
    };

    useOnClickOutside(refModal, () => setShowFilters(false));

    useEffect(() => {
        dispatch(allActions.dimensionFiltersAction.addServerFilters(dimensionFilters));
    }, [dimensionFilters]);

    useEffect(() =>{
        setInternalUser(checkUserInternal(profileRole));
    }, [profileRole])

    useEffect(() => {
        if(reloadDashboard) {
            dispatch(allActions.userAction.reloadDashboard(false));
            navigate('/');
        }
        
    }, [reloadDashboard]);

    useEffect(() => {
        if (siteName) {
            if(params?.siteName && params?.siteName !== siteName) {
                navigate('/');
                const error = {
                    typr: 'danger',
                    message: i18nLabels.workbook_not_preset
                }
                dispatch(allActions.alertAction.showAlert(error));
            }else{
                getTicket(siteName)
                .then((reponse) => {
                    const url = getVizViewURL(
                        reponse.ticket,
                        params.workbook,
                        params.dashboard,
                        params.siteName
                    );
                    setNewURL(url);
                })
                .catch((response) => {
                    dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
                });
            }
           
        }
    }, [siteName]);

    useEffect(() => {
        const { favorite } = userState;
        const workbookId = getWorkbookId() || selectedWorkBookId;

        if (favorite && workbookId && favorite.workBookId === workbookId) {
            setDisableAddFavorite(true);
        }
        if(reportType === globalConstant.reportType.signals && workbookId) {
            getSignalDate(siteName, profileId, 0, 10, workbookId)
            .then((response) => {
                if(!response){
                    response = {
                        signals: {
                            workBooks:[]
                        }
                    }
                }
                setSignalCreationDate(dateFormat(response.signals.workBooks[0].createdDate, 'MMMM yyyy'))
            })
        .catch(() => {
            console.log('')
        });
        }
        
    }, [selectedWorkBookId, userState]);

    const gotoEdit = () => {
        navigate(`/${reportType}/edit/${params.siteName}/${params.workbook}/${currentTabSheet || params.dashboard}`);
    };

    const toggleFilter = () => {
        if(isVizRendered){
            setShowFilters(!showFilters);
        }
        
    };

    const resetExportType = () => {
        setExportType(null);
    };

    const downloadOptionClickHandler = (downloadType) => {
        setExportType(downloadType);
    };

    const updateVizRenderedStatus = (isRendered: boolean) => {
        setIsVizRendered(isRendered);
    };

    const onPinToHomePage = () => {
        const { favorite } = userState;
        if (favorite && favorite.workBookId === getWorkbookId()) {
            return;
        }
        dispatch(allActions.loaderAction.showLoader());
        addFavorite(siteName, profileId, getUserId(), getWorkbookId())
            .then((response) => {
                setDisableAddFavorite(true);
                try{
                    const logsDetails = {
                        'Dashboard': userState.currentWorkbookName,
                    }
                    addAuditEvent(auditEvents.dashboard.pinned, `${userState.currentWorkbookName} pinned`, 'SUCCESS', logsDetails)
                }catch(err) {
                    console.log(err)
                }
                dispatch(allActions.userAction.addFavorite(response.favouritedashboard));
                dispatch(allActions.alertAction.showAlert(getAlertDetails(response.serviceMessage, 'success')));
            })
            .catch((response) => {
                dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
            })
            .finally(() =>{
                dispatch(allActions.loaderAction.hideLoader());
            })
    };

    return (
        <>
            <div id="outer-wrapper" className="square">
                <Row className="worksheet-header mb-2 pt-0">
                    <Col sm={6}>
                        <div className="view-dashboard-header">
                            <h4
                                className="left view-header-title fs-16"
                                title={userState.currentWorkbookName}>
                                {isVizRendered && userState.currentWorkbookName}
                            </h4>
                            {isVizRendered && reportType !== globalConstant.reportType.signals && <h4
                                className="left view-header-title author-details fs-12"
                            >
                                <span className="btn-divider"></span>
                                <span className="created-by">{i18nLabels.created_by}</span >
                                <span className="owner-name">{ownerName || 'NA'}</span>
                                {ownerName && ownerCompany && <span className="bank-name">{`, ${ownerCompany}`}</span>}</h4>}
                            {reportType === globalConstant.reportType.signals && <h4
                                className="left view-header-title fs-12"
                                >
                                {signalCreationDate}
                            </h4>}
                            
                            
                        </div>
                    </Col>
                    <Col sm={6} className="right">
                        <div className="dashboard-right-btn-group">
                        {reportType !== globalConstant.reportType.signals && roles.FAVOURITE.indexOf(profileRole) > -1 && (
                                <><div className="pin-btn">
                                    <Button
                                        onClick={onPinToHomePage}
                                        type="icon-text"
                                        disabled={!isVizRendered || disableAddFavorite}>
                                        <span className="material-symbols-outlined pin-icon">
                                            push_pin
                                        </span>
                                        <span className="btn-text action-btn-text pin-text">
                                            {disableAddFavorite
                                                ? 'Pinned to Homepage'
                                                : 'Pin to Homepage'}
                                        </span>
                                    </Button>
                                </div>
                                <span className="btn-divider"></span> </>
                            )}
                            {(reportType !== globalConstant.reportType.signals || isInternalUser) && <><Button
                                disabled={!isVizRendered}
                                onClick={onShareHandler}
                                type="icon-text">
                                <span className="material-symbols-outlined">share</span>
                                <span className="btn-text action-btn-text">{i18nLabels.dashboard_share}</span>
                            </Button>

                            {roles.EDIT.indexOf(profileRole) > -1 ? (
                                <>
                                    <span className="btn-divider"></span>
                                    <Button onClick={gotoEdit} type="icon-text">
                                        <span className="material-symbols-outlined">edit</span>
                                        <span className="btn-text action-btn-text">{i18nLabels.dashboard_edit}</span>
                                    </Button>
                                </>
                            ) : (
                                ''
                            )}
                            <span className="btn-divider"></span> </>}
                            <DashboardDownload
                                isVizRendered={isVizRendered}
                                setExportType={downloadOptionClickHandler}></DashboardDownload>
                                {reportType === globalConstant.reportType.dashboard && <> <span className="btn-divider"></span>
                            <Button
                                type="secondry"
                                disabled={!isVizRendered}
                                onClick={toggleFilter}>
                                <span
                                    className={
                                        totalSelectedFilters ? 'selected-filter-btn' : 'filter-text'
                                    }>
                                    {i18nLabels.dashboard_filter_title}{' '}
                                    {totalSelectedFilters ? `(${totalSelectedFilters})` : ''}
                                </span>
                            </Button></>}
                           
                        </div>
                    </Col>
                </Row>
                <Row id="list-dashboard" className="view-dashboard rounded-3 mx-2">
                    {reportType === globalConstant.reportType.dashboard && showFilters && (
                        <>
                            <div className="argus-overlay"></div>
                            <div ref={refModal} className="filter-holder">
                                <span className="close-right">
                                    <CloseButton onClick={toggleFilter} />
                                </span>
                                <VizFilterContainer />
                            </div>
                        </>
                    )}
                    <Col sm={12}>
                        <ViewTableau
                            setDashboardIsVizRendered={updateVizRenderedStatus}
                            setExportType={resetExportType}
                            exportType={exportType}
                            showFilters={showFilters}
                            selectedFilters={appliedFilters}
                            reportType= {reportType}
                            screenType={'VIEW'}
                            setCurrentTabSheet={setCurrentTabSheet}
                            url={newUrl}></ViewTableau>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ViewDashboard;
