import React, { useState } from 'react';
import {Row, Col, Container } from 'react-bootstrap';
import './SupportPage.scss';
import { TypeContactUs, TypegeneralQueriesContactInfo } from '../../../types/types';
import UseSupportPage from './useSupportPage';
import i18nLabels from '../../../services/i18n.service';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function ContactUs() {
    const [view, setView] = useState<TypeContactUs | null>(null);

    const getView = (response) => {
        setView(response.contacts);
    };
    UseSupportPage('contacts', getView);

    const getMessage = (message: string) => (<Tooltip id="tooltip" > <div style={{width: 'max-content'}}>{message} </div></Tooltip>);
    const screenWidth = window.innerWidth;

    const renderEmail = (email) => {
        if (email && email.length > 9 && screenWidth > 0 && screenWidth < 770) {
            return (
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={getMessage(email)}
                >
                    <div>{email.slice(0, 8) + '...'}</div>
                </OverlayTrigger>
            );
        } else if (email && email.length > 15 && screenWidth > 770 && screenWidth < 1100) {
            return (<OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={getMessage(email)}
            >
                <div>{email.slice(0, 14) + '...'}</div>
            </OverlayTrigger>
            );
        } else if (email && email.length > 20 && screenWidth > 1100 && screenWidth < 1400) {
            return (<OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={getMessage(email)}
            >
                <div>{email.slice(0, 19) + '...'}</div>
            </OverlayTrigger>
            );
        } else if (email && email.length > 26 && screenWidth > 1400) {
            return (<OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={getMessage(email)}
            >
                <div>{email.slice(0, 26) + '...'}</div>
            </OverlayTrigger>
            );
        } else {
            return (<div> {email}</div>);
        }
    };

    return (
        <Container fluid-xxl id="page-container" className="dashboard-container container-xxl">
            <Row>
                <Col md={12} className="contact-us">
                    <h2 className="text-left support-title">{i18nLabels.contact_info}</h2>
                    <Row className="contact-us-panel">
                        <Col md={5} className="contact-left-panel">
                            {' '}
                            <h3 className="fs-23">{i18nLabels.contact_general_queries}</h3>
                            <p>
                                {i18nLabels.contact_general_query_heading}
                            </p>
                            <ul>
                               <li>{i18nLabels.contact_general_query_list1}</li>
                               <li>{i18nLabels.contact_general_query_list2}</li>
                               <li>{i18nLabels.contact_general_query_list3}</li>
                               <li>{i18nLabels.contact_general_query_list4}</li>
                               <li>{i18nLabels.contact_general_query_list5}</li>
                               <li>{i18nLabels.contact_general_query_list6}</li>
                               <li>{i18nLabels.contact_general_query_list7}</li>
                            </ul>
                        </Col>
                        <Col md={6} className="ms-5 ps-5">
                            {view?.generalQueriesContactInfo.map != null &&
                                view.generalQueriesContactInfo.map(
                                    (views: TypegeneralQueriesContactInfo) => {
                                        return (
                                            <div
                                                key={views?.name}
                                                className="row mb-3 contact-information">
                                                <h3 className="mb-4 contact-person">
                                                    {views?.name}
                                                    <span className="ps-3">{views?.role}</span>
                                                </h3>

                                                <Col md={5} className="mt-0">
                                                    <span className="material-icons-outlined email-icon">
                                                        {i18nLabels.contact_mail_label}
                                                    </span>
                                                    <div className="ps-5 ms-2">
                                                        <h6 className="fs-12 mb-0 w-400 grey-color">
                                                            {i18nLabels.contact_email_address_label}
                                                        </h6>
                                                        <p className="w-500 dark-grey mb-0">
                                                            <b>
                                                            {views?.email ? renderEmail(views.email): ""}
                                                            </b>
                                                        </p>
                                                    </div>
                                                </Col>

                                                <Col md={7} className="">
                                                    <span className="material-icons-outlined phone-icon">
                                                        {i18nLabels.contact_phone}
                                                    </span>
                                                    <div className="ps-5 ms-2">
                                                        <h6 className="mb-0 w-400 grey-color">
                                                            {i18nLabels.contact_phone_number}
                                                        </h6>
                                                        <p className="mb-0 fs-14 w-400 grey-color">
                                                        <span>{i18nLabels.contact_us}{' '}</span>
                                                           
                                                                <b> {views?.phoneNumber}</b>
                                                           
                                                            <span className="ps-2 pe-2">|</span>
                                                            <span>{i18nLabels.contact_global}</span>
                                                          
                                                                <b> {views?.globalPhoneNumber}</b>
                                                            
                                                        </p>
                                                    </div>
                                                </Col>
                                            </div>
                                        );
                                    }
                                )}
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col md={12} className="contact-us">
                    <Row className="contact-us-panel">
                        <Col md={5} className="contact-left-panel">
                            <h3 className="fs-23">{i18nLabels.contact_login_access_label}</h3>
                           <p> {i18nLabels.contact_technical_queries}</p>
                           <ul>
                               <li>{i18nLabels.contact_technical_query_list1}</li>
                               <li>{i18nLabels.contact_technical_query_list2}</li>
                               
                            </ul>
                        </Col>
                        <Col md={6} className="ms-5 ps-5 mt-3">
                            <Row>
                                {view?.techQueriesContactInfo[0].email != null ? (
                                    <Col md={5}>
                                        <span className="material-icons-outlined email-icon me-3">
                                            {i18nLabels.contact_mail_label}
                                        </span>
                                        <div className="ps-5">
                                            <h6 className="fs-12 mb-0 w-400 grey-color">
                                                {i18nLabels.contact_email_address_label}
                                            </h6>
                                            <p className="fs-12 w-500 dark-grey mb-0">
                                                <b>
                                                    {view?.techQueriesContactInfo[0]?.email ? renderEmail(view.techQueriesContactInfo[0].email): ""}
                                                </b>
                                            </p>
                                            <p className="fs-12 mb-0 w-400 grey-color ps-2">
                                                {i18nLabels.contact_respond_queries}
                                                </p>
                                        </div>
                                    </Col>
                                ) : (
                                    <p></p>
                                )}

                                {
                                view?.techQueriesContactInfo[0].phoneNumber != null ? (
                                    <Col md={7}>
                                        <span className="material-icons-outlined phone-icon">
                                            {i18nLabels.contact_phone}
                                        </span>
                                        <div className="ps-5 ms-2">
                                            <h6 className="fs-12 mb-0 w-400 grey-color">
                                                {i18nLabels.contact_phone_number}
                                            </h6>
                                            <p className="fs-12 mb-0 w-400 grey-color">
                                            <span>  {i18nLabels.contact_us}</span>
                                              
                                                    <b>
                                                        {' '}
                                                        {
                                                            view?.techQueriesContactInfo[0]
                                                                .phoneNumber
                                                        }
                                                    </b>
                                                
                                                <span className="fs-12 ps-3">
                                                    {i18nLabels.contact_available_hours}
                                                </span>
                                            </p>
                                            <p className="mb-0 fs-12 w-400 grey-color pb-2">
                                            <span>{i18nLabels.contact_global} </span>
                                               
                                                    <b>
                                                        {' '}
                                                        {
                                                            view?.techQueriesContactInfo[0]
                                                                .globalPhoneNumber
                                                        }
                                                    </b>
                                               
                                                <span className="fs-12 ps-3">
                                                    {' '}
                                                    {i18nLabels.contact_available_hours}
                                                </span>
                                            </p>
                                        </div>
                                    </Col>
                                ) : (
                                    <p></p>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}
export default ContactUs;
