import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useSelector, useDispatch } from 'react-redux';
import allActions from '../../../store/actions';
import { getAlertDetails } from '../../../services/utils';
import type { RootState } from '../../../store/store';
import utils from '../../../services/utils'
import { getDataSources, checkDatasourceAlligned } from '../../../services/api.service';
import { TypeServerPayloadTenant, TypeTenantDatasets } from '../../../types/types';
import Button from '../../common/ui/button/Button';
import DatasetAllignedMessage from './DatasetAllignedMessage';
import { addSourceWorkbookInProfile, getDefaultDatasetIndex } from './dashboardShareUtils';

const getDestinationSites = (tenantDatasets, selectedView) => {
    return tenantDatasets.map((site) => {
        return {
            tenantId: site.tenantId,
            siteName: site.siteName,
            profiles: site.profiles.map((profile) =>{
                return {
                    id: profile.id,
                    name: profile.name,
                    templateWorkbookName: profile.selecteDataset.workBookName,
                    templateWorkbookId: profile.selecteDataset.workBookId,
                    workBookName: profile.publishWorkbookName,
                    compareMessage: '',
                    userList: [selectedView]
                }
            })
        }
    })
}

interface Props {
    tenantId: string;
    profileId: string;
    workbookId: string;
    selectedView:string;
    selectedTenantList: TypeServerPayloadTenant[];
    onBack: () => void;
    onShareHandler: (multiTenantPayload:any) => void;
}

function MultiTenantDatasetSelection({
    tenantId,
    profileId,
    workbookId,
    selectedTenantList,
    onBack,
    onShareHandler,
    selectedView
}: Props) {
    const [tenantDatasets, setTenantDatasets] = useState<Array<TypeTenantDatasets>>([]);
    const [sourcedDataset, setSourcedDataset] = useState('');
    const [isDataSourceLoading, setIsDataSourceLoading] = useState(true);
    const dispatch = useDispatch();
    const { contentUrl } = useSelector((state: RootState) => {
        return state.shareDashboard;
    });

    const [siteName, profileName] = useSelector((state: RootState) => {
        return [state.user.siteName, state.user.profileName];
    });

    //Get data source for all tenants
    useEffect(() => {
        if(selectedTenantList) {
            const selectedTenants = selectedTenantList.filter((tenanat) => {
                return tenanat.siteName !== 'Select All Tenant'
            })
            dispatch(allActions.loaderAction.showLoader());
            getDataSources(tenantId, profileId, addSourceWorkbookInProfile(selectedTenants, contentUrl))
            // getDataSources(tenantId, profileId, selectedTenants)
                .then((response) => {
                    response.tenantDataSets.forEach((element) => {
                        element.profiles.forEach((profile) => {
                            const defaultDatasetIndex = getDefaultDatasetIndex(profile.dataSets) || 0;
                            profile.selecteDataset = profile.dataSets[defaultDatasetIndex];
                            profile.selecteDatasetIndex = defaultDatasetIndex;
                        })
                    });
                    setTenantDatasets(response.tenantDataSets);
                    getSourceDataset();
                })
                .catch((error) => {
                    dispatch(allActions.alertAction.showAlert(getAlertDetails(error, 'danger')));
                })
                .finally(() => {
                    setIsDataSourceLoading(false)
                    dispatch(allActions.loaderAction.hideLoader());
                });
        }
        
    }, []);

    const getPublishPayload = (alldatasets) => {
        return {
            publish: {
                sourceSite: siteName,
                sourceProfile: profileName,
                sourceDatasource: sourcedDataset,
                sourceWorkBookId: workbookId,
                sourceWorkBookName: contentUrl,
                destinationSites:  getDestinationSites(alldatasets, selectedView)
            }
        };
    }

    const mapDatasets = (alldatasets) => {
        dispatch(allActions.loaderAction.showLoader());
        checkDatasourceAlligned(tenantId, profileId, getPublishPayload(alldatasets))
                .then((response) => {
                    response.publish.destinationSites.forEach((element, index) => {
                        element.profiles.forEach((profile, profileIndex) => {
                            tenantDatasets[index].profiles[profileIndex].compareMessage = profile.compareMessage
                            tenantDatasets[index].profiles[profileIndex].publishWorkbookName = profile.workBookName
                        })
                    });
                    setTenantDatasets([...tenantDatasets]);
                })
                .catch((error) => {
                    dispatch(allActions.alertAction.showAlert(getAlertDetails(error, 'danger')));
                })
                .finally(() =>{
                    dispatch(allActions.loaderAction.hideLoader());
                })
    }

    useEffect(() => {
        if(sourcedDataset){
            mapDatasets(tenantDatasets);
        }

    }, [sourcedDataset])

    const getSourceDataset = () => {
        const sourceDatasetPayload = [{ profiles: [{ workBookName: contentUrl }] }];
            getDataSources(tenantId, profileId, sourceDatasetPayload)
                .then((response) => {
                    setSourcedDataset(response.tenantDataSets[0]?.profiles[0]?.dataSets[0]?.name);
                   
                })
                .catch((error) => {
                    dispatch(allActions.alertAction.showAlert(getAlertDetails(error, 'danger')));
                });
    }

    const deleteProfile = (tenantIndex, profileIndex) => {
        const copySelectedDataset = [...tenantDatasets];
        copySelectedDataset[tenantIndex].profiles.splice(profileIndex, 1);
        if(copySelectedDataset[tenantIndex].profiles.length === 0) {
            copySelectedDataset.splice(tenantIndex, 1);
        }
        setTenantDatasets(copySelectedDataset);
    }

    const updateDatasetSelctions = (value, tenantIndex, profileIndex) => {
        const copySelectedDataset = [...tenantDatasets];
        copySelectedDataset[tenantIndex].profiles[profileIndex].selecteDataset =
        copySelectedDataset[tenantIndex].profiles[profileIndex].dataSets[value];
        copySelectedDataset[tenantIndex].profiles[profileIndex].selecteDatasetIndex = value;
        setTenantDatasets(copySelectedDataset);
        mapDatasets(copySelectedDataset);
    };

    const shareBtnHandler = () =>{
        onShareHandler(getPublishPayload(tenantDatasets));
    }

    return (
        <div className="dataset-selection-container">
            <div className="dataset-section-header">
                Select destination dataset from the list for respective tenants
            </div>

            <div className="datset-holder">
                {!isDataSourceLoading && !tenantDatasets.length ? 'No Data Source' : null}
                {tenantDatasets.map((tenant, tenantIndex) => {
                    return (
                        <div key={tenant.siteName}>
                            <span className="tenant-title">{utils.maskBankName(tenant.siteName)}</span>
                            {tenant.profiles.map((profile, profileIndex) => {
                                return (
                                    <div key={profile.name}>
                                        <div className="profile-header">
                                            <span className="profile-title">{profile.name}</span>
                                            {tenantDatasets.length > 1 ? <span className="material-symbols-outlined user-action delete-btn"
                                            onClick={() => {deleteProfile(tenantIndex, profileIndex)}}>
                                                delete
                                            </span> : null}
                                            
                                        </div>
                                        <div className="datset-bounderies">
                                            {profile.compareMessage && <div className="dataset-instructions">
                                                <DatasetAllignedMessage message={profile.compareMessage} isSuccess={profile.datasetAlligned}></DatasetAllignedMessage>
                                            </div>}
                                            
                                            <div>
                                                <Form.Group className="dataset-source">
                                                    <Form.Label>Origin Dataset</Form.Label>
                                                    <Form.Control className='disable-field' value={sourcedDataset} readOnly />

                                                    <Form.Label>Destination dataset</Form.Label>
                                                    <Form.Select
                                                        value={profile.selecteDatasetIndex}
                                                        size="sm"
                                                        onChange={(event) => {
                                                            updateDatasetSelctions(
                                                                event.target.value,
                                                                tenantIndex,
                                                                profileIndex
                                                            );
                                                        }}>
                                                        {profile.dataSets.map((dataset, index) => {
                                                            return (
                                                                <option
                                                                    key={dataset.name}
                                                                    value={index}>
                                                                    {dataset.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <div className='tenant-divider'></div>
                        </div>
                        
                    );
                })}
            </div>
            <div className="dataset-btn-holder">
                <Button type="secondry" onClick={onBack}>
                    Back
                </Button>
                <Button disabled={!tenantDatasets.length} onClick={shareBtnHandler}>Share</Button>
            </div>
        </div>
    );
}

export default MultiTenantDatasetSelection;
