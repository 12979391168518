import { useSelector } from 'react-redux';
import { addAudit } from '../services/api.service';
import { RootState } from '../store/store';
import { auditEvents } from '../services/auditActions';
import { errorCode, globalConstant } from '../services/constant';
import { checkUserInternal } from '../services/utils';

const useAddAudit = () => {
    const { currentProfile, tenantId,tenantIds } = useSelector((state: RootState) => {
        return state.audit;
    });

    const { userEmail, userId, siteName, profileName, role } = useSelector((state: RootState) => {
        return state.user;
    });

    const { given_name, family_name } = useSelector((state: RootState) => {
        return state.userProfile;
    });

    const logShare = (workbookName, sharingType, userList, reportType,  status, notifyObject={}) => {
        const formattedUsers = userList.join(" | ")
        if (process.env.REACT_APP_AUDIT === "false") {
            return;
        }
        const statusLabel = status ? 'SUCCESS' : 'FAILED';
        const message = `${workbookName} shared`
        
        if(reportType === globalConstant.reportType.signals){
            const logDetails ={
                "Signal Finder": workbookName,
                "Sharing option type": sharingType,
                "To User or Group": formattedUsers
            }
            addAuditEvent(auditEvents.signal.shared, message, statusLabel, logDetails, notifyObject)
        } else{
            const logDetails = {
                "Dashboard": workbookName,
                "Sharing option type": sharingType,
                "To User or Group": formattedUsers
            }
            addAuditEvent(auditEvents.dashboard.shared, message, statusLabel, logDetails, notifyObject)
        }
        
    }

    const logApiFailedEvent = (apiResponse, apiName) => {
        if (process.env.REACT_APP_AUDIT === "false") {
            return;
        }
        let reasonMessage;
        if (apiResponse.response.status === 504) {
            reasonMessage = errorCode.apiTimeout;
        } else {
            reasonMessage = apiResponse?.response?.data?.serviceMessage || apiResponse
        }
        const additionalLogs = {
            'Failure Reason': reasonMessage.message || 'Something went wrong',
            'Api Name': apiName 
        }
        addAuditEvent(auditEvents.failure.apiFailure, `${apiName} api failed`, 'FAILED', additionalLogs)
        
    }

    const logCreateDashboard = (workbookName, reportType,  templateName, status) => {
        if (process.env.REACT_APP_AUDIT === "false") {
            return;
        }
        const eventComment = status === 'SUCCESS' ? `${workbookName} created ` : 'Creation failed';
        
        if(reportType === globalConstant.reportType.signals){
            const logsData = {
                'SF Name': workbookName || undefined
            };
            addAuditEvent(auditEvents.signal.create, eventComment, status, logsData)
        } else{
            const logsData = {
                "Dashboard": workbookName || undefined,
                "Template": templateName || undefined
            }
            addAuditEvent(auditEvents.dashboard.create, eventComment, status, logsData)
        }
        
    }

    const logUpdateDashboard = (workbookName, reportType,  templateName, status) => {
        if (process.env.REACT_APP_AUDIT === "false") {
            return;
        }
        const eventComment = status === 'SUCCESS' ? `${workbookName} updated ` : `${workbookName} update failed`;
        
        if(reportType === globalConstant.reportType.signals){
            const logsData = {
                'SF Name': workbookName || 'NA'
            };
            addAuditEvent(auditEvents.signal.update, eventComment, status, logsData)
        } else{
            const logsData = {
                "Dashboard": workbookName || 'NA',
                "Template": templateName || 'NA'
            }
            addAuditEvent(auditEvents.dashboard.edit, eventComment, status, logsData)
        }
        
    }

    const addAuditEvent = (event, message, status = 'SUCCESS', additionDetails = {}, addLogs={}) => {
        if (process.env.REACT_APP_AUDIT === "false") {
            return;
        }
        let log = {
            tenantId: tenantIds.get(siteName),
            product: 'Argus Performance Insights',
            eventGroupingId: event.eventGroupingId,
            userAction: event.userAction,
            eventType: event.eventType,
            eventName: event.eventName,
            status: status,
            profile: currentProfile,
            principalId: userEmail,
            principalName: `${given_name} ${family_name}`,
            eventText: message,
            additionalData: {
                'User ID': userId,
                'Current Profile': `${siteName}/${profileName}`
            }
        };
        if (additionDetails) {
            log.additionalData = { ...log.additionalData, ...additionDetails }
        }
        if (addLogs) {
            log = { ...log, ...addLogs }
        }
        if(role){
            const type = checkUserInternal(role)? "internal": "external";
            log['userType'] = type;
        }
        return addAudit(log)
            .then(() => {
                console.log('Log Success')
            })
            .catch(() => {
                console.log('Log Failed')
            })
    };

    return { addAuditEvent, logShare, logCreateDashboard, logUpdateDashboard, logApiFailedEvent };
};

export default useAddAudit;
