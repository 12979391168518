import { Container, Row,DropdownButton,Dropdown} from "react-bootstrap";
import Audit from "./Audit";
import React,{useEffect} from "react";
import "./Audit.scss";
import "../../../../App.scss";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../../../store/actions";
import {RootState} from  "../../../../store/store";
import { useNavigate } from "react-router-dom";
import { auditRoles } from "../../../../services/constant";
const AuditHome = () => {
    
    const [disableDownload,currentRole] = useSelector((state:RootState) => {
        return [state.audit.disableDownload,state.audit.currentRole];
    })
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleDownload = (type) => {
        dispatch(allActions.auditAction.addDownloadType(type));
    }
   useEffect(() => {

if(currentRole && !auditRoles.includes(currentRole.split("_")[1])) {    
    navigate('/');
}
   },[currentRole])
   

    return (
    <>
    <div className="audit">
    <Container  fluid style={{marginLeft:'2%',width:'96.6%',marginTop:'67px'}}>
         <Row >
                    <div className="d-flex justify-content-between">
                        <div className="audit-page-title  pt-1">User Activity</div>
                        {process.env.REACT_APP_AUDIT_DOWNLOAD === "true" &&
                        <div className="ms-5 ps-5">
                            <DropdownButton onSelect={handleDownload} disabled={disableDownload}
                                title="Download" className={`justify-content-between audit-page-download ${!disableDownload ? "audit-page-download-enable" : "audit-page-download-disable "}`} variant={!disableDownload ? "outline-warning" : "outline-secondary"} id="dropdown-basic">
                                <Dropdown.Item eventKey="All" id="All" className="audit-page-download-item">All Logs</Dropdown.Item>
                                <Dropdown.Item eventKey="Current" id="Current" className="audit-page-download-item">Current Page</Dropdown.Item>
                        

                            </DropdownButton></div>}
                    </div>
                </Row>
            </Container>
            <Container fluid className="  audit-page">
                <Audit />
            </Container>
            </div>
        </>
    )
}

export default AuditHome;
