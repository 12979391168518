import React, { useEffect } from 'react';
import './unsubscribe.scss';
import i18nLabels from '../../../services/i18n.service';
import { app_config } from '../../../services/constant';
import allActions from '../../../store/actions';
import { useDispatch } from 'react-redux';
import { unsubscribe } from '../../../services/api.service';
import Argus_Logo from '../../../assets/images/logo.svg';
import Button from '../../common/ui/button/Button';

function UnsubscribePage() {
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id')

    useEffect(() => {
        if (id) {
            dispatch(allActions.loaderAction.showLoader());
            unsubscribe(encodeURIComponent(id))
                .then((response) => {
                    console.log("Unsubscribe success response: " + response)
                })
                .catch((response) => {
                    console.log("Unsubscribe error response: " + response)
                })
                .finally(() => {
                    dispatch(allActions.loaderAction.hideLoader());
                });
        }
    }, []);

    const onBackToHome = () => {
        window.location.href = app_config.REDIRECT_SIGN_IN ?? '';
    };

    return (
        <div className="unsubscribe-holder">
            <div className="unsubscribe-content">
                <div className="unsubscribe-header">
                    <img src={Argus_Logo} alt="Argus" className="logocss" />
                </div>
                <div className="unsubscribe-text">{i18nLabels.unsubscribe_header}</div>
                <div className="unsubscribe-body">{i18nLabels.unsubscribe_body1}</div>
                <div className="unsubscribe-body">{app_config.SUPPORT_EMAIL} {i18nLabels.unsubscribe_body2}</div>
                <div className="unsubscribe-body">{i18nLabels.unsubscribe_body3}</div>
                <div className="unsubscribe-btn">
                    <Button onClick={onBackToHome}>{i18nLabels.unsubscribe_backtohome}</Button>
                </div>
            </div>
        </div>
    );
}
export default UnsubscribePage;
