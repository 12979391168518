import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { TypeWorkbook, TypeFavorite } from '../../../types/types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getAlertDetails, getDays, calculateDateStatus } from '../../../services/utils';
import { useNavigate } from 'react-router-dom';
import allActions from '../../../store/actions';
import { getDashboardList } from '../../../services/api.service';
import SharingContainer from '../dashboardShare/SharingContainer';
import {
    setDashboardName,
    setWorkbookId,
    setDashboardUserId
} from '../../../services/user.service';
import { RootState } from '../../../store/store';
import FavoriteContainer from './FavoriteContainer';
import CreateDashboardContainer from '../dashboard/CreateDashboardContainer';
import RecentLeftPanel from '../recentDashboard/RecentLeftPaneltsx';
import { globalConstant } from '../../../services/constant';
import i18nLabels from '../../../services/i18n.service';
import moment from 'moment';

function Home() {
    const navigate = useNavigate();
    const currentYear = moment().format('YYYY');
    const copyrightText = `${i18nLabels.footer_copyright_1} ${currentYear} ${i18nLabels.footer_copyright_2}`;
    const [view, setView] = useState<Array<TypeWorkbook> | null>(null);
    const [signalsList, setSignalsList] = useState<Array<TypeWorkbook>>([]);
    const [lastTimeStamp, setLastTimeStamp] = useState("");
    const [favoriteDashboard, setFavoriteDashboard] = useState<TypeFavorite | null>(null);
    const dispatch = useDispatch();
    const lastLoginTimestamp = localStorage.getItem('last-login-timestamp');
   
    const [siteName, profileName, reloadDashboard, isSignalsEnabled, isSystemNotificationOn] = useSelector((state: RootState) => {
        return [state.user.siteName,  state.user.profileName, state.user.reloadDashboard, state.user.isSignalsEnabled, state.user.isSystemNotificationOn];
    });
    
    const shareDashboardState = useSelector((state: RootState) => {
        return state.shareDashboard;
    });
    const createDashboardState = useSelector((state: RootState) => {
        return state.createDashboard;
    });

    useEffect(() => {
        if (lastLoginTimestamp) {
            setLastTimeStamp(lastLoginTimestamp);
        }
    }, [lastLoginTimestamp])


    useEffect(() => {
        if (siteName && profileName) {
            getViews();
        }
    }, [siteName, profileName, reloadDashboard]);

    useEffect(() => {
        if (reloadDashboard) {
            getViews();
            if(isSignalsEnabled) {
                getSignals();
            }
           
        }
    }, [reloadDashboard]);

    useEffect(() => {
        if (siteName && profileName && isSignalsEnabled) {
            getSignals();
        }
    }, [siteName, profileName, isSignalsEnabled]);

    const getViews = async () => {
        dispatch(allActions.loaderAction.showLoader());
        getDashboardList(siteName , profileName, 0, 12, globalConstant.reportType.dashboard)
            .then((reponse) => {
                if (!reponse) {
                    reponse = {
                        dashboard: {
                            workBooks: [],
                            favouriteDashboard: null
                        }
                    };
                }
                const workbooks = reponse.dashboard.workBooks;

                for (const element of reponse.dashboard.workBooks) {
                    const getDaysValue = getDays(element.lastAccessedDate);
                    element.getDays = getDaysValue;
                }

                setDashboardUserId(reponse.dashboard.userId);
                setWorkbookId(null);
                setView(workbooks);
                setFavoriteDashboard(reponse.dashboard.favouriteDashboard);
                dispatch(allActions.userAction.addUser(reponse.dashboard));
            })
            .catch((response) => {
                setView([]);
                setFavoriteDashboard(null);
                dispatch(allActions.userAction.addUser({}));
                dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
            })
            .finally(() => {
                dispatch(allActions.userAction.reloadDashboard(false));
                dispatch(allActions.loaderAction.hideLoader());
            });
    };

    const getSignals = async () => {
        dispatch(allActions.loaderAction.showLoader());
        getDashboardList(siteName , profileName, 0, 12, globalConstant.reportType.signals)
            .then((response) => {
                const workbooks = response?.signals?.workBooks || [];
                
                setSignalsList(workbooks);
            })
            .catch((response) => {
                setSignalsList([]);
                dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
            })
            .finally(() => {
                dispatch(allActions.loaderAction.hideLoader());
            });
    };

    const onCreateNewDashboard = () => {
        dispatch(allActions.createDashboardAction.showCreateDashboardPopup(true));
    };
    const gotoView = (
        workBookName: string,
        workSheetId: string,
        workBookId: string,
        workbookUrl: string,
        reportType:string
    ) => {
        setDashboardName(workBookName);
        setWorkbookId(workBookId);
        navigate(`/${reportType}/view/${siteName}/${workbookUrl}/${workSheetId}`);
    };

    if (!view) {
        return <> </>;
    }

    return (
        <Container fluid-xxl className={`vertical-space-16 container-xxl ${isSystemNotificationOn ? 'vertical-space-with-notification' : ''}`}>
            {shareDashboardState.isOpen ? <SharingContainer></SharingContainer> : null}
            {createDashboardState.isOpen ? <CreateDashboardContainer></CreateDashboardContainer> : null}
            <div className='landing-page'>
                <div className='top-recent-dashboard'>
                    <RecentLeftPanel
                        view={view}
                        signals={signalsList}
                        onCreateNewDashboard={onCreateNewDashboard}
                        gotoView={gotoView}></RecentLeftPanel>
                </div>
                <div className="home-right-content">
                    <FavoriteContainer favoriteDashboard={favoriteDashboard}></FavoriteContainer>
                    <Row className="fs-12 footer  p-2 mt-3 landing-footer">
                        <Col md={8} className="footer-text">{copyrightText}
                        </Col>
                        <Col md={4} className="align-right policy">
                            <span className="login-header">{lastTimeStamp ? 'Last Login: ': ''}</span>
                            <span className="login-time">{lastTimeStamp ? calculateDateStatus(lastTimeStamp) : ''}</span>
                            <a href="https://www.argusinformation.com/privacy-notice/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        </Col>
                    </Row>
                </div>

                <div className='bottom-recent-dashboard'>
                    <RecentLeftPanel
                        view={view}
                        signals={signalsList}
                        onCreateNewDashboard={onCreateNewDashboard}
                        gotoView={gotoView}></RecentLeftPanel>

                        <Row className="fs-12 footer  p-2 mt-4">
                            <Col sm={8} className="footer-text">{copyrightText}
                            </Col>
                            <Col sm={4} className="align-right policy">
                                <span className="login-header">{lastTimeStamp ? 'Last Login: ': ''}</span>
                                <span className="login-time">{lastTimeStamp ? calculateDateStatus(lastTimeStamp) : ''}</span>
                                <a href="https://www.argusinformation.com/privacy-notice/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                            </Col>
                        </Row>
                </div>
                </div>
        </Container>
    );
}
export default Home;
