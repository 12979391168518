import React, { ReactNode, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Pagination from 'react-bootstrap/Pagination';
import './pagination.scss';
import i18nLabels from '../../../../services/i18n.service';
type Props = {
    totalDashboardCount: number;
    dashboardCountPerPage:number;
    getViews: (startNumber: number, lastNumber: number) => void;
    currentSelectedTab: string;
};
/**
 * Handling Pagination for dashboard page
 * @param {Number} totalDashboardCount - Getting Total Dashboard Count
 * @param {Number} dashboardCountPerPage -  Getting Dashboard Per Page Count
 * @param {Function} getViews - In this function, getting pagination start and last number
 * @return {Object} Return Pagination Component
 */

 export const previousPagination = (numberPagination, paginationNumber) => {
    numberPagination(paginationNumber - 1);
};

export const nextPagination = (numberPagination, paginationNumber) => {
    numberPagination(paginationNumber + 1);
};

function HandlingPagination({totalDashboardCount = 0, dashboardCountPerPage, getViews, currentSelectedTab }: Props) {
    const [perPage] = useState(10);
    const [paginationNumber, selectedpaginationNumber] = useState(1);
    const [count, setCount] = useState(false);
    const noOfPage = Math.ceil(totalDashboardCount / perPage);
    const items: Array<ReactNode> = [];

    const [profileName, reloadDashboard] = useSelector((state: RootState) => {
        return [state.user.profileName, state.user.reloadDashboard];
    });

    useEffect(() => {
        if (reloadDashboard) {
            const startNumber = paginationNumber * 10 -10 ;
            if(startNumber < (totalDashboardCount -1) ) {
                numberPagination(paginationNumber);
            }else{
                numberPagination(paginationNumber > 1 ? paginationNumber - 1 : 1);
            }
            
        }
    }, [reloadDashboard]);

    useEffect(() => {
        selectedpaginationNumber(1);
    }, [currentSelectedTab])

    const numberPagination = (number)=>{
        console.log(number, "number");
        const startNumber = number * 10 -10 ;
        console.log("startNumber", startNumber);
        const lastNumber = 10;
        selectedpaginationNumber(number);
        

        if(count == true){
            getViews(startNumber, lastNumber);
         }
        setCount(true);
    };
    

    useEffect(() => {
        if (profileName) {
            setCount(false); 
            numberPagination(1);    
        }
    }, [profileName]);
    
    for (let number = 1; number <= noOfPage; number++) {
        items.push(
            <Pagination.Item
                className="me-2"
                key={number}
                active={number == paginationNumber}
                onClick={() => {
                    numberPagination(number);
                }}>
                {number}
            </Pagination.Item>
        );
    }

    return (
        <Row className="pt-4">
            <Col align="right" className="pagination-wrapper">
                <Pagination className="float-end">
                    <Pagination.Prev
                        className="me-2"
                        data-testid="previous-btn"
                        disabled={paginationNumber === 1}
                        onClick={() => {previousPagination(numberPagination, paginationNumber)}}>
                        {' '}
                        {i18nLabels.pagination_previous}{' '}
                    </Pagination.Prev>
                    {items}
                    <Pagination.Last
                        className="me-2"
                        disabled={noOfPage == paginationNumber || noOfPage == 0}
                        data-testid="next-btn"
                        onClick={() => {nextPagination(numberPagination, paginationNumber)}}>
                        {' '}
                        {i18nLabels.pagination_next}{' '}
                    </Pagination.Last>
                </Pagination>

        <span className="pt-1 fs-14 me-4 float-end">{i18nLabels.pagination_showing} {dashboardCountPerPage} {i18nLabels.pagination_outof} {totalDashboardCount} {i18nLabels.pagination_results}</span>
  
  
</Col>
</Row>
           

  );
}
export default HandlingPagination;
