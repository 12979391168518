import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import CloseButton from 'react-bootstrap/CloseButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../../common/ui/button/Button';
import EditTableau from '../../common/tableau/EditTableau';
import { getVizEditURL, getVizEditURLWithToken, getAlertDetails, dateFormat } from '../../../services/utils';
import { syncMetadataTableEdit, syncMetadataTableCreate, getTicket, getSignalDate } from '../../../services/api.service';
import allActions from '../../../store/actions';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import useGetFilters from '../../../hooks/useGetFilters';
import type { RootState } from '../../../store/store';
import VizFilterContainer from '../dimensionFilters/VizFilterContainer';
import {
    getDashboardName,
    setDashboardName,
    getWorkbookId,
    setWorkbookId
} from '../../../services/user.service';
import { globalConstant } from '../../../services/constant';
import useAddAudit from '../../../hooks/useAddAudit';
import i18nLabels from '../../../services/i18n.service';

interface Props {
    isNewWorkbook:boolean;
    reportType: string;
}
let signalTrendType = '';
/**
 * React component for opening viz in edit mode
 * @return {DOM} Returm React DOM
 */
function EditDashboard({isNewWorkbook, reportType}:Props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const [newUrl, setNewURL] = useState('');
    const [showFilters, setShowFilters] = useState(false);
    const [workbookName, setWorkbookName] = useState('');
    const [currentTabSheet, setCurrentTabSheet] = useState('');
    const [signalCreationDate, setSignalCreationDate] = useState('');
  
    const {siteName, profileName, currentWorkbookName} = useSelector((state: RootState) => {
        return state.user;
    });
    const [isVizRendered, setIsVizRendered] = useState(false);
    const refModal = useRef<HTMLDivElement>(null);
    

    const { appliedFilters, totalSelectedFilters } = useSelector((state: RootState) => {
        return state.dimensionFilters;
    });


    const template = useSelector((state: RootState) => {
        return state.createDashboard.template;
    });
    const {logCreateDashboard, logUpdateDashboard} = useAddAudit();
    const {dimensionFilters} = useGetFilters(isNewWorkbook && template ? template.workbookId : null, params.workbook, params.dashboard, reportType);
    useOnClickOutside(refModal, () => setShowFilters(false));
    useEffect(() =>{
        if(!template && !params.workbook) {
            navigate("/");
        }
    },[])

    useEffect(() => {
        if (siteName) {
            if(params?.siteName && params?.siteName !== siteName) {
                navigate('/');
                const error = {
                    typr: 'danger',
                    message: i18nLabels.workbook_not_preset
                }
                dispatch(allActions.alertAction.showAlert(error));
            }else{
            getTicket(siteName)
                .then((reponse) => {
                    isNewWorkbook && template ?
                    setNewURL(getVizEditURLWithToken(reponse.ticket, template.workbookName, template.worksheetId, siteName)) :
                    setNewURL(getVizEditURL(params.workbook, params.dashboard, params.siteName));
                })
                .catch((response) => {
                    dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
                });
            }
        }
        }, [siteName]);

    useEffect(() => {
        dispatch(allActions.dimensionFiltersAction.addServerFilters(dimensionFilters));
    }, [dimensionFilters]);

    useEffect(() =>{
        const workbookId = getWorkbookId();
        signalTrendType = '';
        if(reportType === globalConstant.reportType.signals && workbookId) {
            getSignalDate(siteName, profileName, 0, 10, workbookId)
            .then((response) => {
                if(!response){
                    response = {
                        signals: {
                            workBooks:[]
                        }
                    }
                }
                signalTrendType = response.signals.workBooks[0].signalType;
                setSignalCreationDate(dateFormat(response.signals.workBooks[0].createdDate, 'MMMM yyyy'))
            })
        .catch(() => {
            console.log('')
        });
        }
    }, [])
    
    const toggleFilter = () => {
        if(isVizRendered){
            setShowFilters(!showFilters);
        }
    };
    const updateVizRenderedStatus = (isRendered:boolean) => {
        setIsVizRendered(isRendered)
    };
    const onPublishDashboard = (publishWorkbookName?: string, sheetId?: string) => {
        dispatch(allActions.loaderAction.showLoader());
        if (publishWorkbookName) {
            syncMetadataTableCreate(
                siteName, 
                profileName,
                publishWorkbookName,
                signalTrendType
            )
                .then((response) => {
                    try {
                        setDashboardName(response.workbooks[0].workbookName);
                        setWorkbookName(response.workbooks[0].workbookName);
                        setWorkbookId(response.workbooks[0].workbookId);
                    } catch {
                        console.log('No valid workbook recieved after new dashboard created');
                    }
                    try{
                        logCreateDashboard(response.workbooks[0].workbookName, reportType, template?.workbookName, 'SUCCESS' )
                    }catch(err) {
                        console.log(err)
                    }
                    navigate(`/${reportType}/edit/${siteName}/${publishWorkbookName}/${sheetId}`);
                    const url = getVizEditURL(publishWorkbookName, sheetId, siteName);
                    setNewURL(url);
                    const notificationObj = {
                        message: response.serviceMessage.message,
                        code: 'Success',
                        type: 'success',
                        showCode: true
                    }
                    dispatch(allActions.alertAction.showAlert(notificationObj));
                })
                .catch((response) => {
                    logCreateDashboard('', reportType, template?.workbookName, 'FAILED' );
                    dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
                })
                .finally(() => {
                    dispatch(allActions.loaderAction.hideLoader());
                });
        } else{
            onPublishDashboardEdit()
        }
    };
    const onPublishDashboardEdit = () => {
       
            const _workbookName = workbookName || getDashboardName() || currentWorkbookName;
            syncMetadataTableEdit(
                siteName,
                profileName,
                getWorkbookId()
            )
                .then((response) => {
                    if (
                        response?.editdashboardSyncRequest?.defaultworkSheetId
                    ) {
                        try{
                            logUpdateDashboard(_workbookName, reportType, template?.workbookName, 'SUCCESS' )
                        }catch(err) {
                            console.log(err)
                        }
                        navigate(
                            `/${reportType}/edit/${siteName}/${params.workbook}/${
                                response.editdashboardSyncRequest.defaultworkSheetId
                            }`
                        );
                        const url = getVizEditURL(params.workbook, response.editdashboardSyncRequest.defaultworkSheetId, siteName);
                        setNewURL(url);
                    }
                })
                .catch((response) => {
                    logUpdateDashboard(_workbookName, reportType, template?.workbookName, 'FAILED' )
                    dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
                })
                .finally(() => {
                    dispatch(allActions.loaderAction.hideLoader());
                });
    };

    return (
        <>
            <div id="outer-wrapper" className="square">
                <Row className="worksheet-header mb-2 pt-0">
                    <Col sm={6}>
                        <div className="view-dashboard-header">
                            <h4 className="left view-header-title fs-16">{isNewWorkbook ? 'Create Dashboard' : workbookName || getDashboardName() || currentWorkbookName}</h4>
                            {reportType === globalConstant.reportType.signals && <h4
                                className="left view-header-date fs-12"
                                >
                                {signalCreationDate}
                            </h4>}
                        </div>
                    </Col>
                    <Col sm={6} className="right">
                        <div className="dashboard-right-btn-group">
                            {!isNewWorkbook ? <><Button
                                onClick={() => {
                                    navigate(
                                        `/${reportType}/view/${params.siteName}/${params.workbook}/${currentTabSheet || params.dashboard}`
                                    );
                                }}
                                type="icon-text">
                                <span className="btn-text action-btn-text"> {i18nLabels.dashboard_view}</span>
                            </Button>
                            
                           { reportType === globalConstant.reportType.dashboard && <span className="btn-divider"></span>}</>: null}
                            {reportType === globalConstant.reportType.dashboard && <Button
                                type="secondry"
                                disabled={!isVizRendered}
                                onClick={toggleFilter}>
                                <span className={totalSelectedFilters ? 'selected-filter-btn' : 'filter-text'}>
                                    {i18nLabels.dashboard_filter_title}{' '}
                                    {totalSelectedFilters ? `(${totalSelectedFilters})` : ''}
                                </span>
                            </Button>}
                            
                        </div>
                    </Col>
                </Row>
                <Row id="list-dashboard" className="view-dashboard rounded-3 mx-2">
                    {reportType === globalConstant.reportType.dashboard && showFilters && (
                        <>
                            <div className="argus-overlay"></div>
                            <div ref={refModal} className="filter-holder">
                                <span className="close-right">
                                    <CloseButton onClick={toggleFilter} />
                                </span>
                                <VizFilterContainer />
                            </div>
                        </>
                    )}

                    <Col sm={12}>
                        <EditTableau
                            reportType={reportType}
                            setDashboardIsVizRendered={updateVizRenderedStatus}
                            onPublishDashboard={onPublishDashboard}
                            extraHeight={0}
                            showFilters={showFilters}
                            setCurrentTabSheet={setCurrentTabSheet}
                            selectedFilters={appliedFilters}
                            url={newUrl}></EditTableau>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default EditDashboard;
