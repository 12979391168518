const showSharePopup = (payload) => {
    return {
        type: "SHOW_SHARE_POPUP",
        payload
    }
}

const hideSharePopup = () => {
    return {
        type: "HIDE_SHARE_POPUP"
    }
}

export default {
    showSharePopup,
    hideSharePopup
}