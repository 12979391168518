const initialState = {
    isOpen: false,
    type: 'danger',
    message: '',
    code: '',
    showCode: false
};

const alert = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'SHOW_ALERT':
            return {
                ...state,
                isOpen: true,
                ...payload
            };

        case 'HIDE_ALERT':
            return {
                ...state,
                isOpen: false,
                showCode: false
            };
        default:
            return state;
    }
};
export default alert;
