import React,{useCallback, useEffect, useMemo, useState,useRef} from "react";
import {AgGridReact} from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "./Audit.scss";
import AuditDetails from "./AuditDetails";
import { AuditDetail,UserAccessData as Data } from "../types/auditTypes";
import { useDispatch} from 'react-redux';
import allActions from "../../../../store/actions";
import Pagination from 'react-bootstrap/Pagination';

type Props = {
    userAccessData:Array<Data>,
    setCurrentPage: (currentPage: number) => void,
    setRowsPerPage: (rowsPerPage: number) => void
}

const UserAccessData = (props:Props) => {
    const gridApiRef = useRef<any>(null);
    const {userAccessData}:Props = props;
    const gridStyle = useMemo(() => ({height:'93%',width:'100%' }), []);
    const [userAccessDataForDisplay,setUserAccessDataForDisplay] = useState<Array<Data>>([]);
    const [details, setDetails] = useState<AuditDetail>();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(0);
    const [totalDisplayedRows, setTotalDisplayedRows] = useState<number>(0)
  

    const [totalPages,setTotalPages] = useState<number>(0);

    useEffect(() => {
        if(userAccessDataForDisplay.length ==0) {
            dispatch(allActions.auditAction.disableDownload(true));
        }else {
        dispatch(allActions.auditAction.disableDownload(false));
        }
     
    },[userAccessDataForDisplay]);

    /** 
    * Column definatins for the grid
    */
    const columnDefs:any = [
        { field: 'user', headerName: 'User Name', suppressMovable:true,resizable: true, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'userType', headerName: 'Type', resizable: true,suppressMovable:true,minWidth:140, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'profile', headerName: 'Profile', suppressMovable:true,resizable: true, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'groups', headerName: 'Group', resizable: true,suppressMovable:true, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'role', headerName: 'Role', resizable: true,suppressMovable:true, sortable: true, filter: 'agTextColumnFilter' },
    ];
     
      useEffect(()=>{
        if (gridApiRef.current) {
          gridApiRef.current.sizeColumnsToFit();
        }
      },[userAccessDataForDisplay,details,currentPage,rowsPerPage])
   


    useEffect(() => {
           
        if(userAccessData !== undefined) {
            userAccessData.sort((a, b) => a.user.localeCompare(b.user)); 
        }
                setUserAccessDataForDisplay(userAccessData);
            }
       
        , [ userAccessData])



    const onGridReady = (event) => {
        gridApiRef.current = event.api;
        setRowsPerPage(gridApiRef.current.paginationGetPageSize());
        props.setRowsPerPage(gridApiRef.current.paginationGetPageSize());
        setTotalPages(gridApiRef.current.paginationGetTotalPages());
        setCurrentPage(0);
        props.setCurrentPage(0);
    
    }
    useEffect(() => {
        if(gridApiRef.current !== null){
        setRowsPerPage(gridApiRef.current.paginationGetPageSize());
        props.setRowsPerPage(gridApiRef.current.paginationGetPageSize());
        setTotalPages(gridApiRef.current.paginationGetTotalPages());
        setCurrentPage(0);
        props.setCurrentPage(0);
        gridApiRef.current?.paginationGoToPage(0);
        }
    },[userAccessDataForDisplay]);
     useEffect(() => {
       if(gridApiRef.current) {
        gridApiRef.current.deselectAll();
           }
     },[currentPage])
      const onPaginationChanged = useCallback(() => {

        if (gridApiRef.current) {      
             setTotalDisplayedRows(gridApiRef.current.getDisplayedRowCount());
        setTotalPages(gridApiRef.current.paginationGetTotalPages());
        setRowsPerPage(gridApiRef.current.paginationGetPageSize());
        props.setRowsPerPage(gridApiRef.current.paginationGetPageSize());
        }
      }, []); 
      const createPaginationComponent = () => {
        let i=0;  
        const items:React.JSX.Element[]=[];
        let startPage=1;
        let endPage= totalPages>4 ? 4:totalPages;
       
        if(currentPage>=3) {
           
            startPage= currentPage-1;
        }
        if(currentPage >= 3) {
            endPage = currentPage+2<=totalPages? currentPage+2: totalPages;     

        }
        for(i=startPage;i<=endPage;i++) {
        items.push(createPageItem(i));
           
      } return items;
    }

    function createPageItem(page) {
        return <Pagination.Item key={page} className={`page-item ${page-1 == currentPage ? 'page-active' : 'page-inactive'}` } active={page - 1 == currentPage} id={page} onClick={() => onPageChangeHandler(page - 1)}><div className="page-item-number">{page}</div></Pagination.Item>

    }
    const onPageChangeHandler = (page) => {
        setCurrentPage(page);
        props.setCurrentPage(page);
        gridApiRef.current?.paginationGoToPage(page);
    }
    const prevPaginationHandler = () => {
        gridApiRef.current?.paginationGoToPage(currentPage - 1);
        setCurrentPage(currentPage - 1);
        props.setCurrentPage(currentPage - 1);
    }
    const nextPaginationHandler = () => {
        gridApiRef.current?.paginationGoToPage(currentPage + 1);
        setCurrentPage(currentPage + 1);
        props.setCurrentPage(currentPage + 1);
    }

    const getRowInfo = () => {
       
        if (currentPage === 0 && rowsPerPage > totalDisplayedRows) {
            return <span>{1} - {totalDisplayedRows} of {totalDisplayedRows} items</span>
        }
        return <span>{currentPage === 0 ? 1 : ((currentPage) * rowsPerPage) + 1} - {(currentPage + 1) * rowsPerPage > totalDisplayedRows ? totalDisplayedRows : (currentPage + 1) * rowsPerPage} of {totalDisplayedRows} items</span>
    }


    useEffect(() => {
        setCurrentPage(0);
        props.setCurrentPage(0);
        gridApiRef.current?.paginationGoToPage(0);
    }, [ userAccessData,userAccessDataForDisplay])

    return (
        <>

            {details &&
                <AuditDetails details={details} />}
            <div style={gridStyle} className="ag-theme-alpine audit">
            
                <AgGridReact
                    rowData={userAccessDataForDisplay}
                    columnDefs={columnDefs}
                    // onFilterChanged={onGridFilterChanged}
                
                suppressRowClickSelection={true}
                suppressRowVirtualisation={true}
                    paginationAutoPageSize={true}
                    rowHeight={36}              
                    tooltipShowDelay={80}
                    suppressPaginationPanel={true}
                    pagination={true}    
                    suppressDragLeaveHidesColumns={true}
                    onGridReady={onGridReady}
                    onPaginationChanged={onPaginationChanged}
                ></AgGridReact>
            </div>
            { totalPages > 0 &&
                <div className="audit float-end div-pagination" >
                    <div className="d-flex justify-content-end pb-3 gy-0" style={{ maxHeight: '2rem' }}>
                        <div className="mt-1 fs-12">
                            {getRowInfo()}</div>
                        <div>
                            <Pagination size="sm" className="ms-3">
                                <Pagination.Prev data-testid="prev-btn" className={`${currentPage == 0 ? 'pagination-disabled' : 'pagination-enabled'}`} disabled={currentPage == 0} onClick={prevPaginationHandler}>Previous</Pagination.Prev>
                                {createPaginationComponent()}
                                <Pagination.Next data-testid="next-btn" className={`page-item ms-1 ${currentPage == totalPages - 1 ? 'pagination-disabled' : 'pagination-enabled'}`} disabled={currentPage == totalPages - 1} onClick={nextPaginationHandler}>Next</Pagination.Next>
                            </Pagination>
                        </div>
                    </div>
                </div>}

        </>
    );
}
export default UserAccessData;

