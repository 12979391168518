import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../common/ui/button/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams } from 'react-router-dom';
import { getDashboardListByFilter} from '../../../services/api.service';
import {
    setDashboardName,
    setWorkbookId,
} from '../../../services/user.service';
import {getAlertDetails, getDays} from '../../../services/utils'
import allActions from '../../../store/actions'
import { globalConstant, roles } from '../../../services/constant';
import DashboardThumbnails from './DashboardThumbnails';
import HandlingPagination from '../../common/ui/pagination/Pagination' 
import {TypeWorkbook} from '../../../types/types';
import { RootState } from '../../../store/store';
import useDeleteDashboard from '../../../hooks/useDeleteDashboard';
const dashboardType = {
    SHARE_BY_ARGUS: 'argus',
    SHARE_BY_BANK: 'bank',
    MY_DASHBOARD: 'self',
}
import i18nLabels from '../../../services/i18n.service';

function DashboardHome() {
    const navigate = useNavigate();
    const params = useParams();
    const [view, setView] = useState<Array<TypeWorkbook> | null>(null);
    const [workbookCount, setWorkbookCount] = useState<number>(0);
    const [dashboardCount, setDashboardCount] = useState<number>(0);
    const [currentSelectedTab, setCurrentSelectedTab] = useState(params.dashboardType || dashboardType.SHARE_BY_ARGUS);
    const dispatch = useDispatch();
    const {onDeleteHandler} = useDeleteDashboard()
  
    const [siteName, profileRole, profileName] = useSelector((state: RootState) => {
        return [state.user.siteName, state.user.role,  state.user.profileName];
    });
    
    useEffect(() => {
        
        if (siteName && profileName && currentSelectedTab) {
            getViews(0, 10);
        }
    }, [siteName, profileName, currentSelectedTab]);
   
    const onTabChange = (tabName) => {
        setCurrentSelectedTab(tabName);
        navigate(`/dashboard/list/${tabName}`);
    };
    const onShareHandler = (workbookId, workbookName, contentUrl) => {
        dispatch(allActions.shareDashboardAction.showSharePopup({workbookId, workbookName, contentUrl}));
    }

    const getViews = async (startNumber: number, lastNumber: number) => {
        dispatch(allActions.loaderAction.showLoader());
        getDashboardListByFilter(siteName,  profileName, startNumber, lastNumber, globalConstant.reportType.dashboard, currentSelectedTab)
            .then((reponse) => {
                if(!reponse){
                    reponse = {
                        dashboard: {
                            workBooks:[],
                            favouriteDashboard:null
                        }
                    }
                }
                setWorkbookId(null);
                setView(reponse.dashboard.workBooks);
                setWorkbookCount(reponse.dashboard.totalCount)
                setDashboardCount(reponse.dashboard.workBooks.length)
              
                for (const element of reponse.dashboard.workBooks) {
                const getDaysValue = getDays(element.lastAccessedDate);
                element.getDays = getDaysValue
              } 
              dispatch(allActions.userAction.addUser(reponse.dashboard));
            })
            .catch((response) => {
                dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
            })
            .finally(() =>{
                dispatch(allActions.userAction.reloadDashboard(false));
                dispatch(allActions.loaderAction.hideLoader());
            })
    };
    const onCreateNewDashboard = () => {
        dispatch(allActions.createDashboardAction.showCreateDashboardPopup(true));
    };
    const gotoView = (
        workBookName: string,
        workSheetId: string,
        workBookId: string,
        workbookUrlName: string,
        reportType:string
    ) =>
     {
        setDashboardName(workBookName);
        setWorkbookId(workBookId);
        navigate(`/${reportType}/view/${siteName}/${workbookUrlName}/${workSheetId}`);
    };

    
   
    if (!view) {
        return (
            <></>
        );
    }

    return (
       
            <Row className='remove-x-space dashboard-list'>
            <Col>
          
            <div id="outer-wrapper" className="square">
                <Row className="pt-2 pb-2">
                    <Col md={3} className='no-hor-padding'>
                        {' '}
                        <h2 className="text-left dashboard-text">{i18nLabels.dashboard_home_title}</h2>
                    </Col>
                    <Col md={9} className="align-right create-dashboard-wrapper no-hor-padding">
                        {roles.EDIT.indexOf(profileRole) > -1 ? (
                            <Button onClick={onCreateNewDashboard}>{i18nLabels.dashboard_home_create}</Button>
                        ) : (
                            ''
                        )}
                    </Col>
                </Row>
                
                <div className="pt-0">
                        <Row id="list-dashboard" className="rounded-3 mx-2">
                            <div className="tab-title">
                                <div
                                    onClick={() => onTabChange(dashboardType.SHARE_BY_ARGUS)}
                                    className={`tabs ${
                                        currentSelectedTab === dashboardType.SHARE_BY_ARGUS && 'selected'
                                    }`}>
                                    {i18nLabels.dashboard_category1}
                                </div>
                                <div
                                    onClick={() => onTabChange(dashboardType.SHARE_BY_BANK)}
                                    className={`tabs ${
                                        currentSelectedTab === dashboardType.SHARE_BY_BANK && 'selected'
                                    }`}>
                                    {i18nLabels.dashboard_category2}
                                </div>
                                <div
                                    onClick={() => onTabChange(dashboardType.MY_DASHBOARD)}
                                    className={`tabs ${
                                        currentSelectedTab === dashboardType.MY_DASHBOARD && 'selected'
                                    }`}>
                                    {i18nLabels.dashboard_category3}
                                </div>
                                 
                            </div>
                            
                            
                            <Row className='thumbnails-container'>
                            {view?.length ? <DashboardThumbnails reportType={globalConstant.reportType.dashboard} shareDashboard={onShareHandler} deleteDashboard={onDeleteHandler} view={view} gotoView={gotoView}></DashboardThumbnails> : <p className="text-center mb-5 mt-5"> {i18nLabels.no_dashboard_found}</p>}
                            </Row>
                        </Row>

                        <Row>
                            {view && <HandlingPagination
                                 totalDashboardCount={workbookCount}
                                 dashboardCountPerPage={dashboardCount}
                                 getViews={getViews}
                                 currentSelectedTab = {currentSelectedTab}
                                 ></HandlingPagination>
                            }
                            
                        </Row>
                    </div>
            </div>
            </Col>
            </Row>
       
    );
}
export default DashboardHome;
