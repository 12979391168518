import React, { useEffect, useState } from 'react';
import './signout.scss';
import { app_config } from '../../../services/constant';
import Argus_Logo from '../../../assets/images/logo.svg';
import Button from '../../common/ui/button/Button';
import i18nLabels from '../../../services/i18n.service';
import { clearCache } from '../../../services/utils';
function Signout() {
    const [signoutMessage, setSignoutMessage] = useState('');

    useEffect(() => {
        const isTimeout = localStorage.getItem("session-timeout") === 'true';
        //Changing signout message based on flag to show different message for timeout and intentional signout
        const signoutText = isTimeout ? i18nLabels.timeout_message : i18nLabels.signout_message
        setSignoutMessage(signoutText);
        localStorage.getItem('last-login-timestamp')&& localStorage.removeItem('last-login-timestamp');
    }, []);

    const onSigninAgain = () => {
        clearCache();
        window.location.href = app_config.REDIRECT_SIGN_IN ?? '';
    };
    return (
        <div className="signout-holder">
            <div className="signout-content">
                <div className="signout-header">
                    <img src={Argus_Logo} alt="Argus" className="logocss" />
                </div>
                <div className="signout-text">{signoutMessage}</div>

                <div className="signout-btn">
                    <Button onClick={onSigninAgain}>{i18nLabels.signout_btn_label}</Button>
                </div>
                {/* Hidden container for iframe to load TABLEAU lougout page for invalidating Tableau session  */}
                <div className='tableau-logout-container'>
                    <iframe src={`${app_config.TABLEAU_URL}/auth/logout`}></iframe>
                </div>
            </div>
        </div>
    );
}
export default Signout;
