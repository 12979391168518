import React from 'react';

interface Props {
    message: string;
    isSuccess: boolean;
}

function DatasetAllignedMessage({ message, isSuccess }: Props) {
    if (isSuccess) {
        return (
            <div className="text-success">
                <span className="material-symbols-outlined">info</span>
                {message}
            </div>
        );
    }
    return (
        <div className="text-danger">
            <span className="material-symbols-outlined">info</span>
            {message}
        </div>
    );
}

export default DatasetAllignedMessage;
