// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { TypeFilterOption, TypeServerPayloadTenant } from '../../../types/types';
import { RootState } from '../../../store/store';
import MultiSelectDropdown from '../../common/ui/multiSelectDropdown/MultiSelectDropdown';
import { roles } from '../../../services/constant';
import { getAlltenant, multidropdownAllSelected } from './dashboardShareUtils';
interface Props {
    siteName: string;
    profileName: string;
    setParentSelectedView: (selectedView:string) => void;
    onProfileChange: (selectedTenant:TypeServerPayloadTenant[], string) => void;
}

const allTenant = {
    isAll: true,
    label: 'Select All Tenant',
    id: 'Select All Tenant',
    value: false
};

const allProfileItem = {
    isAll: true,
    label: 'Select All Profile',
    value: false
};

const targetUsers = [
    {
        label: 'CCPS Group',
        key: 'ccps_group',
        index: 0
    },
    {
        label: 'Myself',
        key: 'self',
        index: 1
    }
]

function MultiTenantProfileSelection({ onProfileChange, setParentSelectedView, siteName, profileName }: Props) {
    const [tenantList, setTenantList] = useState<TypeFilterOption[]>([]);
    const [profileList, setProfileList] = useState<TypeFilterOption[]>([]);
    const [selectedView, setSelectedView] = useState('');
    
    const allProfile = useSelector((state: RootState) => {
        return state.user.allProfile;
    });

    const profileRole = useSelector((state: RootState) => {
        return state.user.role;
    });

    useEffect(() =>{
        const selectedDefaultUser = roles.GROUP_MULTITENANT.indexOf(profileRole) > -1 ?
        targetUsers[1].key : targetUsers[0].key
        setSelectedView(selectedDefaultUser)
        setParentSelectedView(selectedDefaultUser);
        return () => {
            allTenant.value = false;
            allProfileItem.value=false;

        }
    }, [])
    //Set all tenanats
    useEffect(() => {
        if (allProfile) {
            const allTenantList = getAlltenant(allProfile,siteName, profileName)
            setTenantList([allTenant, ...allTenantList]);
        }
    }, [allProfile]);

    //Set profile list
    useEffect(() => {
        allProfileItem.value=false;
        if (tenantList) {
            const selectedTenants = tenantList.filter(({ value }) => {
                return value;
            });
            const profileItems = selectedTenants?.length ? [allProfileItem] : [];
            selectedTenants.forEach((tenant) => {
                if(!tenant.isAll){
                    const tenantItem = {
                        label: tenant.label,
                        id: tenant.id,
                        type: 'label',
                        value: false,
                        isAll: false
                    };
                    profileItems.push(tenantItem);
                    tenant.options?.forEach((profile, index) => {
                        const profileItem = {
                            label: profile,
                            type: '',
                            value: false,
                            isAll: false,
                            parentId: tenant.id,
                            isLast: index === tenant.options?.length -1
                        };
                        profileItems.push(profileItem);
                    });
                }
               
            });
            setProfileList(profileItems);
        }
    }, [tenantList]);

    useEffect(() => {
        if (tenantList && profileList) {
            const copyTenantList = [...tenantList]
            let selectedTenants = copyTenantList.filter(({ value }) => {
                return value;
            })
            selectedTenants = selectedTenants.map((tenant) => {
                const copyTenant = {
                    siteName:tenant.id
                };
                const selectedProfiles = profileList.filter(({parentId, value}) => {
                    return tenant.id === parentId && value;
                })
                copyTenant.profiles = selectedProfiles?.map((profile) => {
                    return {
                        name: profile.label
                    }
                }) || [];
                return copyTenant;
            })

            const selectedTenantsWithProfiles = selectedTenants.filter(({ profiles }) => {
                return profiles.length;
            })
            
            onProfileChange(selectedTenantsWithProfiles, selectedView);
        }
    }, [tenantList, profileList]);

    const updateTenantSelection = (index: number, selection: boolean) => {
        const newState = [...tenantList];
            newState[index].value = selection;
            if (index === 0) {
                newState.forEach((option) => {
                    option.value = selection;
                });
            }
       
        newState[0].value = multidropdownAllSelected(newState)
      
        setTenantList(newState)
    };

    const onTenantResetChange = () => {
        const newState = [...tenantList];
            newState.forEach((option) => {
                option.value = false;
            });
        setTenantList(newState);
    }

    const onProfileResetChange = () => {

        const newState = [...profileList];
            newState.forEach((option) => {
                option.value = false;
            });
        setProfileList(newState);
    }
    const updateProfileSelection = (index: number, selection: boolean) => {
        const newState = [...profileList];
        newState[index].value = selection;
        if (index === 0) {
            newState.forEach((option) => {
                option.value = selection;
            });
        }
        newState[0].value = multidropdownAllSelected(newState)
        setProfileList(newState);
    };
    

    return (
        <div className="tenant-droptdowns-multiple">
            <div className="drop-down-holder">
                <span>Tenant</span>
                <MultiSelectDropdown
                    displayName=""
                    placeHolderText='Select Tenant'
                    options={tenantList}
                    onResetChange={onTenantResetChange}
                    updateParentSelection={updateTenantSelection}></MultiSelectDropdown>
            </div>
            <div className="drop-down-holder">
                <span>Profile</span>
                <MultiSelectDropdown
                    displayName=""
                    disable={profileList.length <= 1}
                    placeHolderText='Select Profile'
                    options={profileList}
                    onResetChange={onProfileResetChange}
                    updateParentSelection={updateProfileSelection}></MultiSelectDropdown>
            </div>
            <div className="drop-down-holder view">
                <span>User</span>
                <Form.Select
                        disabled={roles.GROUP_MULTITENANT.indexOf(profileRole) > -1}
                        value={selectedView}
                        size="sm"
                        onChange={(event) => {
                            setSelectedView(event.target.value);
                            setParentSelectedView(event.target.value)
                        }}>
                        {targetUsers.map((view) => {
                            return (
                                <option key={view.label} value={view.key}>
                                    {view.label}
                                </option>
                            );
                        })}
                    </Form.Select>
            </div>
        </div>
    );
}
export default MultiTenantProfileSelection;
