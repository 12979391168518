import { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getDimensionFilters } from '../services/api.service';
import { TypeFilter } from '../types/types';
import allActions from '../store/actions';
import { getAlertDetails } from '../services/utils';
import { RootState } from '../store/store';
import { useNavigate } from 'react-router-dom';


import {
  getWorkbookId,
  setWorkbookId
} from '../services/user.service';
import { globalConstant } from '../services/constant';

const allOption = {
  label: 'All',
  value:true
}
const useGetFilters = (workBookId:string | null = null, workbookName?:string, worksheetName?:string, reportType = globalConstant.reportType.dashboard) => {
    const [dimensionFilters, setData] = useState<Array<TypeFilter>>([]);
    const [selectedWorkBookId, setSelectedWorkBookId] = useState<string | null>(workBookId);
    const [isWorkbookEditable, setIsWorkbookEditable] = useState(false);
    const [authorName, setAuthorName] = useState<{ownerName: string, ownerCompany: string}>({ownerName: '', ownerCompany: ''});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [siteName, profileName] = useSelector((state: RootState) => {
        return [state.user.siteName, state.user.profileName];
    });

    const transformOption = (options, dimensionName) =>{
      return options.map((option) =>{
        let optionFormatted = option;
        if(dimensionName === 'reporting_date') {
          optionFormatted = moment(option).format(globalConstant.dateDisplayFormat);  
        }
        return {
          label:optionFormatted,
          key:option,
          value: true
        }
      })
    }

    const transformData = (filters) =>{
      let allFilters:Array<TypeFilter> = []
      try{
        filters.forEach(element => {
          const dataSourceFilters =  element.dashboardFilters.map((filter) =>{
            const tarnsformedFilter = {
              key:filter.dimensionName,
              displayName: filter.displayName,
              options: [allOption, ...transformOption(filter.values || [], filter.dimensionName )]
            }
            return tarnsformedFilter;
          })
          allFilters = [...allFilters, ...dataSourceFilters ];
          if(allFilters?.length>0){
            const portfolioIndex = allFilters.findIndex(item => item["key"] === "Portfolio");
            if(portfolioIndex !== -1){
              const portfolioItem = allFilters.splice(portfolioIndex, 1)[0];
              allFilters.push(portfolioItem);
            }
          }
        });
      }
      catch(error){
        console.log(error)
      }
      return allFilters;
    }

    useEffect(() => {
      const  currentWorkBookId = workBookId || getWorkbookId();
      if(reportType === globalConstant.reportType.dashboard && siteName && profileName && (workbookName || currentWorkBookId)) {
        getDimensionFilters( siteName, profileName, currentWorkBookId, workbookName, worksheetName)
          .then((response) => {
              const data = transformData(response.dashboardFilterDetails.tableauDataSourceConfigurations)
              setData(data);
              if(!currentWorkBookId) {
                setSelectedWorkBookId(response.dashboardFilterDetails.workBookId);
                setWorkbookId(response.dashboardFilterDetails.workBookId);
              }
              setIsWorkbookEditable(response.dashboardFilterDetails.allowEditWorkBook)
              setAuthorName(response.dashboardFilterDetails.ownerDetails)
            })
            .catch((response) => {
              dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
              if (response.redirect) {
                  setTimeout(() => {
                      navigate('/');
                  }, 1000);
              }
          });
      }
      
    }, [workBookId]);

    return {dimensionFilters, selectedWorkBookId, isWorkbookEditable, authorName};
};

export default useGetFilters;
