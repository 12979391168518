import { combineReducers } from 'redux'  
import alert from './alertReducers' 
import loader from './loaderReducers' 
import user from './userReducers' 
import userProfile from './userProfileReducers'
import bankName from './bankNameReducers'
import dimensionFilters from './dimensionFiltersReducers'
import shareDashboard from './shareDashboardReducers'
import confirmationModel from './confirmationModelReducers'
import createDashboard from './createDashboardReducers'
import audit from './auditReducer'

  
export default combineReducers({  
    alert ,
    loader,
    user,
    userProfile,
    bankName,
    dimensionFilters,
    shareDashboard,
    createDashboard,
    confirmationModel,
    audit
}) 