import { dimensionDefaultOptions, nonOverallFilters } from "../../services/constant";

const initialState = {
    selectedFilters: [],//React selected filter
    appliedFilters: [],//React applied filter
    serverFilters: [],//Tableau filter
    totalSelectedFilters: 0,//Count of dimensions in which custon filters has been applied
    applySelectedFilter: false//Flag to trigger apply fiter in tableau
};

const makeArrCopy = (serverFilters) => {
    let copyFilters = [...serverFilters];
    copyFilters = copyFilters.map((filter) => {
        const copyFilter = { ...filter };
        copyFilter.options = copyFilter.options.map((option) => {
            return { ...option };
        });
        return copyFilter;
    });
    return copyFilters;
};

//Retun number of dimension in which other than default flters are applied
const getSelectedDimension = (selectedFilters) => {
    let totalSelectedDimension = 0;
    selectedFilters.forEach((filter) => {
        const selectedDimenstion = filter.options.find((option) => {
            return option.value;
        });
        if (selectedDimenstion && !filter.options[0].value) {
            totalSelectedDimension++;
        }
    });
    return totalSelectedDimension;
};

//Reset or select all options in a dimension
const resetOptions = (options) =>{
        options.forEach((option) => {
            option.value = true;
        });
}

//Check if tableau applied filter options are present in React filter options,
//if not, apply default rect filter
const removeNotFoundOptions =(serverDim, localDim) =>{
    let serverAppliedOptions =  serverDim.appliedValues.filter((serverOption) => {
        return localDim?.options?.some((localOption) => {
            return serverOption === 'Overall' || serverOption === localOption.label;
        })
    });
    if(!serverAppliedOptions?.length) {
        if(serverDim.fieldName === 'reporting_date') {
            serverAppliedOptions = [localDim.options[localDim.options.length-1]]
        }
        if(nonOverallFilters.indexOf(serverDim.fieldName) === -1){
            serverAppliedOptions = ['Overall'];
        }
    }
    
    return serverAppliedOptions;
}

const addExtraLogic = (serverFilters) => {
    serverFilters.forEach((element) => {
        element.options.forEach((option) => {
            option.value = true;
        });
        
    });
};

const setServerFilters = (stateCopy, serverFilters) => {
    addExtraLogic(serverFilters);
    stateCopy.serverFilters = serverFilters;
    stateCopy.appliedFilters = makeArrCopy(serverFilters);
    stateCopy.selectedFilters = makeArrCopy(serverFilters);
    stateCopy.totalSelectedFilters = getSelectedDimension(stateCopy.appliedFilters);
    return stateCopy;
};

//Reset all filters
const clearAllFilters = (stateCopy) => {
    addExtraLogic(stateCopy.appliedFilters);
    stateCopy.selectedFilters = makeArrCopy(stateCopy.appliedFilters);
    stateCopy.totalSelectedFilters = getSelectedDimension(stateCopy.appliedFilters);
    return stateCopy;
};

//Revert filter selection which has been not applied
const resetSelectedFilter = (stateCopy, filterIndex) => {
    stateCopy.appliedFilters = [...stateCopy.appliedFilters];
    stateCopy.appliedFilters[filterIndex].options = [
        ...stateCopy.appliedFilters[filterIndex].options
    ];
    stateCopy.appliedFilters[filterIndex].options.forEach((option) => {
        option.value = true;
    });
    stateCopy.selectedFilters = [...stateCopy.selectedFilters];
    stateCopy.selectedFilters[filterIndex].options = [
        ...stateCopy.selectedFilters[filterIndex].options
    ];
    stateCopy.selectedFilters[filterIndex].options.forEach((option, index) => {
        option.value = stateCopy.appliedFilters[filterIndex].options[index].value;
    });
    stateCopy.totalSelectedFilters = getSelectedDimension(stateCopy.appliedFilters);

    return stateCopy;
};

//Adding custom dimensions
const setGroupFilters = (stateCopy, groupFilters) => {
    stateCopy.appliedFilters = stateCopy.appliedFilters.filter((filter) => {
        return !filter.isCustom;
    })
    stateCopy.selectedFilters = stateCopy.selectedFilters.filter((filter) => {
        return !filter.isCustom;
    })
    stateCopy.serverFilters = stateCopy.serverFilters.filter((filter) => {
        return !filter.isCustom;
    })  
    if(groupFilters.length) {
        groupFilters.forEach((gFilter) => {
            const matchedGroup = stateCopy.serverFilters.find((serverFilter) => {
                return serverFilter.displayName === gFilter.displayName;
            })
            if(!matchedGroup){
                stateCopy.appliedFilters = [...stateCopy.appliedFilters, gFilter]
                stateCopy.selectedFilters = [...stateCopy.selectedFilters, gFilter]
                stateCopy.serverFilters = [...stateCopy.serverFilters, gFilter]
            }
        })
    }
    
    return stateCopy;
};

const updateFilterOnApply = (stateCopy, { filterIndex, isApplied }) => {
    if (isApplied) {
        stateCopy.appliedFilters = [...stateCopy.appliedFilters];
        stateCopy.appliedFilters[filterIndex].options = [
            ...stateCopy.appliedFilters[filterIndex].options
        ];
        stateCopy.appliedFilters[filterIndex].options.forEach((option, index) => {
            option.value = stateCopy.selectedFilters[filterIndex].options[index].value;
        });
    } else {
        stateCopy.selectedFilters = [...stateCopy.selectedFilters];
        stateCopy.selectedFilters[filterIndex].options = [
            ...stateCopy.selectedFilters[filterIndex].options
        ];
        stateCopy.selectedFilters[filterIndex].options.forEach((option, index) => {
            option.value = stateCopy.appliedFilters[filterIndex].options[index].value;
        });
    }

    stateCopy.totalSelectedFilters = getSelectedDimension(stateCopy.appliedFilters);

    return stateCopy;
};

const checkAnyDefaultPreset = (localFilterOptions, defaultOptions) =>{
    return defaultOptions.find((defOption) =>{
        const matched = localFilterOptions.find((option) => {
            return option.label === defOption;
        });
        return matched;
    })
}
//For checking whether default filter required for portfolio or population
const getPortfolioFlags = (usedDimenstion, reactFilters, tableauFilters ) => {
    const isPortfolioUsed = usedDimenstion.includes(dimensionDefaultOptions.PORTFOLIO.name);
    //Portfolio used in report-no action
    if(isPortfolioUsed){
        return {
            isPortfolioDefaultFilterRequired: false, isPopulationDefaultFilterRequired: false
        }
    }
    const isPopulationUsed = usedDimenstion.includes(dimensionDefaultOptions.POPULATION.name);
    const isPortfolioInTableuFilters = tableauFilters.find((dim) => {
        return dim.filterName === dimensionDefaultOptions.PORTFOLIO.name;
    });
    const isPopulationInTableuFilters = tableauFilters.find((dim) => {
        return dim.filterName === dimensionDefaultOptions.POPULATION.name;
    });
    const reactPortFolioFilter = reactFilters.find((dim) => {
        return dim.key === dimensionDefaultOptions.PORTFOLIO.name;
    })
    const reactPopulationFilter = reactFilters.find((dim) => {
        return dim.key === dimensionDefaultOptions.POPULATION.name;
    })
    const tableauPortFolioFilter = tableauFilters.find((dim) => {
        return dim.filterName === dimensionDefaultOptions.PORTFOLIO.name;
    })
    const tableauPopulationFilter = tableauFilters.find((dim) => {
        return dim.filterName === dimensionDefaultOptions.POPULATION.name;
    })
    if(tableauPopulationFilter?.appliedValues?.length) {
        tableauPopulationFilter.appliedValues = removeNotFoundOptions(tableauPopulationFilter, reactPopulationFilter);
    }
    if(tableauPortFolioFilter?.appliedValues?.length) {
        tableauPortFolioFilter.appliedValues = removeNotFoundOptions(tableauPortFolioFilter, reactPortFolioFilter);
    }
    //If nothing selected in Tableau > means all is selected
    const isPortfolioAllSelected = tableauPortFolioFilter?.appliedValues &&
    (!tableauPortFolioFilter?.appliedValues?.length ||
    tableauPortFolioFilter.appliedValues.length === reactPortFolioFilter.options.length - 1);

    const isPopulationAllSelected = tableauPopulationFilter?.appliedValues &&
    (!tableauPopulationFilter.appliedValues?.length ||
        tableauPopulationFilter.appliedValues.length === reactPopulationFilter.options.length - 1);
    //Check if applying of portfolio default filter required
    const isPortfolioDefaultFilterRequired = !isPortfolioUsed && reactPortFolioFilter.options.length > 2 &&
    checkAnyDefaultPreset(reactPortFolioFilter.options, dimensionDefaultOptions.PORTFOLIO.options) &&
    (!isPortfolioInTableuFilters || isPortfolioAllSelected)
    //Check if applying of population default filter required
    const isPopulationDefaultFilterRequired = !isPortfolioUsed && reactPopulationFilter.options.length > 2 &&
    checkAnyDefaultPreset(reactPopulationFilter.options, dimensionDefaultOptions.POPULATION.options) &&
    ((!isPortfolioInTableuFilters || isPortfolioAllSelected) && !isPopulationUsed && (!isPopulationInTableuFilters ||isPopulationAllSelected))

    return {
        isPortfolioDefaultFilterRequired, isPopulationDefaultFilterRequired
    }
}

//Apply default filter for portfolio and population
const applyDefaultOptions = (localFilter, defaultOptions) =>{
    localFilter.options.forEach((option) => {
        option.value = defaultOptions.includes(option.label);
    });
}

//Map react filter with tableau filter
const mapLocalWithServerFilter = (localFilter, macthedServerFilter) =>{
    localFilter.options.forEach((localOption) =>{
        const matchedServerOption = macthedServerFilter.appliedValues.find((serverOption) => {
            return serverOption === localOption.label
        })
        localOption.value = matchedServerOption ? true : false;
    })
}

//For the dimension which has been in report- add overall option
//If not used in report - remove overall option
const checkAndAddOverall = (localFilter, macthedUsedFilter) =>{
    if(macthedUsedFilter && nonOverallFilters.indexOf(localFilter.key) === -1){
        const hasOverall = localFilter.options.find((option) =>{
            return option.label === 'Overall';
        })
        if(!hasOverall){
            localFilter.options.push({
                label: 'Overall',
                key: 'Overall',
                value: false
            })
        }
    }else{
        const overallIndex = localFilter.options.findIndex((option) =>{
            return option.label === 'Overall';
        })
        if(overallIndex > -1){
           localFilter.options.splice(overallIndex, 1);
        }
    }
}
//For updating state based on Tableau selected filters
//serverAppliedFilters: Tableau applied filter,usedDimenstion: used dimension name in report
const setServerAppliedFilters = (stateCopy, { serverAppliedFilters, usedDimenstion, isApplyFilterRequired}) => {
        stateCopy.selectedFilters = [...stateCopy.selectedFilters];
        stateCopy.appliedFilters = [...stateCopy.appliedFilters];

        const {isPortfolioDefaultFilterRequired, isPopulationDefaultFilterRequired} =
                getPortfolioFlags(usedDimenstion, stateCopy.appliedFilters, serverAppliedFilters);
        
        stateCopy.appliedFilters.forEach((localFilter, index) =>{
            const macthedServerFilter = serverAppliedFilters.find((serverFilter) => {
                return serverFilter.filterName === localFilter.key
            })
            if(macthedServerFilter?.appliedValues.length) {
                macthedServerFilter.appliedValues = removeNotFoundOptions(macthedServerFilter, localFilter);
            }
            
            const macthedUsedFilter = usedDimenstion.find((usedFilter) => {
                return usedFilter === localFilter.key;
            })

            checkAndAddOverall(localFilter, macthedUsedFilter);
            //Portfolio
            if(localFilter.key === dimensionDefaultOptions.PORTFOLIO.name && isPortfolioDefaultFilterRequired) {
                applyDefaultOptions(localFilter, dimensionDefaultOptions.PORTFOLIO.options);
                if(!isApplyFilterRequired) {
                    stateCopy.applySelectedFilter =true;
                }
            //POPULATION
            } else if(localFilter.key === dimensionDefaultOptions.POPULATION.name && isPopulationDefaultFilterRequired) {
                applyDefaultOptions(localFilter, dimensionDefaultOptions.POPULATION.options);
                if(!isApplyFilterRequired) {
                    stateCopy.applySelectedFilter =true;
                }
            //Check and map react filter with Tableau filter
            } else if(
                macthedServerFilter?.appliedValues?.length &&
                (macthedServerFilter.appliedValues.indexOf('Overall') === -1 || macthedUsedFilter) &&
                (macthedServerFilter.appliedValues.length !== localFilter.options.length - 1)){
                    mapLocalWithServerFilter(localFilter, macthedServerFilter)
            }else{
                //Mark all selected in React filter
                resetOptions(localFilter.options);
            }
            
            localFilter.options = [...localFilter.options]
            stateCopy.appliedFilters[index] = {...localFilter}
        })
        stateCopy.selectedFilters = makeArrCopy(stateCopy.appliedFilters);
        stateCopy.totalSelectedFilters = getSelectedDimension(stateCopy.appliedFilters);

    return stateCopy;
};

//On selection or deselection of react filter - update store
const updateSelectedFilters = (stateCopy, { filterIndex, optionIndex, value }) => {
    stateCopy.selectedFilters[filterIndex].options = [
        ...stateCopy.selectedFilters[filterIndex].options
    ];
    stateCopy.selectedFilters[filterIndex].options[optionIndex].value = value;

    if (optionIndex === 0) {
        stateCopy.selectedFilters[filterIndex].options.forEach((option) => {
            option.value = value;
        });
    } else {
        stateCopy.selectedFilters[filterIndex].options[0].value = false;
        let totalSelected = 0;
        stateCopy.selectedFilters[filterIndex].options.forEach((option) => {
            if (option.value) {
                totalSelected++;
            }
        });
        if (totalSelected === stateCopy.selectedFilters[filterIndex].options.length - 1) {
            stateCopy.selectedFilters[filterIndex].options[0].value = true;
        }
    }
    stateCopy.totalSelectedFilters = getSelectedDimension(stateCopy.appliedFilters);
    return stateCopy;
};
// To set flag for trigger apply filter
const triggerApplyFilter = (stateCopy, applyFilter) => {
    stateCopy.applySelectedFilter = applyFilter;
    return stateCopy;
}

const dimensionFilters = (state = { ...initialState }, { type, payload }) => {
    switch (type) {
        case 'SET_SERVER_FILTERS':
            return setServerFilters({ ...state }, payload);
        case 'UPDATE_FILTER_OPTION':
            return updateSelectedFilters({ ...state }, payload);
        case 'UPDATE_FILTER_APPLY':
            return updateFilterOnApply({ ...state }, payload);
        case 'SET_SERVER_APPLIED_FILTERS':
            return setServerAppliedFilters({ ...state }, payload);
        case 'SET_TABLEAU_GROUP':
                return setGroupFilters({ ...state }, payload);
        case 'FILTER_CLEAR_ALL':
            return clearAllFilters({ ...state });
        case 'RESET_SELECTED_FILTER':
            return resetSelectedFilter({ ...state }, payload);
        case 'SET_APPLY_FILTER':
            return triggerApplyFilter({ ...state }, payload);
        case 'DELETE_ALL_FILTERS':
            return { ...initialState };
        default:
            return state;
    }
};
export default dimensionFilters;
