import React from 'react';
import { Row, Col, } from 'react-bootstrap';
import './Audit.scss';
import UserAccessData from './UserAccessData';
import {UserAccessData as Data } from "../types/auditTypes";


type Props = {
  userAccessData : Array<Data>,
  setCurrentPage: (currentPage: number) => void,
  setRowsPerPage: (rowsPerPage: number) => void
}
const UserAccess = (props:Props) => {

  return (
    <>

      <Row className='mt-2 audit' style={{ height: '80vh' }}>
        <Col className="audit-content">
          <Row className=" w-100 h-90"><Col xs={12}><UserAccessData userAccessData={props.userAccessData} setCurrentPage= {props.setCurrentPage} setRowsPerPage = {props.setRowsPerPage} /></Col></Row>

        </Col>
      </Row>
    </>

  )
}

export default UserAccess