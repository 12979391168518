import alertAction from './alertAction'
import userAction from './userAction'
import profileAction from './profileAction'
import bankAction from './bankAction'
import loaderAction from './loaderAction'
import dimensionFiltersAction from './dimensionFiltersAction'
import shareDashboardAction from './shareDashboardAction'
import createDashboardAction from './createDashboardAction'
import confirmationModelAction from './confirmationModelAction'
import auditAction from './auditAction'
const allActions = {
    alertAction,
    userAction,
    profileAction,
    bankAction,
    dimensionFiltersAction,
    loaderAction,
    shareDashboardAction,
    createDashboardAction,
    confirmationModelAction,
    auditAction
}

export default allActions