import React, { useState, useEffect } from 'react';
import {  useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { TypeServerPayloadTenant, TypeTenant } from '../../../types/types';
import { RootState } from '../../../store/store';
import { getAlltenant } from './dashboardShareUtils';
interface Props {
    siteName: string;
    profileName: string;
    onProfileChange: (selectedTenant:TypeServerPayloadTenant[]) => void;
}

function TenantProfileSelection({ onProfileChange, siteName, profileName }: Props) {
    const [tenantList, setTenantList] = useState<TypeTenant[]>([]);
    const [profileList, setProfileList] = useState<string[]>([]);
    const [currentTenant, setCurrentTenant] = useState(0);
    const [currentProfile, setCurrentProfile] = useState(0);
    const allProfile = useSelector((state: RootState) => {
        return state.user.allProfile;
    });

    useEffect(() => {
        if(allProfile) {
            const allTenant = getAlltenant(allProfile,siteName, profileName)
            if(allTenant.length) {
                setTenantList(allTenant);
                setProfileList(allTenant[0]?.options);
                const selectedTenant = [{
                    siteName: allTenant[0].id,
                    profiles: [
                    {
                        name: allTenant[0].options[0]
                    }
                    ]
                }]
                onProfileChange(selectedTenant);
            }
            
        }
        
    }, [allProfile]);

    useEffect(() => {
        if (tenantList.length) {
            setProfileList(tenantList[currentTenant].options);
            setCurrentProfile(0);
        }
    }, [currentTenant]);

    useEffect(() => {
        if (tenantList.length) {
            const selectedTenant = [{
                siteName: tenantList[currentTenant].id,
                profiles: [
                  {
                    name: tenantList[currentTenant].options[currentProfile]
                  }
                ]
            }]
            onProfileChange(selectedTenant);
        }
    }, [currentTenant, currentProfile]);

    return (
        <div className="tenant-droptdowns single-profile">
           {tenantList.length ?  <>
            <div className="drop-down-holder">
                <span>Tenant</span>
                <Form.Select
                    value={currentTenant}
                    size="sm"
                    onChange={(event) => {
                        setCurrentTenant(Number(event.target.value));
                    }}>
                    {tenantList.map((tenant, index) => {
                        return (
                            <option key={tenant.label} value={index}>
                                {tenant.label}
                            </option>
                        );
                    })}
                </Form.Select>
            </div>
            <div className="drop-down-holder">
                <span>Profile</span>
                <Form.Select
                    value={currentProfile}
                    size="sm"
                    onChange={(event) => {
                        setCurrentProfile(Number(event.target.value));
                    }}>
                    {profileList.map((profile, index) => {
                        return (
                            <option key={profile} value={index}>
                                {profile}
                            </option>
                        );
                    })}
                </Form.Select>
            </div></> : <div>No other profiles available</div>}
          
        </div>
    );
}
export default TenantProfileSelection;
