import axios from 'axios';
import { app_config, errorCode } from './constant';
import { getAuthObject, getTenant } from './user.service';
import { addLogs, globalLogout, openSamlSignout } from './utils';

const API_URL = process.env.REACT_APP_API_URL;
const AUDIT_API_URL = process.env.REACT_APP_AUDIT_API_URL;

let apiFailureEventFunction;


function responseHanlder(response, reject) {
    if (response.response.status === 504) {
        reject(errorCode.apiTimeout);
    } else {
        reject(response?.response?.data?.serviceMessage || response);
    }
}


const callApi = async (apiObj) => {
    const AuthObj = getAuthObject();
    await AuthObj.currentSession()
        .then((userToken) => {
                apiObj.headers = {
                    Authorization: userToken.idToken.jwtToken ,
                    'Content-Type': 'application/json'

                };
        })
        .catch(() => {
                openSamlSignout();
        });

    return new Promise(function (resolve, reject) {
        axios(apiObj)
            .then((res) => {
                resolve(res.data);
            })
            .catch((res) => {
                apiFailureEventFunction && apiFailureEventFunction(res, apiObj.name);
                responseHanlder(res, reject);
            });
    });
};


const callUnsubscribeApi = async (apiObj) => {
    return new Promise(function (resolve, reject) {
        axios(apiObj)
            .then((res) => {
                resolve(res.data);
            })
            .catch((res) => {
                apiFailureEventFunction && apiFailureEventFunction(res, apiObj.name);
                responseHanlder(res, reject);
            });
    });
};

const callAuditApi = async (apiObj) => {
    const AuthObj = getAuthObject();
    await AuthObj.currentSession()
        .then((userToken) => {
            apiObj.headers = {
                Authorization: "Bearer " + userToken.idToken.jwtToken,
                'Content-Type': 'application/json',
                'x-api-key':'Argus2.0_Platform_API_'+getTenant()
                
                
                           
                        };
        })
        .catch( async(e) => {
            addLogs('signout', `API service::currentAuthenticatedUser failed >> ${e}`)
            globalLogout(AuthObj);
            });

    return new Promise(function (resolve, reject) {
        axios(apiObj)
            .then((res) => {
                resolve(res.data);
            })
            .catch((res) => {
                
                if(res?.response?.data?.message)
                reject(res.response.data.message);
            else 
            reject("Unknown error occured");
            });
    });
};

export const getDashboardList = (bankId, profileName, startNumber, lastNumber, reportType) => {
    return callApi({
        name: 'Dashboard list',
        url: `${
            API_URL
        }/${reportType}/bank/${bankId}/profile/${profileName}?offset=${startNumber}&limit=${lastNumber}`,
        method: 'get'
    });
};
export const getDashboardListByFilter = (bankId, profileName, startNumber, lastNumber, reportType, type) => {
    return callApi({
        name: 'Dashboard list',
        url: `${
            API_URL
        }/${reportType}/bank/${bankId}/profile/${profileName}?offset=${startNumber}&limit=${lastNumber}&filter=${type}`,
        method: 'get'
    });
};
export const getSignalList = (bankId, profileName, startNumber, lastNumber, type) => {
    return callApi({
        name: 'Signal list',
        url: `${
            API_URL
        }/signals/bank/${bankId}/profile/${profileName}?offset=${startNumber}&limit=${lastNumber}&filter=${type}`,
        method: 'get'
    });
};

export const getSignalDate = (bankId, profileName, startNumber, lastNumber, workbookId) => {
    return callApi({
        name: 'Signal date',
        url: `${
            API_URL
        }/signals/bank/${bankId}/profile/${profileName}?workbookid=${workbookId}&offset=${startNumber}&limit=${lastNumber}`,
        method: 'get'
    });
};
export const getTicket = (bankId) => {
    return callApi({
        name: 'Tableau ticket',
        url: `${app_config.TRUSTED_TOKEN_API}/trusted?target_site=${bankId}`,
        method: 'post'
    });
};

export const addFavorite = (siteName, profileId, userId, workBookId) => {
    return callApi({
        name: 'Add favourite',
        url: `${
            API_URL
        }/dashboard/bank/${siteName}/profile/${profileId}/favouritedashboard`,
        method: 'post',
        data: { siteName, userId, workBookId, profileId }
    });
};

export const syncMetadataTableCreate = (bankId, profileId, workBookName, signalType) => {
    const postData = signalType ? { workBookName, signalType } : { workBookName }
    return callApi({
        name: 'Sync create dashboard',
        url: `${API_URL}/dashboard/bank/${bankId}/profile/${profileId}`,
        method: 'post',
        data: postData
    });
};
export const syncMetadataTableEdit = (bankId, profileId, workbookId) => {
    return callApi({
        name: 'Sync update dashboard',
        url: `${
            API_URL
        }/dashboard/bank/${bankId}/profile/${profileId}/workbook/${workbookId}`,
        method: 'put',
        data: {}
    });
};
export const getDimensionFilters = (bankId, profileId, workBookId, workBookName, workSheetName) => {
    const payload = workBookId ? { workBookId } : { workBookName, workSheetName };
    return callApi({
        name: 'Dashboard filter',
        url: `${API_URL}/dashboard/bank/${bankId}/profile/${profileId}/dashboardfilters`,
        method: 'post',
        data: payload
    });
};
export const searchUser = (bankId, profileId) => {
    return callApi({
        name: 'Search user',
        url: `${API_URL}/dashboard/bank/${bankId}/profile/${profileId}/user`,
        method: 'get'
    });
};

export const downloadDashboardSignalData = (bankId, profileId) => {
    return callApi({
        name: 'Download dashboard and Signal Data',
        url: `${API_URL}/user/bank/${bankId}/profile/${profileId}/dashboardsignaldata`,
        method: 'get'
    });
};

export const shareDashboard = (bankId, profileId, workbookId, userList) => {
    return callApi({
        name: 'Dashboard share',
        url: `${
            API_URL
        }/dashboard/bank/${bankId}/profile/${profileId}/workbook/${workbookId}/permissions`,
        method: 'put',
        data: { userList: userList }
    });
};

export const deleteDashboard = (bankId, profileId, workbookId) => {
    return callApi({
        name: 'Dashboard delete',
        url: `${
            API_URL
        }/dashboard/bank/${bankId}/profile/${profileId}/workbook/${workbookId}`,
        method: 'delete'
    });
};

export const multiTenantshareHandler = (bankId, profileId, multiTenantPayload) => {
    return callApi({
        name: 'Dashboard multi-tenant share',
        url: `${API_URL}/dashboard/bank/${bankId}/profile/${profileId}/publish`,
        method: 'post',
        data: multiTenantPayload
    });
};

export const checkDatasourceAlligned = (bankId, profileId, payload) => {
    return callApi({
        name: 'Datasource map',
        url: `${API_URL}/dashboard/bank/${bankId}/profile/${profileId}/datasourcemap`,
        method: 'post',
        data: payload
    });
};
export const getDataSources = (bankId, profileId, tenantList) => {
    return callApi({
        name: 'Get datasource',
        url: `${API_URL}/dashboard/bank/${bankId}/profile/${profileId}/searchdatasets`,
        method: 'post',
        data: { tenantDataSets: tenantList }
    });
};

export const getTenants = (bankId, profileId, workbookId, userList) => {
    return callApi({
        name: 'Get tenants',
        url: `${
            API_URL
        }/dashboard/bank/${bankId}/profile/${profileId}/workbook/${workbookId}/tenants`,
        method: 'get',
        data: { userList: userList }
    });
};

export const setLastAccessed = ( bankId, lastusedProfile, index) => {
    return callApi({
        name: 'Set profile',
        url: `${
            API_URL
        }/dashboard/bank/${bankId}/profile/${lastusedProfile}/userpreferences`,
        method: 'post',
        data: { bankId, lastusedProfile, index }
    });
};

export const getLastPreference = (bankname, bankProfile) => {
    return callApi({
        name: 'Get profile',
        url: `${
            API_URL
        }/dashboard/bank/${bankname}/profile/${bankProfile}/userpreferences`,
        method: 'get'
    });
};

export const getSystemNotification = (bankId, profileName) => {
    return callApi({
        name: 'Get system notification',
        url: `${API_URL}/dashboard/bank/${bankId}/profile/${profileName}/message`,
        method: 'get'
    });
};

export const supportPages = (bankId, profileName, pageType) => {
    return callApi({
        name: 'Support page',
        url: `${API_URL}/dashboard/bank/${bankId}/profile/${profileName}/${pageType}`,
        method: 'get'
    });
};

export const getLastLoginData = () => {
    return callApi({
        name: 'Get Last login of user',
        url: `${API_URL}/user/lastlogin`,
        method: 'get'
    });
}

export const updateLastLoginData = () => {
    return callApi({
        name: 'Update Last login of user',
        url: `${API_URL}/user/lastlogin`,
        method: 'post'
    });
}

export const unsubscribe = (id) => {
    return callUnsubscribeApi({
        name: 'Unsubscribe page',
        url: `${AUDIT_API_URL}/unsubscribe?id=${id}`,
        method: 'get'
    });
};

export const getTenantId= ( )=> {
    return callAuditApi({
        url:`${AUDIT_API_URL}/app/tenants`,
        method:'get'
    })
}

export const setApiFailureEventFunction= ( logFunction)=> {
    apiFailureEventFunction = logFunction;
}

//Audit - User Info(User Activity) API's

export const getAuditList = (data) => {
    return callAuditApi({
        url:`${AUDIT_API_URL}/audit/list`,
        method:'post',
        data
    })
}

//Audit - User Info(User Access) API's

export const getUserAccessList =(bankId) => {
    return callApi({
        name: 'User Access',
        url: `${
            API_URL
        }/user/bank/${bankId}/useraccessinfo`,
        method: 'get'
    });
};


export const getAuditDetails = (data) => {
    return callAuditApi({
        url:`${AUDIT_API_URL}/audit/detail`,
        method:'post',
        data
    })
}
export const downloadLogs = (data) => {
    return callAuditApi({
        url:`${AUDIT_API_URL}/events/download`,
        method:'post',
        data
    })
}

export const downloadExcel = (url) => {
    window.open(url);
}
export const getPresignedUrl =(data)=> {
  return callAuditApi({
    url:`${AUDIT_API_URL}/events/status`,
    method:'post',
    data
  })
}

export const addAudit =(data)=> {
    return callAuditApi({
      url:`${AUDIT_API_URL}/audit`,
      method:'post',
      data
    })
  }
  



  export const getAttachmentUrl = (data) => {
    return callAuditApi({
        url:`${AUDIT_API_URL}/events/attachment`,
        method:"POST",
        data
    })}


export const getProfileRoleMnemonics= ( )=> {
    return callAuditApi({
        name: 'Profile Role Mnemonics list',
        url:`${AUDIT_API_URL}/app/mnemonics`,
        method:'get'
    })
}