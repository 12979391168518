import React, { useState, useEffect } from 'react';
import { Container, Table, Row, Col } from 'react-bootstrap';
import { TypeGlossaryItems } from '../../../types/types';
import './SupportPage.scss';
import UseSupportPage from './useSupportPage';
import Dropdown from 'react-bootstrap/Dropdown';
import { sortByKey } from '../../../services/utils';
import i18nLabels from '../../../services/i18n.service';



function Glossary() {
    const [glossaryList, setGlossaryList] = useState<Array<TypeGlossaryItems> | null>(null);
    const [orignalGlossaryList, setGlossaryOrignalList] = useState<Array<TypeGlossaryItems> | null>(
        null
    );
    const [sortOrderByName, setSortOrderByName] = useState<boolean | null>(null);
    const [searchInput, setSearchInput] = useState('');

    const onSortByNameClick = () => {
        if (glossaryList?.length) {
            let copyUsers = [...glossaryList];
            copyUsers = sortByKey(!sortOrderByName, copyUsers, 'name');
            setSortOrderByName(!sortOrderByName);
            setGlossaryList(copyUsers);
        }
    };

    const getView = (response) => {
        setGlossaryList(response.glossaryItems);
        setGlossaryOrignalList(response.glossaryItems);
    };

    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
    };

    useEffect(() => {
        if (searchInput.length > 0) {
            if (orignalGlossaryList?.length) {
                const searchResult = orignalGlossaryList.filter((views) => {
                    return views.name.toLowerCase().includes(searchInput.toLowerCase());
                });
                setGlossaryList(searchResult);
            }
        } else {
            setGlossaryList(orignalGlossaryList);
        }
    }, [searchInput]);

    UseSupportPage('glossary', getView);

    if (!glossaryList) {
        return <></>;
    }

    return (
        <Container fluid-xxl id="page-container" className="dashboard-container container-xxl">
            <Row>
                <Col>
                    <h2 className="text-left support-title">{i18nLabels.glossary_title}</h2>
                </Col>
            </Row>

            <div id="support-pages" className="rounded-3 p-4 mx-2 glossary">
                <Table hover className="p-4">
                    <thead>
                        <tr>
                            <th>
                                {i18nLabels.glossary_col_heading_name}
                                {sortOrderByName === true ? (
                                    <span
                                        className="material-symbols-outlined"
                                        onClick={onSortByNameClick}>
                                        arrow_downward
                                    </span>
                                ) : (
                                    <span
                                        className="material-symbols-outlined"
                                        onClick={onSortByNameClick}>
                                        arrow_upward
                                    </span>
                                )}
                                <Dropdown className="search-option">
                                    <Dropdown.Toggle
                                        variant="link outline-info"
                                        className="pt-1 pe-0"
                                        id="dropdown-basic">
                                        <span className="material-symbols-outlined">
                                            filter_alt
                                        </span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu align="start" className="search-panel border-0">
                                        <input
                                            type="search"
                                            className="search-box"
                                            placeholder="Search here"
                                            onChange={handleChange}
                                            value={searchInput}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </th>
                            <th>{i18nLabels.glossary_col_heading_description}</th>
                        </tr>
                    </thead>
                    {glossaryList?.length ? (
                        <tbody>
                            {glossaryList?.map != null &&
                                glossaryList.map((views: TypeGlossaryItems) => {
                                    return (
                                        <tr key={views.name}>
                                            <td width="30%"> {views.name}</td>
                                            <td width="70%"> {views.description}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    ) : (
                        <tr>
                            <td width="30%"> {i18nLabels.glossary_no_record}</td>
                            <td width="70%"> </td>
                        </tr>
                    )}
                </Table>
            </div>
        </Container>
    );
}
export default Glossary;
