import { useState, useEffect } from 'react';
import { globalConstant } from '../../../services/constant';
const getContainerHeight = (screenType) => {
    const configHeight= globalConstant.tableauHeight[screenType];
    const screenHeight = window.innerHeight - configHeight.deduction;
    
    const tableauSize = screenHeight < configHeight.min ? configHeight.min : screenHeight;
    return tableauSize;
};
const useContainerSize = (screenType) => {
    const [vizHeight, setVizHeight] = useState(getContainerHeight(screenType));
    useEffect(() => {
        const resize = () => {
            setVizHeight(getContainerHeight(screenType));
        };
        window.addEventListener('resize', resize);
        return () => {
            window.removeEventListener('resize', resize);
        };
    }, []);

    return { vizHeight };
};

export default useContainerSize;
