const i18n = {
    eng:{
        all_dashboard: 'All Dashboard',
        dashboard_recent_dashboard_title: 'Recent Dashboards',
        dashboard_recent_signal_title: 'Recent Signals',
        dashboard_recent_dashboard_no_dashboard_info: 'No recent dashboards found',
        dashboard_recent_signal_no_signal_info: 'No recent signals found',
        dashboard_recent_dashboard_view_all_dashboard_link: 'View All',
        dashboard_share_instruction: 'Search to add users',
        dashboard_share_search_placeholder: 'Search by name, email or user group',
        dashboard_share_success_message: 'Successfully shared dashboard',
        dashboard_home_btn_go_dashboard: 'Go to Dashboard',
        dashboard_filters_no_filter: 'No filter available',
        dashboard_filters_btn_clear_all: 'Clear All',
        global_btn_apply: 'Apply',
        global_btn_cancel: 'Cancel',
        global_drop_down_select_option: 'Select Option',
        global_dropdown_placeholder: 'Cancel',
        profile_change_passowrd: 'Change Password',
        profile_change_signout: 'Signout',
        success_publish_message: 'Dashboard has been created successfully',
        no_data_source: 'No dataset available',
        dashboard_delete_confirmation: 'Are you sure you want to delete this dashboard?',
        success_delete_dashboard_message: 'Dashboard has been deleted successfully.',
        signout_message: 'Successfully Signed Out',
        timeout_message: 'For security, this session has been signed out',
        signout_btn_label: 'Back to Sign In',
        signin_error: 'Sorry, you do not have access to this application. To request access please reach out to ',
        footer_copyright_1: '© Copyright',
        footer_copyright_2: 'TransUnion LLC. All Rights Reserved.',
        footer_label_privacy_policy: 'Privacy Policy',
        switch_profile_title: 'Switch Profile',
        switch_profile_switch_btn: 'Switch',
        switch_profile_header_bank: 'Banks',
        switch_profile_header_profile: 'Profiles',
        signal_listing_title: 'Signals',
        signal_listing_tab_new: 'New Signals',
        signal_listing_tab_shared: 'Shared Signals',
        contact_info: 'Contact Info',
        contact_general_queries: 'General Queries',
        contact_general_query_heading: 'For any general queries regarding the platform please reach out to your relationship management team. This could include but is not limited to:',
        contact_general_query_list1: 'Data accuracy or methodology',
        contact_general_query_list2: 'How to use certain features within the platform',
        contact_general_query_list3: 'Error messages on the platform',
        contact_general_query_list4: 'Best practices for building dashboards',
        contact_general_query_list5: 'Requests for support in building new dashboards',
        contact_general_query_list6: 'Information on future product releases',
        contact_general_query_list7: 'Product information or pricing of additional data or features',
        contact_mail_label: 'mail',
        contact_email_address_label: 'Email Address',
        contact_phone: 'phone',
        contact_phone_number: 'Phone Number' ,
        contact_us: 'US:',
        contact_global: 'Global:',
        contact_login_access_label: 'Log-In and Access',
        contact_technical_queries: 'For any technical queries related to log-in and general access you can reach out directly to our product support team. This support would include:',
        contact_technical_query_list1: 'Password resets',
        contact_technical_query_list2: 'Requesting access for additional new users',
        contact_available_hours: 'Available Hours: Weekdays 10am - 6pm EDT',
        contact_respond_queries: 'We aim to respond to your queries by the next working day.',
        dashboard_filter_title: 'Filters',
        dashboard_view: 'View',
        workbook_not_preset: 'Workbook is not preset.',
        dashboard_create_title: 'Create Dashboard - Choose Data Source',
        dashboard_no_data_source: 'No Data Source',
        global_btn_create: 'Create',
        dashboard_pin_messgae: 'You can pin any of your dashboards to be displayed here for quick reference',
        no_dashboard_found: 'No dashboards found',
        dashboard_category1: 'Shared by Argus',
        dashboard_category2: 'Shared by Bank',
        dashboard_category3: 'My Dashboards',
        dashboard_home_create: 'Create Dashboard',
        dashboard_home_title: 'Dashboards',
        dashboard_download: 'Download',
        download_option_pdf: 'PDF',
        download_option_image: 'Image',
        download_option_ppt: 'PPT',
        download_option_data: 'Data',
        dashboard_edit: 'Edit',
        dashboard_share: 'Share',
        created_by: 'Created by:',
        session_time_out_stay_signin: 'Stay Signed In',
        session_time_out_alert1: 'For security reasons, you will be logged out in',
        session_time_out_alert2: 'minutes and any unsaved work will be lost.',
        pagination_next: 'Next',
        pagination_previous: 'Previous',
        pagination_showing: 'Showing',
        pagination_outof: 'out of',
        pagination_results: 'results',
        loading_msg: 'Loading...',
        yes: 'Yes',
        no: 'No',
        generic_error_msg: 'Something went wrong',
        header_switch_profile: 'Switch Profile',
        header_current_profile: 'Current Profile',
        header_signout: 'Sign out',
        faq: 'FAQs',
        tutorial_and_training: 'Tutorials and Training',
        glossary: 'Glossary',
        tableau_edit_warning_apply_filter: 'Apply Filter ',
        tableau_edit_warning_filter_adjustment: 'Some filters have been adjusted. To save all changes, please publish the dashboard.',
        tableau_edit_warning_filter_reapply_instruction: 'After making changes to dimensions in your dashboard, please click Reapply Filters to ensure they are configured correctly.',
        tableau_edit_warning_filter_new_tab_instruction: ' A new sheet has been added to the workbook. Please click on the "Publish" button to save the dashboard and enable data filtering.',
        tableau_edit_btn_reapply: 'Reapply Filters',
        tutorial_training_no_found: 'No Tutorials and Training Found',
        tutorial_training_title: 'Tutorials and Training',
        tutorial_training_col_heading_name: 'Name',
        tutorial_training_col_heading_description: 'Description',
        tutorial_training_date_created: 'Date created',
        glossary_title: 'Glossary',
        glossary_col_heading_name: 'Name',
        glossary_col_heading_description: 'Description',
        glossary_no_record: 'No Records Found',
        user_activity: "User Activity",
        user_access: "User Access",
        download_dashboard_data: " Download Dashboard Data",
        page_not_found: 'Requested page not available.',
        faq_title: 'Frequently Asked Questions',
        notify_user: 'Notify via Email',
        unsubscribe_header: 'Unsubscribe Request Processed',
        unsubscribe_backtohome: 'Back to Home',
        unsubscribe_body1: 'Please contact',
        unsubscribe_body2: 'if',
        unsubscribe_body3: 'you wish to start receiving notification emails again.'
    }
}

const language = 'eng';

const i18nLabels = i18n[language];

export default i18nLabels