
import { profile } from "console";
import { globalConstant } from "../../services/constant";
const removeAdminProfile = (profiles) =>{
    const profileList = profiles.split(',');
    return profileList.filter((profileName) =>{
        return profileName.toLowerCase().indexOf(globalConstant.hideProfile.toLowerCase()) === -1
    }).join(',');
}

const decodeMnemonics = (mnemonicsList: any, profiles:string) => {
    const profileList = profiles.split(',');
    const profileDecoded: string[] = [];
    for (const item of profileList) {
        if(item in mnemonicsList) {
        profileDecoded.push(mnemonicsList[item])
        }
  }
  return profileDecoded.toString();
}
const userProfile = (
    state = { email: '', profile: '', isInternal: '', given_name: '', family_name: '', roles: '', signal_banks: '', signal_roles: '', signal_profiles: '', 
        profile_mnemonics: null, role_mnemonics: null},
        { type, payload }

) => {
    switch (type) {
        case 'USER_PROFILE':
            return {
                ...state,
                email: payload.email,
                given_name: payload.given_name,
                family_name: payload.family_name,
                signal_banks: payload.sf_banks,
                profile: payload.isInternal == 'true'? decodeMnemonics(state.profile_mnemonics,removeAdminProfile(payload.profile)) : removeAdminProfile(payload.profile),
                signal_roles: payload.isInternal == 'true'? decodeMnemonics(state.role_mnemonics, payload.sf_roles) : payload.sf_roles,
                signal_profiles: payload.isInternal == 'true'? decodeMnemonics(state.profile_mnemonics, payload.sf_profiles): payload.sf_profiles,
                roles: payload.isInternal == 'true'? decodeMnemonics(state.role_mnemonics, removeAdminProfile(payload['custom:bankIdrole'])) : removeAdminProfile(payload['custom:bankIdrole'])
            };
        case 'PROFILE_MNEMONICS': 
            return {
                ...state,
                profile_mnemonics: payload.profiles,
                role_mnemonics: payload.roles
            };    
        default:
            return state;
    }
};
export default userProfile;
