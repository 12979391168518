import React from 'react';
import { TypeSearchUser } from '../../../types/types';
import { getUserTypeClassName } from '../../../services/utils';

interface Props {
    userList: Array<TypeSearchUser>;
    onUserSelect: (string) => void;
}
function SearchList({ userList, onUserSelect }: Props) {
    return (
        <div className="search-list-container">
            <ul className="search-user-list">
                {userList.map((user) => {
                    return (
                        <li
                            data-testid={user.userId}
                            key={user.userId}
                            onClick={() => {
                                onUserSelect(user);
                            }}>
                                <div>
                            {user.selected ? (
                                <span className="material-icons user-name-selected">check_circle</span>
                            ) : (
                                <span className="material-symbols-outlined user-name-check">
                                    check
                                </span>
                            )}
                            <span className="user-name">{user.userName}</span>
                            </div>
                            <div>
                            {user.userType ? (
                                <span
                                    className={
                                        `user-type ${getUserTypeClassName(user)}`
                                    }>
                                    {user.userType}
                                </span>
                            ) : null}</div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default SearchList;
