const userProfile = (payload) => {
    return {
        type: "USER_PROFILE",
        payload
    }
}

const profileMnemonics = (payload) => {
    return {
        type: "PROFILE_MNEMONICS",
        payload
    }
}

export default {
    userProfile,
    profileMnemonics
}