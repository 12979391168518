// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { globalConstant } from '../../../services/constant';
import { TypeAppliedFilter } from '../../../types/types';

const getTableauCustomFilters = async (sheet, col) => {
    let dimFormatOptions = [];
    return new Promise((resolve) => {
        sheet.getFiltersAsync().then(async (tableauFilters) => {
            const filterBaseDim = tableauFilters.find((appliedFilter) => {
                return col.fieldId.indexOf(appliedFilter.fieldName) > -1;
            });
            const filterGroupDim = tableauFilters.find((appliedFilter) => {
                return appliedFilter.fieldName === col.fieldName;
            });
            if(filterBaseDim) {
                if (filterBaseDim.appliedValues[0]?.value === 'Overall') {
                    await sheet.clearFilterAsync(filterBaseDim.fieldName);
                }
    
                if (!filterBaseDim.appliedValues.length) {
                    await sheet.applyFilterAsync(filterBaseDim.fieldName, [''], 'all');
                }
            }
            //If there is no filter available for custom group or base dimension has overall selected
            if (!filterGroupDim || !filterGroupDim.appliedValues.length || filterBaseDim.appliedValues[0].value === 'Overall') {
                await sheet.applyFilterAsync(col.fieldName, [], 'all');//Apply all filters
                await sheet.getFiltersAsync().then(async (udatedTableauFilters) => {
                    const groupDimFilter = udatedTableauFilters.find((appliedFilter) => {
                        return appliedFilter.fieldName === col.fieldName;
                    });
                    await groupDimFilter?.getDomainAsync().then(async (domainFilters) => {
                        dimFormatOptions = domainFilters.values.map((elem) => {
                            return {
                                label: elem.value,
                                value: true
                            };
                        });
                    });
                });
            }
            resolve(dimFormatOptions);
        });
    });
};

export const getCustomGroup = (sheet, customGroup) => {
        return sheet.getSummaryDataAsync().then(async (response) => {
            const dataColumns = response.columns;
            for (const [index, col] of dataColumns.entries()) {
                if (col.fieldId.indexOf('(group)') > -1) {
                    const dim = {
                        displayName: col.fieldName,
                        isCustom: true,
                        key: col.fieldName,
                        options: [
                            {
                                label: 'All',
                                value: true
                            }
                        ]
                    };
    
                    await getTableauCustomFilters(sheet, col).then((customOptions) => {
                        dim.options = [...dim.options, ...customOptions];
                    });
    
                    if (dim.options.length === 1) {
                        const dimOptions = new Set();
                        response.data.forEach((element) => {
                            dimOptions.add(element[index].value);
                        });
    
                        const dimArray = Array.from(dimOptions);
                        const dimFormatOptions = dimArray.map((elem) => {
                            return {
                                label: elem,
                                value: true
                            };
                        });
                        dimFormatOptions.reverse();
                        dim.options = [...dim.options, ...dimFormatOptions];
                    }
    
                    customGroup.push(dim);
                }
            }
        });
     
};
//Related to managing Calculated field used in Tableau -  not in use
export const getUsedDimensions = (summaryData, serverFiltersRef) =>{
    const usedDimenstion = [];
    summaryData.columns.forEach(({ fieldName }) => {
        usedDimenstion.push(fieldName);
    });
    const calculatedReferDim = getCalculatedFieldBaseDim(
        summaryData.columns,
        summaryData.data,
        serverFiltersRef
    );

    return [...usedDimenstion, ...calculatedReferDim];
}
//Related to managing Calculated field used in Tableau -  not in use
export const getCalculatedFieldBaseDim = (usedColumn, allData, serverFilters) => {
    const calculatedUsedDim = new Set();
    usedColumn.forEach((column, index) =>{
        const allOptions = [];
        allData.forEach((colData) =>{
            if(colData[index].value !== 'Overall') {
                allOptions.push(colData[index].value);
            }
        })
        if(column.fieldId.indexOf('(copy)') > -1){
            const originalDim =  serverFilters.find((serverFilter) => {
               const dimName  = serverFilter.key.toLowerCase();
               const usedDimName  = column.fieldId.toLowerCase();
               return usedDimName.indexOf(dimName) > -1
            })
            if(originalDim){
                calculatedUsedDim.add(originalDim.key);
            }
        }
        if(column.fieldId.indexOf('Calculation_') > -1 && allOptions.length){
            const originalDim =  serverFilters.find((serverFilter) => {
                let isAllFound = true;

                allOptions.forEach((segOption) =>{
                        const matched = serverFilter.options.find((option) =>{
                            return option.label === segOption
                        })
                        if(!matched){
                            isAllFound = false
                        }
                })
                return isAllFound;
            })
            if(originalDim){
                calculatedUsedDim.add(originalDim.key);
            }
        }

        if(column.fieldId.indexOf('[Parameters]') > -1){
             const originalDim =  serverFilters.find((serverFilter) => {
                const parameterValue  = allOptions[0].toLowerCase();
                const dimName  = serverFilter.key.toLowerCase();
                const dimMapValue  = globalConstant.filterPointerMap[parameterValue]
                return (dimName.indexOf(parameterValue) > -1) || (dimMapValue && dimName.indexOf(dimMapValue))
             })
             if(originalDim){
                 calculatedUsedDim.add(originalDim.key);
             }
         }
    })
    return calculatedUsedDim
}
//Related to managing Calculated field used in Tableau -  not in used
export const getTranformedFilters = (selectedFilters) => {
    const dimFilters = selectedFilters.map((filter) => {
        const dimFilter: TypeAppliedFilter = {
            key: filter.key,
            hasGroup: filter.hasGroup,
            isCustom: filter.isCustom,
            options: []
        };
        filter.options.forEach((element) => {
            if (element.value) {
                dimFilter.options.push(element.label);
            }
        });
        return dimFilter;
    });
    return dimFilters;
};
