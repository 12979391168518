import React, {useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './confirmationModel.scss';
import Alert from 'react-bootstrap/Alert';
import Button from '../button/Button';
import allActions from '../../../../store/actions';

import type { RootState } from '../../../../store/store';
import { deleteDashboard } from '../../../../services/api.service';
import { getAlertDetails } from '../../../../services/utils';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import useAddAudit from '../../../../hooks/useAddAudit';
import { auditEvents } from '../../../../services/auditActions';
import i18nLabels from '../../../../services/i18n.service';

type Props = {
    isHeaderVisible: boolean;
};

function ConfirmationModel({ isHeaderVisible }: Props) {
    const dispatch = useDispatch();
    const refModal = useRef<HTMLDivElement>(null);
    useOnClickOutside(refModal, () => onNoHandler());
    const confirmationModelState = useSelector((state: RootState) => {
        return state.confirmationModel;
    });
    const { siteName, profileName } = useSelector((state: RootState) => {
        return state.user;
    });
    const {addAuditEvent} = useAddAudit();
    const onNoHandler = () => {
        dispatch(allActions.confirmationModelAction.hideConfirmationModel());
    };
    const onYesHandler = () => {
        dispatch(allActions.loaderAction.showLoader());
        dispatch(allActions.confirmationModelAction.hideConfirmationModel());
        deleteDashboard(siteName, profileName, confirmationModelState.workbookId)
            .then((response) => {
                const notificationObj = {
                    message: response.serviceMessage.message,
                    code: 'Success',
                    type: 'success',
                    showCode: true
                }
                const logsDetails = {
                    'Dashboard': confirmationModelState.workBookName
                }
                try{
                    addAuditEvent(auditEvents.dashboard.delete, `${confirmationModelState.workBookName} deleted`, 'SUCCESS', logsDetails)
                }catch(err) {
                    console.log(err)
                }
                dispatch(allActions.alertAction.showAlert(notificationObj));
                dispatch(allActions.userAction.reloadDashboard(true));
                
            })
            .catch((response) => {
                try{
                    addAuditEvent(auditEvents.dashboard.delete, `${confirmationModelState.workBookName} delete`, 'FAILED')
                }catch(err) {
                    console.log(err)
                }
                dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
            })
            .finally(() => {
                dispatch(allActions.loaderAction.hideLoader());
            });
    };
    if (!confirmationModelState.isOpen) {
        return null;
    }
    return (
        <div className={`confirmation-model ${isHeaderVisible ? 'top-position' : ''}`} ref={refModal}>
            <Alert variant="success" className="border-0 rounded-bottom">
                <p className="mb-0 mt-2">
                    <span className="material-icons">warning</span>
                    {confirmationModelState.confirmationMessage}
                </p>
                <div className="confirmation-btn-holder">
                    <Button onClick={onYesHandler}>{i18nLabels.yes}</Button>

                    <Button type="secondry" onClick={onNoHandler}>
                        {i18nLabels.no}
                    </Button>
                </div>
            </Alert>
        </div>
    );
}
export default ConfirmationModel;
