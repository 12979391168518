import React, {  useState, useRef } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "./Audit.scss";

type Props= {
onFilterChanged: (object:object)=> void;
}
const DateFilter = ({  onFilterChanged }:Props) => {
	const [operator, setOperator] = useState("equals");
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [expanded, setExpanded] = useState(false);
	const lang = window.navigator.language;
	moment.locale(lang);
	let formatL = moment.localeData().longDateFormat("L");
	formatL = formatL.toLowerCase().replace("mm", "MM");
	
	const handleCalendarOpen = () => {
		setExpanded(true);
	};

	const handleStartChange = (date) => {
		setStartDate(date);
	};

	const handleEndChange = (date) => {
		setEndDate(date);
	};

	const resetFilter = (event) => {
		setStartDate(null);
		setOperator("equals");
		const filterModel = {
			type: "date",
			filterType: operator,
			dateFrom: null,
			dateTo: null,
		};
		event.preventDefault();
		onFilterChanged(filterModel);
	};

	const applyFilter = (event) => {
		setExpanded(false);
		const filterModel = {
			type: "date",
			filterType: operator,
			dateFrom: startDate,
			dateTo: operator === "inRange" ? endDate : null,
		};
		event.preventDefault();
		onFilterChanged(filterModel);
	};
	function handleCalendarClose() {
		setExpanded(false);
	}

	const portalDivRef = useRef(null);


	return (
		<div>
			<div
			
				className="shadow abcd ag-custom-date-filter ag-input-field-input ag-text-field-input flexDisplay"
			>
				<div className="ag-filter ag-custom-date-filter" id="shadowRoot">
					<div>
						<form className="ag-filter-wrapper ag-focus-managed">
							<div
								className="ag-filter-body-wrapper ag-simple-filter-body-wrapper ag-wrapper"
								ref={portalDivRef}
							>
								<div
									className="ag-picker-field ag-labeled ag-label-align-left "
									role="presentation"
								>
									<div className="ag-wrapper ag-picker-field-wrapper ag-custom-filter">
										<select
											value={operator}
											className="ag-picker-field-wrapper ag-picker-field-display ag-select ag-filter-select ag-picker-field-icon"
											onChange={(e) => setOperator(e.target.value)}
										>
											<option value="equals">Equals</option>
											<option value="lessThan">Less Than</option>
											<option value="greaterThan">Greater Than</option>
											<option value="inRange">In Range</option>
										</select>
									</div>
								</div>
								<div
									className="ag-label ag-hidden"
									aria-hidden="true"
									role="presentation"
								></div>
								<div className="ag-filter-body">
									<div
										className={`ag-filter-from ag-filter-date-from  ${expanded ? 'expanded':'not-expanded'}`}
										
									>
										<div className="ag-filter-filter">
											<div className="ag-date-filter ag-labeled ag-label-align-left ag-text-field ag-input-field">
												<div
													className="ag-input-field-label ag-label ag-hidden ag-text-field-label"
													aria-hidden="true"
													role="presentation"
												></div>
												<div className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper">
													{operator === "inRange" ? (
														<div>
															<div
																className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper "
																role="presentation"
															>
																<DatePicker
																	selected={startDate}
																	onChange={(date) => handleStartChange(date)}
																	className="ag-input-field-input ag-text-field-input ag-filter-to ag-filter-date-to ag-wrapper ag-input-wrapper ag-text-field-input-wrapper ag-custom-date-filter__date-picker"
																	showYearDropdown
																	dateFormat={formatL}
																	placeholderText={formatL}
																	scrollableYearDropdown
																	role="presentation"
																	isClearable
																	onCalendarClose={handleCalendarClose}
																	onCalendarOpen={handleCalendarOpen}
																/>
															</div>
															<div
																className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper"
																role="presentation"
															>
																<DatePicker
																	selected={endDate}
																	onChange={(date) => handleEndChange(date)}
																	className="ag-input-field-input ag-text-field-input ag-filter-to ag-filter-date-to ag-wrapper ag-input-wrapper ag-text-field-input-wrapper ag-custom-date-filter__date-picker"
																	showYearDropdown
																	dateFormat={formatL}
																	placeholderText={formatL}
																	scrollableYearDropdown
																	role="presentation"
																	isClearable
																	onCalendarClose={handleCalendarClose}
																	onCalendarOpen={handleCalendarOpen}
																/>
															</div>
														</div>
													) : (
														<DatePicker
															className="ag-input-field-input ag-text-field-input ag-filter-to ag-filter-date-to ag-wrapper ag-input-wrapper ag-text-field-input-wrapper ag-custom-date-filter__date-picker"
															showYearDropdown
															selected={startDate}
															dateFormat={formatL}
															placeholderText={formatL}
															scrollableYearDropdown
															onChange={(date) => handleStartChange(date)}
															role="presentation"
															isClearable
															onCalendarClose={handleCalendarClose}
															onCalendarOpen={handleCalendarOpen}
														/>
													)}
													<div
														className="ag-filter-to ag-filter-date-to ag-hidden"
														aria-hidden="true"
													></div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="button-container" style={{ display: "flex" }}>
									<button
										className="ag-standard-button ag-filter-apply-panel-button custom-button"
										onClick={applyFilter}
									>
										Apply
									</button>
									<button
										className="ag-standard-button ag-filter-apply-panel-button custom-button"
										onClick={resetFilter}
									>
										Reset
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DateFilter;