import { useEffect } from 'react';
import './SupportPage.scss';
import { getProfile } from '../../../services/user.service';
import { supportPages } from '../../../services/api.service';
import allActions from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

import { getAlertDetails } from '../../../services/utils';

function UseSupportPage(pageType: string, setResponse) {
    const dispatch = useDispatch();
    const [siteName, profileName] = useSelector((state: RootState) => {
        return [state.user.siteName, state.user.profileName];
    });

    useEffect(() => {
        if (siteName && profileName) {
            dispatch(allActions.loaderAction.showLoader());
            supportPages(siteName, profileName || getProfile(), pageType)
                .then((response) => {
                    setResponse(response);
                })
                .catch((response) => {
                  
                    dispatch(allActions.alertAction.showAlert(getAlertDetails(response, 'danger')));
                })
                .finally(() => {
                    dispatch(allActions.loaderAction.hideLoader());
                });
        }
    }, [profileName]);
}
export default UseSupportPage;
