// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useDispatch } from 'react-redux';
import allActions from '../../../store/actions';
import { TypeServerAppliedFilter } from '../../../types/types';
import { getCustomGroup } from './tableauUtils';
import { globalConstant } from '../../../services/constant';
import moment from 'moment';
import { addLogs } from '../../../services/utils';

const getWorksheets = (sheet) => {
    if (sheet.worksheets) {
        return sheet.worksheets;
    } else {
        sheet.initializeWithPublicInterfaces();
        return sheet.worksheetsImpl;
    }
};

const useSyncFilter = () => {
    const dispatch = useDispatch();

    const syncLocalFilters = async (sheet, isApplyFilterRequired = true) => {
        addLogs('filter-logs', 'Filter sync started');
        const customGroup = [];
        if (sheet.sheetType === 'worksheet') {
            await getCustomGroup(sheet, customGroup);
            dispatch(allActions.dimensionFiltersAction.setGroupFilters(customGroup));
        } else {
            const allPromise: Array<Promise<void>> = [];
            const worksheets = getWorksheets(sheet);
            for (const worksheet of worksheets) {
                allPromise.push(getCustomGroup(worksheet, customGroup));
            }
            await Promise.all(allPromise).then(() => {
                dispatch(allActions.dimensionFiltersAction.setGroupFilters(customGroup));
            });
        }
        await sheet.getFiltersAsync().then(async (resF) => {
            const allFilters: Array<TypeServerAppliedFilter> = [];

            resF.forEach((element) => {
                const allMatchedDim = resF.filter((dim) => {
                    return element.fieldName === dim.fieldName;
                });
                let allAppliedValues = [];
                allMatchedDim.forEach((matchedDim) => {
                    const appliedValues = matchedDim.appliedValues?.map((appliedOption) => {
                        if (element.fieldName === 'reporting_date') {
                            return moment(appliedOption.formattedValue).format(
                                globalConstant.dateDisplayFormat
                            );
                        }
                        return appliedOption.value;
                    });
                    allAppliedValues = [...allAppliedValues, ...(appliedValues || [])];
                });

                const uniqueSelectedFilters = [...new Set(allAppliedValues)];
                allFilters.push({
                    filterName: element.fieldName,
                    appliedValues: uniqueSelectedFilters,
                    isExcludeMode: element.isExcludeMode
                });
            });
            const usedDimenstion: string[] = [];

            if (sheet.sheetType === globalConstant.reportType.dashboard) {
                if (sheet.worksheets) {
                    for (const worksheet of sheet.worksheets) {
                        await worksheet.getSummaryDataAsync().then((res) => {
                            res.columns.forEach(({ fieldName }) => {
                                usedDimenstion.push(fieldName);
                            });
                        });
                    }
                } else {
                     sheet.initializeWithPublicInterfaces();
                            for (const worksheet of sheet.worksheetsImpl) {
                        await worksheet.getSummaryDataAsync().then((res) => {
                            res.columns.forEach(({ fieldName }) => {
                                usedDimenstion.push(fieldName);
                            });
                        });
                    }
                }
            } else {
                await sheet.getSummaryDataAsync().then((res) => {
                    res.columns.forEach(({ fieldName }) => {
                        usedDimenstion.push(fieldName);
                    });
                });
            }
                
            dispatch(allActions.dimensionFiltersAction.setServerAppliedFilters(allFilters, usedDimenstion, isApplyFilterRequired));
        });
        if (isApplyFilterRequired) {
            setTimeout(() => {
                dispatch(allActions.dimensionFiltersAction.triggerApplyFilter(true));
            }, 10);
        }
    };
    return { syncLocalFilters };
};

export default useSyncFilter;
