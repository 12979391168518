// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { Amplify, Auth, Hub } from 'aws-amplify';
import type { RootState } from './store/store';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import '../node_modules/bootstrap/scss/bootstrap.scss';
import config from './aws-exports';
import jwt_decode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';

import allActions from './store/actions';
import Header from './components/features/header/Header';
import Home from './components/features/home/Home';
import Footer from './components/features/footer/Footer';
import './App.scss';
import ViewDashboard from './components/features/dashboard/ViewDashboard';
import EditDashboard from './components/features/dashboard/EditDashboard';
import DashboardContainer from './components/features/dashboard/DashboardContainer';
import DashboardHome from './components/features/dashboard/DashboardHome';
import { setUserId, setAuthObject, setUserRole } from './services/user.service';
import ArgusAlert from './components/common/ui/alert/Alert';
import PageNotFound from './components/features/pageNotFound/PageNotFound';
import ArgusLoader from './components/common/ui/loader/Loader';
import ContactUs from './components/features/supportPages/ContactUs';
import Faq from './components/features/supportPages/Faq';
import TutorialTraining from './components/features/supportPages/TutorialTraining';
import Glossary from './components/features/supportPages/Glossary';
import SessionTimeOut from './components/common/ui/sessionTimeOut/SessionTimeOut';
import { addLogs, dcrpt, globalLogout, isSessionExpired, openSamlSignin, openSamlSignout, openSignoutPage } from './services/utils';
import ConfirmationModel from './components/common/ui/confirmationModel/ConfirmationModel';
import SignalContainer from './components/features/signalFinder/SignalContainer';
import SignalsHome from './components/features/signalFinder/SignalsHome';
import AuditHome from './components/features/audit/components/AuditHome';
import UserAccessHome from './components/features/audit/components/UserAccessHome';
import useAddAudit from './hooks/useAddAudit';
import { setApiFailureEventFunction } from './services/api.service'
import { auditEvents } from './services/auditActions';
import {  getTenantId, getProfileRoleMnemonics } from './services/api.service';

Amplify.configure(config);

interface User {
    attributes: {
        email: string;
        'custom:role': string;
        'custom:bankId': string;
    };
    signInUserSession: {
        idToken: {
            jwtToken: string;
        };
    };
}

type Props = {
    user?: User;
    signOut?: () => void | undefined;
};

setAuthObject(Auth);

function App(props?: Props): JSX.Element {
    const [authUser, setAuthUser] = useState(null);
    const [authInitiated, setAuthInitiated] = useState(false);
    const [scrollDirection, setScrollDirection] = useState<string | null>(null);
    const [jwtToken, setJwtToken] = useState<string | null>(null);

    const dispatch = useDispatch();
    const {addAuditEvent, logApiFailedEvent} = useAddAudit();

    const { siteName, profileName } = useSelector((state: RootState) => {
        return state.user;
    });
    useEffect(() => {
        let lastScrollY = window.scrollY;

        const updateScrollDirection = () => {
            const scrollY = window.scrollY;
            const direction = scrollY > lastScrollY ? 'down' : 'up';
            setScrollDirection(direction);
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        window.addEventListener('scroll', updateScrollDirection); // add event listener
        return () => {
            window.removeEventListener('scroll', updateScrollDirection); // clean up
        };
    }, [scrollDirection]);

    useEffect(() => {
         if(jwtToken != null && jwtToken.length > 0) {
            dispatch(allActions.loaderAction.showLoader());
            const tenantMap = new Map();
            getTenantId().then((response) => {
                for (const profile in response) {
                    tenantMap.set(response[profile]["tenant"], response[profile]["tenantId"]);
                }
                dispatch(allActions.auditAction.addTenantIds(tenantMap));
            }
            ).catch(error =>
                allActions.alertAction.showAlert({type:"danger",message: error}))
                .finally(() => dispatch(allActions.loaderAction.hideLoader()))
            }
    
    },[jwtToken])

    function getToken(redirectOnLogin) {
        return Auth.currentSession()
            .then((userToken) => {
                if(redirectOnLogin){
                    localStorage.removeItem('session-expiry-time');
                    localStorage.setItem('redirected-to-login', 'true');
                    addLogs('signout', 'session expire > called signout');
                    handleLogout(false);
                    return;
                }
                localStorage.removeItem('login-initiated');
                if (userToken.idToken.jwtToken != null) {

                    setJwtToken(userToken.idToken.jwtToken);
                    const setAccessDecodeToken = jwt_decode(userToken.idToken.jwtToken);
                    setUserId(setAccessDecodeToken?.preferred_username);
                    dispatch(
                        allActions.userAction.addUserID(
                            setAccessDecodeToken?.preferred_username
                        )
                    );
                    dispatch(
                        allActions.userAction.addUserEmail(
                            setAccessDecodeToken?.email
                        )
                    );
                    
                    getProfileRoleMnemonics().then((mnemonicsList) => {
                        // console.log('mnemonics:',mnemonicsList);
                        setAccessDecodeToken.isInternal = mnemonicsList?.domainCheck?.isInternal;            
                        if(setAccessDecodeToken?.isInternal == 'true') {
                            console.log('internal user is accessing........');
                            dispatch(allActions.profileAction.profileMnemonics(mnemonicsList));
                            dispatch(allActions.profileAction.userProfile(setAccessDecodeToken));
                        } else {
                            dispatch(allActions.profileAction.userProfile(setAccessDecodeToken));
                        }

                    }).catch((err) => {
                        console.log("couldn't fetch mnemonics -", err);
                    })                   
                }
            }).catch(() => {
                if (!authInitiated) {
                    setAuthInitiated(true);
                    localStorage.setItem('audit-login', 'true');
                    localStorage.removeItem('system-notification-off');
                    openSamlSignin();
                }
            });
    }

    function getUser() {
        return Auth.currentAuthenticatedUser()
            .then((user) => user)
            .catch((err) => console.log('errUser' + err));
    }

    useEffect(() => {
        if (Auth) {
            const _sessionTime = localStorage.getItem('session-expiry-time')
            const expiryTime = _sessionTime && dcrpt(_sessionTime);
            const isLoginInitiated = localStorage.getItem('login-initiated');       

           if(!isLoginInitiated && expiryTime && isSessionExpired()){
                addLogs('signout', 'Last saved session is expired');
                localStorage.removeItem('session-expiry-time');
                localStorage.setItem('login-initiated', 'true');
                getToken(true)
            }else{
                getToken();
            }
            
        }
    }, []);

    useEffect(() =>{
        setApiFailureEventFunction(logApiFailedEvent);
    },[logApiFailedEvent])

    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            addLogs('signout', `Hub event ${event}`)
            switch (event) {
                case 'signIn':
                    localStorage.removeItem('oAuthsignOut');
                    getUser().then((user) => setAuthUser(user));
                    break;
                case 'tokenRefresh':
                    localStorage.removeItem('oAuthsignOut');
                    break;
                case 'oAuthSignOut':
                    localStorage.setItem('oAuthsignOut', 'true');
                    break;
                case 'signOut':
                    if(localStorage.getItem('oAuthsignOut') === 'true'){
                        openSignoutPage();
                    }else{
                        openSamlSignout();
                    }
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    console.log('Sign in failure', data);
                    break;
            }
        });
        getUser().then((user) => setAuthUser(user));
    }, []);
    const propUser = props ? props.user : null;

    const handleLogout = async (logoutLogRequired = true) => {    
        localStorage.removeItem('session-expiry-time');
        if(logoutLogRequired) {
            await addAuditEvent(auditEvents.user.logout, 'Logged out');
        }
        globalLogout(Auth);
    };


    setUserRole(propUser?.attributes['custom:role']);

    if (!authUser || !jwtToken) {
        return <div></div>;
    }

    const isDashboard = location.pathname.includes('dashboard');
    return (
       
        <div >
            <BrowserRouter>
            <Header
                signOut={handleLogout}
                cssClass={{
                    hover: 'home-header header mb-2',
                    up: `${isDashboard && scrollDirection}`
                }}
            />
            <div className='main-section'>
                <ArgusLoader></ArgusLoader>
                <ArgusAlert isHeaderVisible={isDashboard && scrollDirection === 'down'}></ArgusAlert>
                <ConfirmationModel isHeaderVisible={isDashboard && scrollDirection === 'down'}></ConfirmationModel>
                <SessionTimeOut isHeaderVisible={isDashboard && scrollDirection === 'down'} Auth={Auth} logOut={handleLogout}></SessionTimeOut>

                <Routes>
                    <Route index element={<Home />} />
                    <Route path="/" element={<Home />} />
                 {process.env.REACT_APP_AUDIT === "true" &&  <Route path="/userinfo/activity" element={<AuditHome/>}/>}
                 {process.env.REACT_APP_AUDIT === "true" &&  <Route path="/userinfo/access" element={<UserAccessHome/>}/>}
                    <Route path="/dashboard" element={<DashboardContainer />}>
                        <Route path="list/:dashboardType?" element={<DashboardHome />} />
                        <Route
                            path="view/:siteName/:workbook/:dashboard"
                            element={siteName && profileName ? <ViewDashboard reportType={'dashboard'} /> : null}
                        />
                        <Route
                            path="edit/:siteName/:workbook/:dashboard"
                            element={
                                siteName && profileName ? (
                                    <EditDashboard reportType={'dashboard'} isNewWorkbook={false} />
                                ) : null
                            }
                        />
                        <Route path="new" element={<EditDashboard reportType={'dashboard'} isNewWorkbook={true} />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Route>
                    <Route path="/signals" element={<SignalContainer />}>
                        <Route path="list/:signalType?" element={<SignalsHome />} />
                        <Route
                            path="view/:siteName/:workbook/:dashboard"
                            element={siteName && profileName ? <ViewDashboard reportType={'signals'} /> : null}
                        />
                        <Route
                            path="edit/:siteName/:workbook/:dashboard"
                            element={
                                siteName && profileName ? (
                                    <EditDashboard reportType={'signals'} isNewWorkbook={false} />
                                ) : null
                            }
                        />
                        <Route path="*" element={<PageNotFound />} />
                    </Route>
                    <Route path="/contactus" element={<ContactUs />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/tutorialtraining" element={<TutorialTraining />} />
                    <Route path="/glossary" element={<Glossary />} />

                    <Route path="*" element={<PageNotFound />} />
                </Routes>
                <Footer
                    cssClass={
                        location.pathname === '/' ? 'hide-footer' : 'dashboard-footer'
                    }></Footer>
                    </div>
            </BrowserRouter>
        </div>
    );
}

export default App;
