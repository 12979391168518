const showCreateDashboardPopup = (payload) => {
    return {
        type: "SHOW_CREATE_DASHBOARD_POPUP",
        payload
    }
}

const hideCreateDashboardPopup = () => {
    return {
        type: "HIDE_CREATE_DASHBOARD_POPUP"
    }
}

const setCreateDashboardTemplate = (payload) => {
    return {
        type: "SET_CREATE_DASHBOARD_TEMPLATE",
        payload
    }
}




export default {
    showCreateDashboardPopup,
    hideCreateDashboardPopup,
    setCreateDashboardTemplate
}