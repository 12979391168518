
import React from 'react'
import './button.scss'
interface Props{
    children: React.ReactNode | string,
    type?:string,
    disabled?:boolean
    onClick: () => void
}
function Button({ children, onClick, type='normal', disabled = false }: Props) {
    return (
        <>
            <button className={`argus-btn ${type}`} disabled={disabled} onClick={onClick}>{children}</button>
        </>
    );
}

export default Button;
