const addTenantIds = (payload) => {
    return {
        type: "ADD_TENANT_IDS",
        payload
    }
}

const addTenantId = (payload) => {
    return {
        type: "ADD_TENANT_ID",
        payload
    }
}

const addCurrentProfile = (payload) => {
    return {
        type: "ADD_CURRENT_PROFILE",
        payload
    }
}

const addCurrentRole = (payload) => {
return {
    type:"ADD_CURRENT_ROLE",
    payload
}
}

const addDownloadType = (payload) => {
    return {
        type:"ADD_DOWNLOAD_TYPE",
        payload
    }
}
const addEventsFrom = (payload) => {
    return {
        type: "ADD_EVENTS_FROM",
        payload
    }
} 
const addEventsTo = (payload) =>{
    return {
        type: "ADD_EVENTS_TO",
        payload
    }
}


const addPresignedUrl = (payload) => {
    return {
        type: 'ADD_PRESIGNED_URL',
        payload
    }
}

const addDownloadAsyncId = (payload) => {
   return {
    type:'ADD_DOWNLOAD_ASYNC_ID',
    payload
   }
}
const disableDownload= (payload)=> {
   return {
    type:'DISABLE_DOWNLOAD',
    payload
   }
}
const totalPages = (payload) => {
    return {
        type: "TOTAL_PAGES",
        payload
    }
}
export default {
    addTenantId,
    addCurrentProfile,
    addCurrentRole,
    addTenantIds,

    addDownloadType,
    addEventsFrom,
    addEventsTo,
    addPresignedUrl,
   totalPages,
    addDownloadAsyncId,
    disableDownload
}