const addServerFilters = (payload) => {
    return {
        type: "SET_SERVER_FILTERS",
        payload
    }
}

const updateFilterOption = (filterIndex, optionIndex, value) => {
    return {
        type: "UPDATE_FILTER_OPTION",
        payload: {filterIndex, optionIndex, value}
    }
}
const updateFilterOnApply = (filterIndex, isApplied) => {
    return {
        type: "UPDATE_FILTER_APPLY",
        payload: {filterIndex, isApplied}
    }
}

const resetSelectedDimension = (filterIndex) => {
    return {
        type: "RESET_SELECTED_FILTER",
        payload: filterIndex
    }
}

const setServerAppliedFilters = (serverAppliedFilters, usedDimenstion, isApplyFilterRequired) => {
    return {
        type: "SET_SERVER_APPLIED_FILTERS",
        payload: { serverAppliedFilters, usedDimenstion, isApplyFilterRequired}
    }
}

const setGroupFilters = (groupFilters) => {
    return {
        type: "SET_TABLEAU_GROUP",
        payload: groupFilters
    }
}

const clearAll = () => {
    return {
        type: "FILTER_CLEAR_ALL"
    }
}

const deleteAllFilters = () => {
    return {
        type: "DELETE_ALL_FILTERS"
    }
}

const triggerApplyFilter = (applyFilter) => {
    return {
        type: "SET_APPLY_FILTER",
        payload: applyFilter
    }
}




export default {
    addServerFilters,
    setServerAppliedFilters,
    updateFilterOption,
    updateFilterOnApply,
    clearAll,
    resetSelectedDimension,
    deleteAllFilters,
    setGroupFilters,
    triggerApplyFilter
}
