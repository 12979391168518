import utils from '../../../services/utils'
export const getAlltenant = (allProfile, userSite, userProfile, includeSourceProfile = false) => {
    const allTenant = (allProfile || []).map(({bankname, parent}) => {
        let allOptions = parent;
        if(!includeSourceProfile) {
            allOptions = parent.filter((option) => {
                return option !== userProfile;
            })
        }
        
        return {
            label: utils.maskBankName(bankname),
            id: bankname,
            options: [...allOptions],
            value: false
        }
    })

    return allTenant.filter((tenant) => {
        return tenant.options.length;
    })
}   

export const getMaxWorkbookinDataset = (datasets) =>{
    let maxWorkbookCount = 0;
    if(datasets) {
        datasets.forEach(element => {
            if(element.workBookCount > maxWorkbookCount) {
                maxWorkbookCount = element.workBookCount;
            }
        });
    }
    return maxWorkbookCount
    
}

export const excludeLoggedInUser = (userList, loggedInUserID)  => {
    return userList.filter((user)=>{
        return user.userEmail?.toLowerCase() !== loggedInUserID?.toLowerCase();
    })
}

export const multidropdownAllSelected = (options) =>{
    let currentSelectedCount = 0;
    let totalCheckbox = 0;
    options.forEach((option, index) => {
        if(option.type !== 'label' && index){
            totalCheckbox++
        }
        if (option.value && index && option.type !== 'label') {
            currentSelectedCount++;
        }
    });
    return currentSelectedCount === totalCheckbox;
}

export const isValidGroupDescPattern = (group: string) => {
    const regexPattern= /^([a-zA-Z0-9\s_]+)$/;
    const isValid =  regexPattern.test(group);
    return isValid;
}

export const addSourceWorkbookInProfile = (selectedTenants, workbookName) =>{
    return selectedTenants.map((tenant) => {
        const tenantCopy = {...tenant};
        tenantCopy.profiles = tenant.profiles.map((profile) =>{
            const copyProfile = {...profile, workBookName: workbookName}
            return copyProfile;
        })
        return tenantCopy;
    });
}


export const getDefaultDatasetIndex = (datasets) =>{
     const defaultDatasetIndex = datasets.findIndex((dataset) => {
        return dataset.isDefault === true;
    });
    return defaultDatasetIndex === -1 ? 0 : defaultDatasetIndex;
}

export const getDefaultDataset = (datasets) =>{
    const defaultDataset = datasets.find((dataset) => {
        return dataset.isDefault === true;
    });
    return defaultDataset || datasets[0];
}

